import React, { useState } from 'react';
import './form.scss';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// import { pure } from 'recompose';

const CustomTextField = ({
	label,
	type,
	renderActions,
	className,
	style,
	value,
	setFieldValue,
	...props
}) => {
	const [field, meta] = useField(props);
	const [visibility, setVisibility] = useState(false);
	const errorText = meta.error && meta.touched ? meta.error : '';
	const styles = makeStyles((theme) => ({
		input: {
			'&::placeholder': {
				color: meta.error && meta.touched ? '#fb4868' : '#898f8f',
			},
		},
	}));
	const classes = styles();

	const toggleVisibility = () => {
		setVisibility(!visibility);
	};

	return (
		<div
			className={'text-field'.concat(` ${className}`)}
			style={{ display: 'grid' }}
		>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				{label && (
					<label
						style={{
							color:
								meta.error && meta.touched
									? '#83827c'
									: '#898f8f',
							fontSize: '14px',
							fontWeight: 500,
							fontFamily: 'Montserrat, sans-serif',
							marginBottom: '0px!important',
						}}
						htmlFor={field.name}
					>
						{label}
					</label>
				)}
				{type == 'password' && value && (
					<Typography
						onClick={toggleVisibility}
						style={{
							fontSize: '14px',
							cursor: 'pointer',
							color: '#54BAAB',
						}}
					>
						{visibility ? 'Hide' : 'Show'}
					</Typography>
				)}
			</div>
			<input
				{...field}
				id={field.name}
				type={visibility ? 'text' : type}
				maxLength="50"
				onBlur={(e) => {
					const val = (e.target.value || '').replace(/\s+/gi, ' ');
					setFieldValue(field.name, val.trim());
				}}
				className={classes.input}
				style={{
					...(meta.error && meta.touched
						? {
								border: '1px solid #fb4868',
								color: '#fb4868',
						  }
						: {
								border: '1px solid #9fabc6',
								color: '#898f8f',
						  }),
					...style,
				}}
				{...props}
				placeholder=""
			/>
			<span className="errorText">{errorText}</span>
			{renderActions && renderActions()}
		</div>
	);
};

CustomTextField.propTypes = {
	label: PropTypes.string,
};

CustomTextField.defaultProps = {
	label: '',
};

export default CustomTextField;
