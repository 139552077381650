import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  Button,
  Typography,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useHistory } from "react-router-dom";
import Image from "../../assets/images/img-02.jpg";
import SellerSidebar from "../Sidebar/SellerSidebar";
import SellerTitle from "../PageTitle/SellerTitle";
import api from "../../api";
import { useSnackbar } from "../../lib/context";
import CustomLoader from "../../components/CustomLoader";
import { formatDate, logout } from "../../utils/utils";
import MessageIcon from "@mui/icons-material/Message";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { Formik, Field, Form, useFormik } from "formik";
import Footer from "../../layout/Footer";

const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 0.5em;
  background: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 800;
  font-size: 0.8rem;
  &:first-child {
    padding-top: 0.5em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.4em;
  padding-top: 0rem;
  font-weight: 800;
  font-size: 1rem;
`;
const options = [
  <li id="active" className="nav-item">
    <a href="/order" className="nav-link">
      <Link to="/order"> In Progress</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a href="/completedorder" className="nav-link">
      <Link to="/completedorder">Completed</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a href="/shipped" className="nav-link">
      <Link to="/shipped">Shipped</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a href="/canceled" className="nav-link">
      <Link to="/canceled">Canceled</Link>{" "}
    </a>
  </li>,
];

const optionz = [
  <li className="nav-item">
    <a href="/products" className="nav-link">
      <Link to="/products">Recent Products</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a href="/allproducts" className="nav-link">
      <Link to="/allproducts">All Products</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a href="/addproduct" className="nav-link">
      <Link to="/addproduct">Add A Product</Link>{" "}
    </a>
  </li>,
];

function Order() {
  const [orders, setOrders] = useState([]);
  const [search, setSearch] = useState();

  const [isOpen, setIsOpen] = useState(true);
  const { push } = useHistory();
  const [orderDiag, setOrderDiag] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const { openSnackbar } = useSnackbar();

  const handleSubmit = async (values) => {
    console.log(document.activeElement.dataset.rowindex);
    const row = values.orders[document.activeElement.dataset.rowindex];
    const postValues = {
      orderid: row.id,
      comments: row.orderStatusComments,
      orderStatus: row.orderstatus,
      paymentStatus: row.paymentStatus,
      sellerid: row.sellerAccountData.id,
    };
    console.log(values);
    console.log(row);
    try {
      const { data } = await api.post("/order/update-status", postValues);
      openSnackbar("success", "Updated Successfully");
      fetchData();
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      orders: [],
    },
    onSubmit: handleSubmit,
  });
  const toggling = () => {
    setIsOpen(!isOpen);
    setIsOn(false);
  };

  const [isOn, setIsOn] = useState(false);
  const toggling1 = () => {
    setIsOn(!isOn);
    setIsOpen(false);
  };

  const onOptionClicked = (value) => () => {
    console.log(selectedOption);
  };

  const onOptionzClicked = (value) => () => {
    console.log(selectedOption);
  };
  const [option, setOption] = useState({
    page: 1,
    size: 5,
    sortBy: "buyerAccountData.firstname",
    sortOrder: "asc",
    status: "Completed",
    search: "",
  });

  const [totalElem, setTotalElem] = useState();
  const fetchData = async (signal) => {
    try {
      const { data } = await api.post("/order/getSellerOrders", option, {
        signal: signal,
      });
      formik.resetForm({
        values: {
          // orders: data.filter((d) => d.orderstatus == "Completed"),
          orders: data.body.content,
        },
      });
      setTotalElem(data.body.totalElements);
      // setOrders(
      //   data.filter(
      //     (d) => d.orderstatus == "New Order" || d.orderstatus == "In Progress"
      //   )
      // );
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchData(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, [option]);

  // if (!formik.values.orders) {
  //   return <CustomLoader />;
  // }

  const handleSort = (column) => {
    console.log(column);
    setOption({
      ...option,
      sortBy: column,
      sortOrder:
        option.sortBy === column && option.sortOrder === "asc" ? "desc" : "asc",
    });
  };
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        scroll="paper"
        open={orderDiag}
        onClose={() => setOrderDiag(null)}
        TransitionComponent={Grow}
      >
        {/* <DialogContent
        className="modal-dialog  modal-order-details"
        role="document"
        > */}
        {/* <!-- Modal --> */}
        <div
        // class="modal fade"
        // id="viewOrderDetail"
        // tabindex="-1"
        // role="dialog"
        // aria-labelledby="exampleModalLabel"
        // aria-hidden="true"
        >
          {/* <div class="modal-dialog  modal-order-details" role="document"> */}
          {orderDiag && (
            <div class="modal-content">
              <div class="modal-body">
                <button
                  type="button"
                  class="close"
                  // data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setOrderDiag(null)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>

                <div class="order-details brdr-none">
                  <h2>ORDER DETAILS</h2>
                  <div class="od-list">
                    <label>Order Date:</label>{" "}
                    {orderDiag &&
                      new Date(orderDiag.orderdate).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )}
                  </div>
                  <div class="od-list">
                    <label>Order Number:</label>{" "}
                    {orderDiag && orderDiag.ordernumber}
                  </div>
                  <div class="od-list">
                    <label>Order Total:</label> ${" "}
                    {orderDiag.product[0].discountprice !== ""
                      ? orderDiag.product[0].discountprice *
                        orderDiag.product[0].quantity
                      : orderDiag.product[0].price *
                        orderDiag.product[0].quantity}
                  </div>
                </div>
                {/* <!-- end  order-details --> */}

                <div class="order-details brdr-none">
                  <h2>PRODUCT DETAILS</h2>
                  <div class="od-list">
                    <strong>Product</strong> <br />
                  </div>
                  <div class="od-list mt-2 mb-2">
                    {orderDiag &&
                      orderDiag.product.map((item) => (
                        <img
                          src={item.primaryfile.url}
                          alt="Order Product Image"
                          style={{ height: "8em", width: "8em" }}
                        />
                      ))}
                  </div>
                  <div class="od-list mb-2">
                    {orderDiag.product[0].productname}
                  </div>
                  <div class="od-list">
                    <label>Selling Instrument:</label>
                    {orderDiag.product[0].sellinginstrument}
                  </div>
                  <div class="od-list">
                    <label>Brand:</label>
                    {orderDiag.product[0].manufacturer}
                  </div>

                  <div class="od-list">
                    <label>Seller:</label>
                    {orderDiag.sellerAccountData.firstname +
                      " " +
                      orderDiag.sellerAccountData.lastname +
                      " (" +
                      orderDiag.sellerAccountData.company +
                      ")"}
                  </div>
                </div>
                {/* <!-- end  order-details --> */}

                <div class="row">
                  <div class="col-md-6">
                    <div class="order-details brdr-none">
                      <h2>SHIPPING DETAILS</h2>
                      <div class="od-list">
                        {orderDiag.buyerAccountData.firstname +
                          " " +
                          orderDiag.buyerAccountData.lastname +
                          " (" +
                          orderDiag.buyerAccountData.company +
                          ")"}
                      </div>
                      <div class="od-list mt-2">
                        <strong>Shipping Address</strong> <br />
                        {orderDiag && orderDiag.deliveryaddress}
                      </div>
                    </div>
                    {/* <!-- end  order-details --> */}
                  </div>

                  <div class="col-md-6">
                    <div class="order-details brdr-none">
                      <h2>PREFERRED SHIPPING METHOD</h2>
                      <div class="od-list">
                        Ground Shipping
                        <br />
                        Seller Preferred Shipping
                      </div>
                    </div>
                    {/* <!-- end  order-details --> */}
                  </div>
                </div>

                <div class="order-details brdr-none">
                  <h2>PREFERRED PAYMENT METHOD</h2>
                  <div class="od-list">
                  {orderDiag && orderDiag.postcode}</div>
                </div>
                {/* <!-- end  order-details --> */}
              </div>
              {/* </div> */}
            </div>
          )}
        </div>
        {/* </DialogContent> */}
      </Dialog>

      <div id="dashboard" className="col-2-layout">
        <div className="left-sidebar equalColumn">
          <div className="box-content">
            <SellerSidebar pageName="orders" />
          </div>
        </div>
        {/* end left-sidebar */}
        <div className="right-content equalColumn">
          <div className="box-content title-box">
            <SellerTitle />
          </div>
          {/* end box-content */}
          <div className="box-content minColheight">
            <div className="row" id="dashboard-tabs">
              <div className="col-md-12">
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                      className="nav-item active"
                      id="nav-activeOrders-tab"
                      data-toggle="tab"
                      href="#nav-activeOrders"
                      role="tab"
                      aria-controls="nav-activeOrders"
                      aria-selected="true"
                    >
                      Completed Orders
                    </a>
                    <div className="order-right-filter">
                      <div className="search-blk">
                        <input
                          type="text"
                          placeholder="Search all orders"
                          className="form-control"
                          value={search}
                          onChange={(e) => {
                            // setSearch(e.target.value);
                            setOption({ ...option, search: e.target.value });
                          }}
                        />
                        <button
                          type="submit"
                          defaultValue="Search"
                          className="btn-search"
                          onClick={() => setOption({ ...option, search })}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </nav>

                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-activeOrders"
                    role="tabpanel"
                    aria-labelledby="nav-activeOrders-tab"
                  >
                    <div className="form-sec" id="order-details-sec">
                      {formik.values.orders &&
                      formik.values.orders.length > 0 ? (
                        <form onSubmit={formik.handleSubmit}>
                          <table className="table">
                            <thead>
                              <tr>
                                {/* <th>Name</th> */}
                                <th
                                  scope="col"
                                  onClick={() =>
                                    handleSort("buyerAccountData.firstname")
                                  }
                                >
                                  Product Name{" "}
                                  <span>
                                    {option.sortBy ===
                                      "product[0].productname" &&
                                    option.sortOrder === "asc"
                                      ? "↑"
                                      : "↓"}
                                  </span>
                                </th>
                                <th>Order Number</th>
                                <th>Address</th>
                                <th>Change Status</th>
                                <th>Payment Status</th>
                                <th>Comments</th>
                                <th>Action</th>
                                <th>Save</th>
                                {/* <th>Message</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {formik.values.orders.map((item, i) => (
                                <tr key={item.ordernumber}>
                                  <td>
                                    <h3>{item.product[0].productname} </h3>
                                  </td>
                                  <td>
                                    <p
                                      className="order-number"
                                      style={{ fontSize: "14px" }}
                                    >
                                      <strong>{item.ordernumber}</strong>
                                    </p>
                                  </td>
                                  <td>
                                    <p style={{ fontSize: "15px" }}>
                                      {item.buyerAccountData.address}
                                    </p>
                                  </td>

                                  <td>
                                    <div className="row">
                                      <div className="col-md-12 order-status">
                                        <select
                                          className="form-control style2 statusinprogress"
                                          // value={values.orderStatus}
                                          // onChange={(e) => {
                                          //   setFieldValue(
                                          //     "orderStatus",
                                          //     e.target.value
                                          //   );
                                          // }}
                                          {...formik.getFieldProps(
                                            `orders.${i}.orderstatus`
                                          )}
                                        >
                                          {[
                                            "In Progress",
                                            "Completed",
                                            "Shipped",
                                            "Cancelled",
                                          ].map((status) => (
                                            <option key={status}>
                                              {status}
                                            </option>
                                          ))}
                                        </select>{" "}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="row">
                                      <div className="col-md-12 order-status">
                                        <select
                                          className="form-control style2 statusinprogress"
                                          // value={values.paymentStatus}
                                          // onChange={(e) => {
                                          //   setFieldValue(
                                          //     "paymentStatus",
                                          //     e.target.value
                                          //   );
                                          // }}
                                          {...formik.getFieldProps(
                                            `orders.${i}.paymentStatus`
                                          )}
                                        >
                                          <option>Paid</option>
                                          <option>Unpaid</option>
                                        </select>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <textarea
                                      className="form-control style2"
                                      placeholder="Comments"
                                      // value={values.comments}
                                      // onChange={(e) => {
                                      //   setFieldValue("comments", e.target.value);
                                      // }}
                                      {...formik.getFieldProps(
                                        `orders.${i}.orderStatusComments`
                                      )}
                                    />
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      className="view-more btn btn-sm"
                                      data-toggle="modal"
                                      data-target="#viewOrderDetail"
                                      onClick={() => setOrderDiag(item)}
                                      style={{ fontSize: "13px" }}
                                    >
                                      View Details
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      type="submit"
                                      data-rowindex={i}
                                      // disabled={rest.isSubmitting}
                                      className="btn btn-success"
                                    >
                                      Save
                                    </button>
                                  </td>
                                  {/* <td>
                                  <div
                                    className="td-col tg-pay-status"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <MessageIcon />
                                  </div>
                                </td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </form>
                      ) : (
                        <div>No orders found</div>
                      )}
                    </div>
                    <div className="d-flex flex-row-reverse">
                      <div>
                        <select
                          className="form-select mt-2 mx-2"
                          value={option.size}
                          onChange={(e) =>
                            setOption({ ...option, size: e.target.value })
                          }
                        >
                          {/* <option selected>{option.size}</option> */}
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="100">100</option>
                        </select>
                      </div>

                      <nav aria-label="...">
                        <ul className="pagination">
                          <li
                            className={`page-item ${
                              option.page === 1 ? "disabled" : ""
                            }`}
                          >
                            <span
                              className="page-link"
                              onClick={() =>
                                setOption({ ...option, page: option.page - 1 })
                              }
                              style={{ color: "black" }}
                            >
                              Previous
                            </span>
                          </li>
                          {Array.from(
                            { length: Math.ceil(totalElem / option.size) },
                            (_, index) => (
                              <li
                                key={index}
                                className={`page-item ${
                                  option.page === index + 1 ? "active" : ""
                                }`}
                              >
                                <span
                                  className="page-link"
                                  onClick={() =>
                                    setOption({
                                      ...option,
                                      page: index + 1,
                                    })
                                  }
                                  style={
                                    {
                                      // backgroundColor: "#4ba26b",
                                      // color: "black",
                                    }
                                  }
                                >
                                  {index + 1}
                                </span>
                              </li>
                            )
                          )}
                          <li
                            className={`page-item ${
                              option.page === Math.ceil(totalElem / option.size)
                                ? "disabled"
                                : ""
                            }`}
                          >
                            <span
                              className="page-link"
                              onClick={() =>
                                setOption({
                                  ...option,
                                  page: option.page + 1,
                                })
                              }
                              style={{ color: "black" }}
                            >
                              Next
                            </span>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    {/* <div className="form-sec" id="order-details-sec">
                      {orders.length == 0 ? (
                        <div>No orders found</div>
                      ) : (
                        orders.map((item) => (
                          <div className="row order-details">
                            <div className="col-md-6">
                              <h3>
                                {item.buyerAccountData.firstname}{" "}
                                {item.buyerAccountData.lastname}
                              </h3>
                              <p className="order-number">
                                Order Number:{" "}
                                <strong>{item.ordernumber}</strong>
                              </p>
                              <p>{item.buyerAccountData.address}</p>
                              <button
                                type="button"
                                className="view-more pt-3"
                                data-toggle="modal"
                                data-target="#viewOrderDetail"
                                onClick={() => setOrderDiag(item)}
                              >
                                View Details
                              </button>
                            </div>
                            <div className="col-md-6">
                              <Formik
                                enableReinitialize
                                initialValues={{
                                  orderid: item.id,
                                  sellerid: item.sellerAccountData.id,
                                  comments: item.orderStatusComments,
                                  orderStatus: item.orderstatus,
                                  paymentStatus: item.paymentStatus,
                                }}
                                onSubmit={handleSubmit}
                              >
                                {({ values, setFieldValue, ...rest }) => {
                                  return (
                                    <Form>
                                      <div className="row">
                                        <div className="col-md-4 order-status">
                                          <label>Change Status</label>
                                          <select
                                            className="form-control style2 statusinprogress"
                                            value={values.orderStatus}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "orderStatus",
                                                e.target.value
                                              );
                                            }}
                                          >
                                            {[
                                              "In Progress",
                                              "Completed",
                                              "Shipped",
                                              "Cancelled",
                                            ].map((item) => (
                                              <option>{item}</option>
                                            ))}
                                          </select>{" "}
                                          <label>Payment Status</label>
                                          <select
                                            className="form-control style2 statusinprogress"
                                            value={values.paymentStatus}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "paymentStatus",
                                                e.target.value
                                              );
                                            }}
                                          >
                                            <option>Paid</option>
                                            <option>Unpaid</option>
                                          </select>
                                        </div>
                                        <div className="col-md-5">
                                          <label>&nbsp;</label>
                                          <textarea
                                            className="form-control style2"
                                            placeholder="Comments"
                                            value={values.comments}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "comments",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </div>
                                        <div className="col-md-3 text-right">
                                          <label>&nbsp;</label>
                                          <button
                                            type="submit"
                                            disabled={rest.isSubmitting}
                                            className="btn btn-style1 btn-green btn-save"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    </Form>
                                  );
                                }}
                              </Formik>
                            </div>
                            
                          </div>
                        ))
                      )}
                    </div> */}
                    {/* <div className="form-sec" id="order-details-sec">
                      {orders.length === 0 ? (
                        <div>No orders found</div>
                      ) : (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Order Number</th>
                              <th>Address</th>
                              <th>Change Status</th>
                              <th>Payment Status</th>
                              <th>Save</th>
                              <th>Comments</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orders.map((item) => (
                              <tr key={item.ordernumber}>
                                <td>
                                  <h3>
                                    {item.buyerAccountData.firstname}{" "}
                                    {item.buyerAccountData.lastname}
                                  </h3>
                                </td>
                                <td>
                                  <p className="order-number">
                                    <strong>{item.ordernumber}</strong>
                                  </p>
                                </td>
                                <td>
                                  <p>{item.buyerAccountData.address}</p>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="view-more pt-3"
                                    data-toggle="modal"
                                    data-target="#viewOrderDetail"
                                    onClick={() => setOrderDiag(item)}
                                  >
                                    View Details
                                  </button>
                                </td>
                                <td>
                                  <Formik
                                    enableReinitialize
                                    initialValues={{
                                      orderid: item.id,
                                      sellerid: item.sellerAccountData.id,
                                      comments: item.orderStatusComments,
                                      orderStatus: item.orderstatus,
                                      paymentStatus: item.paymentStatus,
                                    }}
                                    onSubmit={handleSubmit}
                                  >
                                    {({ values, setFieldValue, ...rest }) => (
                                      <Form>
                                        <div className="row">
                                          <div className="col-md-4 order-status">
                                            <select
                                              className="form-control style2 statusinprogress"
                                              value={values.orderStatus}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "orderStatus",
                                                  e.target.value
                                                );
                                              }}
                                            >
                                              {[
                                                "In Progress",
                                                "Completed",
                                                "Shipped",
                                                "Cancelled",
                                              ].map((status) => (
                                                <option key={status}>
                                                  {status}
                                                </option>
                                              ))}
                                            </select>{" "}
                                            <select
                                              className="form-control style2 statusinprogress"
                                              value={values.paymentStatus}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "paymentStatus",
                                                  e.target.value
                                                );
                                              }}
                                            >
                                              <option>Paid</option>
                                              <option>Unpaid</option>
                                            </select>
                                          </div>
                                          <div className="col-md-5">
                                            <textarea
                                              className="form-control style2"
                                              placeholder="Comments"
                                              value={values.comments}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "comments",
                                                  e.target.value
                                                );
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-3 text-right">
                                            <button
                                              type="submit"
                                              disabled={rest.isSubmitting}
                                              className="btn btn-style1 btn-green btn-save"
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      </Form>
                                    )}
                                  </Formik>
                                </td>
                                <td></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div> */}
                  </div>
                </div>
                {/*  <div className="payments-content">
                    {orders.length > 0 && (
                      <div className="paymentsCon-thead">
                        <div className="th-col tg-pro">Product</div>
                        <div className="th-col tg-name">Name</div>
                        <div className="th-col tg-email">Email</div>
                        <div className="th-col tg-date">Order Date</div>
                        <div className="th-col tg-orderNo">Order No.</div>
                        <div className="th-col tg-amount">Amount</div>
                        <div className="th-col tg-pay-status">Status</div>
                      </div>
                    )}
                    {orders.length == 0 ? (
                      <div>No orders found</div>
                    ) : (
                      orders.map((order) => (
                        <div className="paymentsCon-item-row">
                          <div className="td-col tg-pro">
                            <div className="tg-pro-img">
                              {order.product.length > 0 &&
                              order.product[0].primaryfile &&
                              order.product[0].primaryfile.url ? (
                                <img
                                  src={order.product[0].primaryfile.url}
                                  style={{
                                    width: "60px",
                                  }}
                                  alt=""
                                  className="img-responsive"
                                />
                              ) : (
                                <ImageNotSupportedIcon
                                  style={{
                                    fontSize: "50px",
                                    color: "#6e6e6e",
                                  }}
                                />
                              )}
                            </div>
                            {order.product.length > 0 &&
                            order.product[0].productname
                              ? order.product[0].productname
                              : "NA"}
                            <button
                              type="button"
                              className="view-more pt-3"
                              data-toggle="modal"
                              data-target="#viewOrderDetail"
                              onClick={() => handleDialogOpen(order)}
                            >
                              View Details
                            </button>
                          </div>
                          <div className="td-col tg-name">
                            {order.buyerAccountData
                              ? order.buyerAccountData.firstname
                              : "NA"}
                          </div>
                          <div
                            className="td-col tg-email"
                            style={{
                              overflowWrap: "break-word",
                            }}
                          >
                            {order.buyerAccountData
                              ? order.buyerAccountData.username
                              : "NA"}
                          </div>
                          <div className="td-col tg-date">
                            {formatDate(order.orderdate)}
                          </div>
                          <div className="td-col tg-orderNo">
                            #{order.ordernumber ?? "NA"}
                          </div>
                          <div className="td-col tg-amount">
                            ${order.total ?? ""}
                          </div>
                          <div className="td-col tg-pay-status">
                            {order.orderstatus ?? "NA"}
                          </div>
                          <div
                            className="td-col tg-pay-status"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <MessageIcon
                            // onClick={() => handleMessageDialogOpen(order.id)}
                            />
                          </div>
                        </div>
                      ))
                    )}
                  </div>*/}
              </div>
            </div>
          </div>
        </div>
        {/* end right -sidebar */}

        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
        <Footer />
      </div>
    </div>
  );
}

const OrderTable1 = ({ handleDialogOpen }) => {
  return (
    <>
      <div className="tab-content" id="nav-tabContent">
        <div
          className="tab-pane fade show active"
          id="nav-activeOrders"
          role="tabpanel"
          aria-labelledby="nav-activeOrders-tab"
        >
          <div className="form-sec" id="order-details-sec">
            <div className="row order-details">
              <div className="col-md-6">
                <h3>Chris Wattson</h3>
                <p className="order-number">
                  Order Number: <strong>#5896254</strong>
                </p>
                <p>
                  Beckman Coulter Life Science UV/Vis Spectrophotometer DU 730
                </p>
                <button
                  type="button"
                  className="view-more pt-3"
                  data-toggle="modal"
                  data-target="#viewOrderDetail"
                  onClick={handleDialogOpen}
                >
                  View Details
                </button>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4 order-status">
                    <label>Change Status</label>
                    <select className="form-control style2 statusinprogress">
                      <option>In Progress</option>
                    </select>{" "}
                    <label>Payment Status</label>
                    <select className="form-control style2 statusinprogress">
                      <option>Paid</option>
                    </select>
                  </div>
                  <div className="col-md-5">
                    <label>&nbsp;</label>
                    <textarea
                      className="form-control style2"
                      placeholder="Comments"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-md-3 text-right">
                    <label>&nbsp;</label>
                    <button className="btn btn-style1 btn-green btn-save">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* end row */}
            <div className="row order-details">
              <div className="col-md-6">
                <h3>Chris Wattson</h3>
                <p className="order-number">
                  Order Number: <strong>#5896254</strong>
                </p>
                <p>
                  Beckman Coulter Life Science UV/Vis Spectrophotometer DU 730
                </p>
                <button
                  type="button"
                  className="view-more pt-3"
                  data-toggle="modal"
                  data-target="#viewOrderDetail"
                  onClick={handleDialogOpen}
                >
                  View Details
                </button>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4 order-status">
                    <label>Change Status</label>
                    <select className="form-control style2 statusinprogress">
                      <option>In Progress</option>
                    </select>
                    <label>Payment Status</label>
                    <select className="form-control style2 statusCanceled">
                      <option>Unpaid</option>
                    </select>
                  </div>
                  <div className="col-md-5">
                    <label>&nbsp;</label>
                    <textarea
                      className="form-control style2"
                      placeholder="Comments"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-md-3 text-right">
                    <label>&nbsp;</label>
                    <button className="btn btn-style1 btn-green btn-save">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* end row */}
            <div className="row order-details">
              <div className="col-md-6">
                <h3>Chris Wattson</h3>
                <p className="order-number">
                  Order Number: <strong>#5896254</strong>
                </p>
                <p>
                  Beckman Coulter Life Science UV/Vis Spectrophotometer DU 730
                </p>
                <button
                  type="button"
                  className="view-more pt-3"
                  data-toggle="modal"
                  data-target="#viewOrderDetail"
                  onClick={handleDialogOpen}
                >
                  View Details
                </button>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4 order-status">
                    <label>Change Status</label>
                    <select className="form-control style2 statusinprogress">
                      <option>In Progress</option>
                    </select>
                    <label>Payment Status</label>
                    <select className="form-control style2 statusinprogress">
                      <option>Paid</option>
                    </select>
                  </div>
                  <div className="col-md-5">
                    <label>&nbsp;</label>
                    <textarea
                      className="form-control style2"
                      placeholder="Comments"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-md-3 text-right">
                    <label>&nbsp;</label>
                    <button className="btn btn-style1 btn-green btn-save">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* end row */}
            <div className="row order-details">
              <div className="col-md-6">
                <h3>Chris Wattson</h3>
                <p className="order-number">
                  Order Number: <strong>#5896254</strong>
                </p>
                <p>
                  Beckman Coulter Life Science UV/Vis Spectrophotometer DU 730
                </p>
                <button
                  type="button"
                  className="view-more pt-3"
                  data-toggle="modal"
                  data-target="#viewOrderDetail"
                  onClick={handleDialogOpen}
                >
                  View Details
                </button>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4 order-status">
                    <label>Change Status</label>
                    <select className="form-control style2 statusinprogress">
                      <option>In Progress</option>
                    </select>
                    <label>Payment Status</label>
                    <select className="form-control style2 statusCanceled">
                      <option>Unpaid</option>
                    </select>
                  </div>
                  <div className="col-md-5">
                    <label>&nbsp;</label>
                    <textarea
                      className="form-control style2"
                      placeholder="Comments"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-md-3 text-right">
                    <label>&nbsp;</label>
                    <button className="btn btn-style1 btn-green btn-save">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* end row */}
            <div className="row order-details">
              <div className="col-md-6">
                <h3>Chris Wattson</h3>
                <p className="order-number">
                  Order Number: <strong>#5896254</strong>
                </p>
                <p>
                  Beckman Coulter Life Science UV/Vis Spectrophotometer DU 730
                </p>
                <button
                  type="button"
                  className="view-more pt-3"
                  data-toggle="modal"
                  data-target="#viewOrderDetail"
                  onClick={handleDialogOpen}
                >
                  View Details
                </button>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-4 order-status">
                    <label>Change Status</label>
                    <select className="form-control style2 statusinprogress">
                      <option>In Progress</option>
                    </select>
                    <label>Payment Status</label>
                    <select className="form-control style2 statusinprogress">
                      <option>Paid</option>
                    </select>
                  </div>
                  <div className="col-md-5">
                    <label>&nbsp;</label>
                    <textarea
                      className="form-control style2"
                      placeholder="Comments"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-md-3 text-right">
                    <label>&nbsp;</label>
                    <button className="btn btn-style1 btn-green btn-save">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* end row */}
            {/* Modal */}
            <div
              className="modal fade"
              id="viewOrderDetail"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog  modal-order-details"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="order-details brdr-none">
                      <h2>ORDER DETAILS</h2>
                      <div className="od-list">
                        <label>Order Date:</label> July 19, 2021
                      </div>
                      <div className="od-list">
                        <label>Order Number:</label> #5896254
                      </div>
                      <div className="od-list">
                        <label>Order Total:</label> $5,900
                      </div>
                    </div>
                    {/* end  order-details */}
                    <div className="order-details brdr-none">
                      <h2>PRODUCT DETAILS</h2>
                      <div className="od-list">
                        <strong>Product</strong> <br />
                      </div>
                      <div className="od-list mt-2 mb-2">
                        <img src={Image} alt="" />
                        <img src={Image} alt="" />
                        <img src={Image} alt="" />
                        <img src={Image} alt="" />
                        <img src={Image} alt="" />
                        <img src={Image} alt="" />
                      </div>
                      <div className="od-list mb-2">
                        Beckman Coulter Life Science UV/Vis Spectrophotometer DU
                        730
                      </div>
                      <div className="od-list">
                        <label>Catagory:</label> Test &amp; Measurement
                      </div>
                      <div className="od-list">
                        <label>Brand:</label> Rigaku
                      </div>
                      <div className="od-list">
                        <label>Seller:</label> SeqGen
                      </div>
                    </div>
                    {/* end  order-details */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="order-details brdr-none">
                          <h2>SHIPPING DETAILS</h2>
                          <div className="od-list">Chris Wattson</div>
                          <div className="od-list mt-2">
                            <strong>Shipping Address</strong> <br />
                            12345 Jones Rd. Suite #246 <br />
                            Houston, TX 77075
                          </div>
                        </div>
                        {/* end  order-details */}
                      </div>
                      <div className="col-md-6">
                        <div className="order-details brdr-none">
                          <h2>PREFERRED SHIPPING METHOD</h2>
                          <div className="od-list">
                            Ground Shipping
                            <br />
                            Seller Preferred Shipping
                          </div>
                        </div>
                        {/* end  order-details */}
                      </div>
                    </div>
                    <div className="order-details brdr-none">
                      <h2>PREFERRED PAYMENT METHOD</h2>
                      <div className="od-list">Credit Card</div>
                    </div>
                    {/* end  order-details */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Order;
