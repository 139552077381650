import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
// import styled from "styled-components";
import { styled } from "@mui/material/styles";

import productImage from "../../assets/images/pro-img.jpg";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  Typography,
  IconButton,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Image from "../../assets/images/img-02.jpg";
import api from "../../api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "../../lib/context";
import CustomLoader from "../../components/CustomLoader";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { logout } from "../../utils/utils";
import SellerSidebar from "../Sidebar/SellerSidebar";
import SellerTitle from "../PageTitle/SellerTitle";
import editImg from "../../assets/images/icon-edit.png";
import deleteImg from "../../assets/images/icon-delete2.png";
// import { Tooltip } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Box } from "@mui/material";
import Footer from "../../layout/Footer";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    placement="bottom-start"
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 420,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
function AllProducts() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const { openSnackbar } = useSnackbar();
  const { push } = useHistory();
  const [deleteId, setDeleteId] = useState("");
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };
  const handleDeleteDialogOpen = (id) => {
    setDeleteId(id);
    setIsDeleteDialogOpen(true);
  };
  const handleDeleteDialogClose = () => {
    setDeleteId("");
    setIsDeleteDialogOpen(false);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const toggling = () => {
    setIsOpen(!isOpen);
    setIsOn(false);
  };

  const [isOn, setIsOn] = useState(true);
  const toggling1 = () => {
    setIsOn(!isOn);
    setIsOpen(false);
  };

  const onOptionClicked = (value) => () => {
    console.log(selectedOption);
  };

  const onOptionzClicked = (value) => () => {
    console.log(selectedOption);
  };
  const [option, setOption] = useState({
    page: 1,
    size: 5,
    sortBy: "productname",
    sortOrder: "asc",
    search: "",
  });

  const [totalElem, setTotalElem] = useState();
  const fetchData = async () => {
    try {
      const { data } = await api.post("product", option);
      setAllProducts(data?.body.content);
      setTotalElem(data?.body.totalElements);
    } catch (error) {
      openSnackbar("error", error.message);
      // if (
      //   error.response !== null ||
      //   error.response.data.body !== null ||
      //   error.response.data.body.errors.length !== 0
      // ) {
      //   error.response.data.body.errors.forEach((e) => openSnackbar("error", e));
      // } else {
      //   openSnackbar("error", error.message);
      // }
    }
  };
  useEffect(() => {
    fetchData();
  }, [option]);

  const handleDelete = async (productId) => {
    try {
      const { data } = await api.delete(`product/${deleteId}`);
      setAllProducts(allProducts.filter((product) => product.id != deleteId));
      handleDeleteDialogClose();
      openSnackbar("success", "Product is deleted.");
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  // if (!allProducts) {
  //   return <CustomLoader />;
  // }
  const handleSort = (column) => {
    console.log(column);
    setOption({
      ...option,
      sortBy: column,
      sortOrder:
        option.sortBy === column && option.sortOrder === "asc" ? "desc" : "asc",
    });
  };
  return (
    <div>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleDeleteDialogClose}
        TransitionComponent={Grow}
      >
        <DialogContent>
          {/* <div className="modal" onClick={handleDialogClose}> */}
          {/* <div
							className="modal-content"
							onClick={(e) => e.stopPropagation()}
						> */}
          {/* <div className="modal-body"> */}
          <HighlightOffIcon
            style={{ color: "#48a068", float: "right" }}
            onClick={handleDeleteDialogClose}
          />
          <div>
            <Typography>
              Are you sure you want to delete this product?
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button onClick={() => handleDeleteDialogClose()}>Cancel</Button>
              <Button onClick={() => handleDelete()}>Confirm</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        TransitionComponent={Grow}
      >
        <DialogContent>
          {/* <div className="modal" onClick={handleDialogClose}> */}
          {/* <div
							className="modal-content"
							onClick={(e) => e.stopPropagation()}
						> */}
          {/* <div className="modal-body"> */}
          <HighlightOffIcon
            style={{ color: "#48a068", float: "right" }}
            onClick={handleDialogClose}
          />
          {/* <button
							onClick={handleDialogClose}
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>

							<span aria-hidden="true">×</span>
						</button> */}

          <div className="order-details brdr-none">
            <div id="watchlist-img" className="od-list mt-2 mb-2">
              <img src={Image} alt="" />
              <img src={Image} alt="" />
              <img src={Image} alt="" />
            </div>
            <div className="od-list mb-2">
              <strong>
                Beckman Coulter Life Science UV/Vis Spectrophotometer DU 730
              </strong>
            </div>
            <div className="od-list mb-2">
              <div className="order-details brdr-none">
                Product description: Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eius mod tempor incididunt
              </div>
              {/* end  order-details */}
            </div>
            <div className="od-list">
              <label id="watchlistitem">Catagory:</label> Electronics &amp;
              Components
            </div>
            <div className="od-list">
              <label id="watchlistitem">Brand:</label> Beckman
            </div>
            <div className="od-list">
              <label id="watchlistitem">Location:</label> Houston, TX
            </div>
            <div className="od-list">
              <label id="watchlistitem">Price:</label> $19,990.00
            </div>
          </div>
          {/* end  order-details */}

          {/* end  order-details */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
        </DialogContent>
      </Dialog>
      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      {/* Bootstrap CSS */}
      <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
      <link rel="stylesheet" href="assets/css/brands.css" />
      <link rel="stylesheet" href="assets/css/solid.css" />
      <link rel="stylesheet" href="assets/css/style.css" />
      <title>The Xchange Marketplace</title>
      <div id="dashboard" className="col-2-layout">
        <div className="left-sidebar equalColumn">
          <div className="box-content">
            <SellerSidebar pageName="products" />
          </div>
        </div>
        {/* end left-sidebar */}
        <div className="right-content equalColumn">
          <div className="box-content title-box">
            <SellerTitle />
          </div>
          {/* end box-content */}
          <div className="box-content minColheight">
            <div className="row" id="dashboard-tabs">
              <div className="col-md-12">
                <nav>
                  <div
                    className="nav nav-tabs"
                    id="nav-tab"
                    role="tablist"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ alignSelf: "center" }}>
                      <a
                        className="nav-item"
                        id="nav-account-tab"
                        data-toggle="tab"
                        href="#nav-account"
                        role="tab"
                        aria-controls="nav-account"
                        aria-selected="true"
                      >
                        <Link to="/products">RECENT PRODUCTS</Link>
                      </a>
                      <a
                        className="nav-item active"
                        id="nav-account-tab"
                        data-toggle="tab"
                        href="#nav-account"
                        role="tab"
                        aria-controls="nav-account"
                        aria-selected="true"
                      >
                        <Link to="/allproducts">ALL PRODUCTS</Link>
                      </a>
                    </div>
                    {/* <a
											id="addprod"
											href="/addproduct"
										className="nav-item"
										> */}
                    <Button
                      style={{
                        float: "right",
                        backgroundColor: "#51a470",
                        marginBottom: 10,
                        color: "white",
                      }}
                      variant="contained"
                      color="primary"
                      href="/addproduct"
                    >
                      Add A Product
                    </Button>
                    {/* </a> */}
                  </div>
                </nav>
              </div>
              {/* <div className="col-md-3 text-right">
										<label>&nbsp;</label>
										<button className="btn btn-style1 btn-green btn-save">
											Add A Product
										</button>
									</div> */}
              <div
                className="tab-content"
                id="nav-tabContent"
                style={{
                  width: "100%",
                  paddingRight: "12px",
                  paddingLeft: "12px",
                }}
              >
                <div
                  className="tab-pane fade show active"
                  id="nav-activeOrders"
                  role="tabpanel"
                  aria-labelledby="nav-activeOrders-tab"
                >
                  {/* <div className="payments-content">
                    {allProducts.length == 0 ? (
                      <label>No Record Found</label>
                    ) : (
                      <div className="paymentsCon-thead">
                        <div id="prod" className="th-col tg-pro">
                          Product
                        </div>
                        <div className="th-col tg-prod-price">
                          Type of Instrument
                        </div>
                        <div className="th-col ">Manufacturer</div>
                        <div className="th-col tg-prod-price">Price</div>
                        <div className="th-col tg-action"></div>
                      </div>
                    )}
                    {allProducts.map((product) => (
                      <div
                        // onClick={handleDialogOpen}
                        className="paymentsCon-item-row"
                      >
                        <div id="prod" className="td-col tg-pro">
                          <div className="tg-pro-img">
                            {product.primaryfile && product.primaryfile.url ? (
                              <img
                                src={product.primaryfile.url}
                                style={{
                                  width: "60px",
                                }}
                                alt=""
                                className="img-responsive"
                              />
                            ) : (
                              <ImageNotSupportedIcon
                                style={{
                                  fontSize: "50px",
                                  color: "#6e6e6e",
                                }}
                              />
                            )}
                          </div>{" "}
                          {product.productname}
                          <div
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              // width: "10em",
                            }}
                          >
                            {product.description}
                          </div>
                        </div>
                        <div className="td-col tg-prod-price">
                          {product.sellinginstrument}
                        </div>
                        <div className="td-col tg-prod-price">
                          {product.manufacturer}
                        </div>
                        <div className="td-col tg-prod-price">
                          ${product.price}
                        </div>
                        <div
                          id="delete"
                          className="td-col tg-action"
                          //   onClick={() =>
                          //     // handleDelete(product.id)
                          //     handleDeleteDialogOpen(product.id)
                          //   }
                        >
                          <IconButton
                            className="mr-4"
                            onClick={() => {
                              push(`editproduct/${product.id}`);
                            }}
                          >
                            <img src={editImg} alt="" width="25"></img>
                          </IconButton>
                          <IconButton
                            // className="mr-3"
                            onClick={() =>
                              // handleDelete(product.id)
                              handleDeleteDialogOpen(product.id)
                            }
                          >
                            <img src={deleteImg} alt="" width="25"></img>
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div> */}
                  {/* <table className="payments-content">
                    <thead>
                      {allProducts.length === 0 ? (
                        <tr>
                          <td colSpan="5">No Record Found</td>
                        </tr>
                      ) : (
                        <tr className="paymentsCon-thead">
                          <th
                            id="prod"
                            className="th-col tg-pro"
                            style={{ width: "20%" }}
                          >
                            Product
                          </th>
                          <th
                            className="th-col tg-prod-price"
                            style={{ width: "20%" }}
                          >
                            Type of Instrument
                          </th>
                          <th
                            className="th-col tg-prod-price"
                            style={{ width: "20%" }}
                          >
                            Manufacturer
                          </th>
                          <th
                            className="th-col tg-prod-price"
                            style={{ width: "20%" }}
                          >
                            Price
                          </th>
                          <th className="th-col tg-action"></th>
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {allProducts.map((product) => (
                        <tr
                          key={product.id}
                          // onClick={handleDialogOpen}
                          className="paymentsCon-item-row"
                        >
                          <td
                            id="prod"
                            className="td-col tg-pro"
                            style={{ width: "20%" }}
                          >
                            <div className="tg-pro-img">
                              {product.primaryfile &&
                              product.primaryfile.url ? (
                                <img
                                  src={product.primaryfile.url}
                                  style={{
                                    width: "60px",
                                  }}
                                  alt=""
                                  className="img-responsive"
                                />
                              ) : (
                                <ImageNotSupportedIcon
                                  style={{
                                    fontSize: "50px",
                                    color: "#6e6e6e",
                                  }}
                                />
                              )}
                            </div>{" "}
                            {product.productname}
                            <div
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                // width: "10em",
                              }}
                            >
                              {product.description}
                            </div>
                          </td>
                          <td
                            className="td-col tg-prod-price"
                            style={{ width: "20%" }}
                          >
                            {product.sellinginstrument}
                          </td>
                          <td
                            className="td-col tg-prod-price"
                            style={{ width: "20%" }}
                          >
                            {product.manufacturer}
                          </td>
                          <td
                            className="td-col tg-prod-price"
                            style={{ width: "20%" }}
                          >
                            ${product.price}
                          </td>
                          <td id="delete" className="td-col tg-action">
                            <IconButton
                              className="mr-4"
                              onClick={() => {
                                push(`editproduct/${product.id}`);
                              }}
                            >
                              <img src={editImg} alt="" width="25"></img>
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                // handleDelete(product.id)
                                handleDeleteDialogOpen(product.id)
                              }
                            >
                              <img src={deleteImg} alt="" width="25"></img>
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                  {allProducts && allProducts.length > 0 ? (
                    <table className="table">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            onClick={() => handleSort("product.productname")}
                          >
                            Product{" "}
                            <span>
                              {option.sortBy === "product.productname" &&
                              option.sortOrder === "asc"
                                ? "↑"
                                : "↓"}
                            </span>
                          </th>
                          <th scope="col">Description</th>
                          <th scope="col">Type of Instrument</th>
                          <th scope="col" style={{ width: "10%" }}>
                            Manufacturer
                          </th>
                          <th scope="col">Price</th>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {allProducts?.map((product, index) => (
                          <tr key={index}>
                            <td>
                              <div id="prod" className="td-col tg-pro">
                                <div className="tg-pro-img">
                                  {product.primaryfile &&
                                  product.primaryfile.url ? (
                                    <img
                                      src={product.primaryfile.url}
                                      style={{
                                        width: "60px",
                                      }}
                                      alt=""
                                      className="img-responsive"
                                    />
                                  ) : (
                                    <ImageNotSupportedIcon
                                      style={{
                                        fontSize: "50px",
                                        color: "#6e6e6e",
                                      }}
                                    />
                                  )}
                                </div>{" "}
                                {product.productname}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div
                                style={{
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  width: "7em",
                                }}
                              >
                                {/* <Tooltip
                                    title={product.description}
                                    placement="bottom-start"
                                  >
                                    {product.description}
                                  </Tooltip> */}
                                <Box>
                                  <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <Typography color="inherit">
                                          {product.description}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                  >
                                    {" "}
                                    {product.description}{" "}
                                  </HtmlTooltip>
                                </Box>
                              </div>
                            </td>
                            <td>
                              <div className="mt-2">
                                {product.sellinginstrument}
                              </div>
                            </td>
                            <td>
                              <div className="mt-2">{product.manufacturer}</div>
                            </td>
                            <td>
                              <div className="mt-2">
                                $ {/* ${product.price} */}
                                {product.discountprice !== ""
                                  ? product.discountprice
                                  : product.price}{" "}
                              </div>
                            </td>
                            <td>
                              <IconButton
                                onClick={() =>
                                  push(`editproduct/${product.id}`)
                                }
                              >
                                <img src={editImg} alt="" width="20" />
                              </IconButton>
                            </td>
                            <td>
                              <IconButton
                                onClick={() =>
                                  handleDeleteDialogOpen(product.id)
                                }
                              >
                                <img src={deleteImg} alt="" width="19" />
                              </IconButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <h5 className="text-center">No products</h5>
                  )}

                  <div className="d-flex flex-row-reverse">
                    <div>
                      <select
                        className="form-select mt-2 mx-2"
                        value={option.size}
                        onChange={(e) =>
                          setOption({ ...option, size: e.target.value })
                        }
                      >
                        {/* <option selected>{option.size}</option> */}
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="100">100</option>
                      </select>
                    </div>

                    <nav aria-label="...">
                      <ul className="pagination">
                        <li
                          className={`page-item ${
                            option.page === 1 ? "disabled" : ""
                          }`}
                        >
                          <span
                            className="page-link"
                            onClick={() =>
                              setOption({ ...option, page: option.page - 1 })
                            }
                            style={{ color: "black" }}
                          >
                            Previous
                          </span>
                        </li>
                        {Array.from(
                          { length: Math.ceil(totalElem / option.size) },
                          (_, index) => (
                            <li
                              key={index}
                              className={`page-item ${
                                option.page === index + 1 ? "active" : ""
                              }`}
                            >
                              <span
                                className="page-link"
                                onClick={() =>
                                  setOption({
                                    ...option,
                                    page: index + 1,
                                  })
                                }
                                style={
                                  {
                                    // backgroundColor: "#4ba26b",
                                    // color: "black",
                                  }
                                }
                              >
                                {index + 1}
                              </span>
                            </li>
                          )
                        )}
                        <li
                          className={`page-item ${
                            option.page === Math.ceil(totalElem / option.size)
                              ? "disabled"
                              : ""
                          }`}
                        >
                          <span
                            className="page-link"
                            onClick={() =>
                              setOption({
                                ...option,
                                page: option.page + 1,
                              })
                            }
                            style={{ color: "black" }}
                          >
                            Next
                          </span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Optional JavaScript */}
          {/* jQuery first, then Popper.js, then Bootstrap JS */}
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default AllProducts;
