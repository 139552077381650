import React from 'react';
import Loader from 'react-loader-spinner';

function CustomLoader(props) {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100vh',
				width: '100vw',
			}}
		>
			<Loader
				type="Circles"
				color="#898f8f"
				height={75}
				width={75}
				// timeout={3000} //3 secs
			/>
		</div>
	);
}

export default CustomLoader;
