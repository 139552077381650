import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import styled from "styled-components";
import { useHistory } from "react-router-dom";
import SellerSidebar from "./Sidebar/SellerSidebar";
import { logout } from "../utils/utils";
import SellerTitle from "./PageTitle/SellerTitle";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  Typography,
  Grid,
} from "@material-ui/core";
import { useSnackbar } from "../lib/context";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import api from "../api";
import CustomTextarea from "../components/CustomTextarea";
import CustomLoader from "../components/CustomLoader";
import { formatDate2, formatTime } from "../utils/utils";
import { Button, styled } from "@mui/material";
import Footer from "../layout/Footer";

const Dot = styled("div")({
  height: "13px",
  width: "13px",
  borderRadius: "50%",
  backgroundColor: "red",
});
function Messages() {
  const { push } = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const { openSnackbar } = useSnackbar();
  const [messages, setMessages] = useState();
  const [messageColumn, setMessageColumn] = useState();
  const [isMessageColumnOpen, setIsMessageColumnOpen] = useState(true);

  const [selectedOption, setSelectedOption] = useState(null);

  const toggling = () => {
    setIsOpen(!isOpen);
    setIsOn(false);
  };

  const [isOn, setIsOn] = useState(false);
  const toggling1 = () => {
    setIsOn(!isOn);
    setIsOpen(false);
  };

  const onOptionClicked = (value) => () => {
    console.log(selectedOption);
  };

  const onOptionzClicked = (value) => () => {
    console.log(selectedOption);
  };
  const fetchData = async () => {
    try {
      const { data } = await api.get(`/message/getAll`);
      //   ?user=${JSON.parse(localStorage.getItem("data")).id}
      setMessages(data);
      if (!messageColumn) {
        setMessageColumn({
          message: data.length ? data[0].messageContentObject : [],
          orderid: data.length ? data[0].orderid : "",
          buyerId: data.length ? data[0].orderData.buyerAccountData.id : "",
          sellerId: data.length ? data[0].orderData.sellerAccountData.id : "",
        });
      } else {
        let oid = data.find((item) => item.orderid == messageColumn.orderid);
        setMessageColumn({
          message: oid.messageContentObject,
          orderid: oid.orderid,
          buyerId: oid.orderData.buyerAccountData.id,
          sellerId: oid.orderData.sellerAccountData.id,
        });
      }
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  const handleSubmit = async (values, { resetForm }) => {
    try {
      const newVal = { ...values, notify: true };

      const { data } = await api.post("message", newVal);
      resetForm();
      fetchData();
      openSnackbar("success", "Message Sent Successfully");
      // setOrders(data.filter((d) => d.orderstatus == 'New Order'));
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (!messages || !messageColumn) {
    return <CustomLoader />;
  }
  const handleCloseButtonClick = () => {
    setIsMessageColumnOpen(false);
  };
  const handleUpdateNotification = async (id) => {
    try {
      const { data } = await api.put(
        `/message/closeNotification?id=${id}&status=false`
      );
      // fetchData();
      // openSnackbar("success", "Message Sent Successfully");
      // setOrders(data.filter((d) => d.orderstatus == 'New Order'));
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  for (let message of messages) {
    for (let item of message.messageContentObject) {
      if (item.usertype === "buyer" && item.notify) {
        message.notify = true;
        break;
      }
    }
  }
  return (
    <div>
      <div>
        {/* Required meta tags */}
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {/* Bootstrap CSS */}
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
        <link rel="stylesheet" href="assets/css/brands.css" />
        <link rel="stylesheet" href="assets/css/solid.css" />
        <link rel="stylesheet" href="assets/css/style.css" />
        <title>The Xchange Marketplace</title>
        <div id="dashboard" className="col-2-layout">
          <div className="left-sidebar equalColumn">
            <div className="box-content">
              <SellerSidebar pageName="messages" />
            </div>
          </div>
          {/* end left-sidebar */}
          <div className="right-content equalColumn">
            <div className="box-content title-box">
              <SellerTitle />
            </div>
            {/* end box-content */}
            <div className="box-content minColheight">
              <div className="row" id="dashboard-tabs">
                <div className="col-md-12">
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <a
                        className="nav-item active"
                        id="nav-activeOrders-tab"
                        data-toggle="tab"
                        href="#nav-activeOrders"
                        role="tab"
                        aria-controls="nav-activeOrders"
                        aria-selected="true"
                      >
                        Messages
                      </a>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-activeOrders"
                      role="tabpanel"
                      aria-labelledby="nav-activeOrders-tab"
                    >
                      <div className="form-sec" id="messages-sec">
                        {messages.map((item) => (
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setMessageColumn({
                                message: item.messageContentObject,
                                orderid: item.orderid,
                                buyerId: item.orderData.buyerAccountData.id,
                                sellerId: item.orderData.sellerAccountData.id,
                              });
                              setIsMessageColumnOpen(true); // Set isMessageColumnOpen to true
                            }}
                          >
                            <div
                              className="row order-details align-items-center"
                              onClick={() => {
                                handleUpdateNotification(item.orderid);
                              }}
                            >
                              <div className="col-md-10">
                                <h3>
                                  {item.orderData.buyerAccountData.firstname +
                                    " " +
                                    item.orderData.buyerAccountData.lastname}
                                </h3>
                                <p>
                                  <div>
                                    {item.orderData.product[0].productname}
                                  </div>
                                  ${item.orderData.product[0].price}
                                </p>
                                {/* <p className="ms-date">07/19/21</p>
                            <p className="ms-delete">
                              <a href="#">
                                <img
                                  src="assets/images/icon-delete.png"
                                  alt=""
                                  width={16}
                                />
                              </a>
                            </p> */}
                              </div>
                              <div className="col-md-2">
                                {item.notify && (
                                  <Dot sx={{ ml: "auto", mr: 2 }} />
                                )}
                                {/* <Dot sx={{ ml: "auto", mr: 2 }} /> */}
                              </div>
                            </div>
                          </a>
                        ))}

                        {/* <div className="row order-details">
                          <div className="col-md-11">
                            <h3>Chris Wattson</h3>
                            <p>
                              Biotech Equipment Sales <br />
                              Houston, TX
                              <br />
                              832-963-5269
                            </p>
                            <p className="ms-date">07/19/21</p>
                            <p className="ms-delete">
                              <a href="#">
                                <img
                                  src="assets/images/icon-delete.png"
                                  alt=""
                                  width={16}
                                />
                              </a>
                            </p>
                          </div>
                        </div> */}
                        {/* end row */}
                      </div>
                      {isMessageColumnOpen &&
                        messageColumn.message.length > 0 && (
                          <div
                            className="messages-sec-right"
                            style={{ backgroundColor: "#f6f6f6" }}
                          >
                            <>
                              <div
                                className="mb-4"
                                style={{ textAlign: "end" }}
                              >
                                <button
                                  className="btn btn-sm btn-dark"
                                  onClick={handleCloseButtonClick}
                                  style={{ cursor: "pointer" }}
                                >
                                  Close
                                </button>
                              </div>

                              <div className="msgWraper">
                                <p>
                                  {/* Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. */}
                                </p>
                                {messageColumn.message &&
                                  messageColumn.message.map((item) => (
                                    // <div
                                    //   style={{
                                    //     textAlign:
                                    //       item.usertype == "buyer" ? "right" : "",
                                    //     width: "100%",
                                    //   }}
                                    // >
                                    //   <div style={{}}>
                                    //     <div className="date">
                                    //       {formatDate2(
                                    //         new Date(item.messagedate)
                                    //       )}
                                    //     </div>
                                    //     <div className="message">
                                    //       {item.messagetext}
                                    //     </div>
                                    //     <div className="time">
                                    //       {formatTime(new Date(item.messagedate))}
                                    //     </div>
                                    //   </div>
                                    // </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent:
                                          item.usertype === "seller"
                                            ? "right"
                                            : "left",
                                        maxWidth: "100%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          backgroundColor:
                                            item.usertype === "seller"
                                              ? "#DCF8C6"
                                              : "#E2E2E2",
                                          borderRadius: "10px",
                                          padding: "10px",
                                          width:
                                            item.usertype === "seller"
                                              ? "50%"
                                              : "50%",
                                          margin: "10px 0",
                                          marginLeft:
                                            item.usertype === "seller"
                                              ? "0"
                                              : "1%",
                                          marginRight:
                                            item.usertype === "seller"
                                              ? "1%"
                                              : "0",
                                        }}
                                      >
                                        <div
                                          className="message"
                                          style={{
                                            fontSize: "18px",
                                            wordWrap: "break-word",
                                            textAlign:
                                              item.usertype === "seller"
                                                ? "right"
                                                : "start",
                                          }}
                                        >
                                          {item.messagetext}
                                        </div>
                                        <div
                                          className="date"
                                          style={{
                                            textAlign:
                                              item.usertype === "seller"
                                                ? "right"
                                                : "right",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {formatDate2(
                                            new Date(item.messagedate)
                                          )}
                                          {"   "}
                                          {formatTime(
                                            new Date(item.messagedate)
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              <div className="full-block mt-3 btn-reply">
                                <Formik
                                  enableReinitialize
                                  initialValues={{
                                    orderid: messageColumn.orderid,
                                    buyerId: messageColumn.buyerId,
                                    sellerId: messageColumn.sellerId,
                                    messagetext: "",
                                    usertype: "seller",
                                  }}
                                  validationSchema={yup.object().shape({
                                    messagetext: yup.string().required(""),
                                  })}
                                  onSubmit={handleSubmit}
                                >
                                  {({ values, setFieldValue }) => {
                                    console.log(
                                      values,
                                      "MMM",
                                      messageColumn.orderid
                                    );
                                    return (
                                      <Form>
                                        <Grid container>
                                          <Grid item xs={8} md={9}>
                                            <Field
                                              name="messagetext"
                                              as={CustomTextarea}
                                              // label="Type Message"
                                              // className="form-control style2"
                                              placeholder="Type Message"
                                              // className="form-control style2"
                                              rows={2}
                                              className="mx-4 mb-4"
                                            />
                                          </Grid>
                                          <Grid item xs={4} md={3}>
                                            <Button
                                              // className="btn btn-style1 btn-green mt-2 pt-3"
                                              sx={{
                                                px: { xs: 1, md: 6 },
                                                mr: { xs: 7, md: 2 },
                                                fontSize: {
                                                  xs: "10px",
                                                  md: "1.5rem",
                                                },
                                                mt: 2,
                                                borderRadius: "20px",
                                                backgroundColor: "#48a068",
                                              }}
                                              variant="contained"
                                              size="small"
                                              type="submit"
                                            >
                                              Reply
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      </Form>
                                    );
                                  }}
                                </Formik>
                              </div>
                            </>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end right -sidebar */}
        </div>

        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
        <Footer />
      </div>
    </div>
  );
}

export default Messages;
