import React, { useState, useEffect } from "react";
import "font-awesome/css/font-awesome.min.css";

function SellerTitle() {
  const [username, setUsername] = useState("");
  useEffect(() => {
    if (localStorage.getItem("data") && localStorage.getItem("data") != null) {
      var firstname = JSON.parse(localStorage.getItem("data")).firstname;
      var lastname = JSON.parse(localStorage.getItem("data")).lastname;
      setUsername(firstname + " " + lastname);
    }
  }, []);
  return (
    <div>
      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      {/* Bootstrap CSS */}
      <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
      <link rel="stylesheet" href="assets/css/brands.css" />
      <link rel="stylesheet" href="assets/css/solid.css" />
      <link rel="stylesheet" href="assets/css/style.css" />

      <title>The Xchange Marketplace</title>

      {/* end left-sidebar */}
      <div className="page-title">seller</div>
      <div className="right-top-link">
        {/* <a href="#" className="link-alert">
          <i className="fas fa-bell" />
        </a> */}
        <h3>
          <a href="/myaccount">
            {/* <i className="fas fa-chevron-down" /> */}
            Hello, {username ?? ""} &nbsp;
          </a>
        </h3>
      </div>
    </div>
  );
}

export default SellerTitle;
