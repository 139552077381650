import React, { useState } from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import CustomTextField from "../components/CustomTextField";
import CustomCheckbox from "../components/CustomCheckbox";
import CustomButton from "../components/CustomButton";
import { useHistory } from "react-router-dom";
import api from "../api";
import * as yup from "yup";
import OtpInput from "react-otp-input";
import { Box } from "@mui/material";
import { useSnackbar } from "../lib/context";

function ForgotPasswordForm({ setShowForgotPassword }) {
  const { push } = useHistory();
  const { openSnackbar } = useSnackbar();
  const [email, setEmail] = useState();
  const handleSubmit = (values) => {
    setShowForgotPassword(false);
  };

  const handleSendOTP = async (values, { resetForm }) => {
    try {
      const { data } = await api.get(
        `/auth/sendForgotPasswordMail?email=${values.email}`
      );
      resetForm();
      setEmail(values.email);
      // openSnackbar('success', 'Signup successfully.')
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };

  const handleResetPassword = async (values, { resetForm }) => {
    try {
      console.log(String(email), "Emai ", {
        ...values,
        email: String(email),
      });
      const { data } = await api.put(`auth/setForgotPassword`, {
        ...values,
        email: email,
      });
      setShowForgotPassword(false);
      resetForm({});
      openSnackbar("success", "Password changed successfully.");
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  return (
    <div style={{ maxWidth: "420px" }}>
      <Typography
        style={{
          fontSize: "28px",
          color: "#4aa56d",
          // textTransform: 'uppercase',
          fontWeight: 600,
          marginBottom: "8px",
        }}
      >
        Forgot your password?
      </Typography>
      {!email ? (
        <>
          <Typography
            style={{
              fontSize: "14px",
              color: "#737979",
              marginBottom: "16px",
            }}
          >
            Please enter the email address registered on your account to reset
            your password
          </Typography>
          <Formik
            initialValues={{ email: "" }}
            validationSchema={yup.object().shape({
              email: yup
                .string()
                .email("Invalid Email.")
                .required("Email is required."),
            })}
            onSubmit={handleSendOTP}
          >
            {({ values, setFieldValue, ...rest }) => {
              return (
                <Form>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Field
                        as={CustomTextField}
                        name="email"
                        label="Email"
                        placeholder="Email"
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "12px" }}>
                      <CustomButton
                        type="submit"
                        variant="contained"
                        style={{ width: "100%" }}
                        color="primary"
                        disabled={rest.isSubmitting}
                      >
                        Send OTP
                      </CustomButton>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </>
      ) : (
        <>
          <Typography
            style={{
              fontSize: "14px",
              color: "#737979",
              marginBottom: "16px",
            }}
          >
            Please enter the new password and otp to reset your password
          </Typography>
          <Formik
            initialValues={{
              email: email,
              newPassword: "",
              confirmPassword: "",
              otp: "",
            }}
            validationSchema={yup.object().shape({
              newPassword: yup
                .string()
                .min(8, "Password must be at least 8 characters long.")
                .matches(
                  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
                  "Password must contain at least one number and one special character."
                )
                .required("Password is required."),
              confirmPassword: yup
                .string()
                .oneOf([yup.ref("newPassword")], "Passwords do not match")
                .required("Please re-type your password"),
              otp: yup.string().required("OTP is required."),
            })}
            onSubmit={handleResetPassword}
          >
            {({ values, setFieldValue, ...rest }) => {
              return (
                <Form>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Field
                        as={CustomTextField}
                        name="newPassword"
                        label="New Password"
                        placeholder="New Passwword"
                        type="password"
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={CustomTextField}
                        name="confirmPassword"
                        label="Confirm Password"
                        placeholder="Confirm Passwword"
                        type="password"
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Box>
                      {/* <Button onClick={() => handleSubmit(sendOtp())}>Send OTP</Button> */}
                    </Box>
                    <Grid item xs={12}>
                      <Typography
                        sx={{ fontSize: "3rem", fontWeight: "bold" }}
                        // variant="h6"
                      >
                        Enter Verification Code
                      </Typography>

                      <OtpInput
                        inputType="tel"
                        numInputs={4}
                        renderSeparator={<span> - </span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={{
                          width: "45px",
                          borderRadius: "10px",
                          height: "45px",
                        }}
                        containerStyle={{
                          display: "flex",
                          // justifyContent: "center",
                          alignItems: "center",
                        }}
                        onChange={(otp) => setFieldValue("otp", otp)}
                        value={values.otp}
                      />
                      {rest.errors["otp"] && (
                        <span className="errorText">{rest.errors["otp"]}</span>
                      )}
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: "12px" }}>
                      <CustomButton
                        type="submit"
                        variant="contained"
                        style={{ width: "100%" }}
                        color="primary"
                        disabled={rest.isSubmitting}
                      >
                        Reset Password
                      </CustomButton>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </div>
  );
}

export default ForgotPasswordForm;
