import React from "react";
import Layout from "../layout/Layout";
import video from "../assets/videos/Welcome to the Xchange Marketplace (1).mp4";
import { Box, Button, Grid, Typography } from "@mui/material";

function AboutUs() {
    return (
        <Layout pageName="AboutUs">
            {/* <div className="container text-center my-2">
                <h1>About Us</h1>
            </div> */}
            <div className="main">
                {/* <div className="jumbotron">
                    <div className="container">
                        <h2>About Us</h2>
                        <div>
                            <a
                                className="btn btn-primary btn-md"
                                href="/loginpage/seller"
                                role="button"
                            >
                                Join Us
                            </a>
                        </div>
                    </div>
                </div> */}
                <Grid container sx={{ my: 5 }}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                background:
                                    "url(/static/media/banner-02.553b564a.png);",
                                backgroundSize: { xs: "100% 100%" },
                                minHeight: "50vh",
                                py: 7,
                                px: { xs: 2, md: 6 },

                            }}
                        >
                            <Typography
                                variant="h2"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: { xs: "1.5rem", md: "3rem" },
                                    mt: { xs: 10, md: 12 },
                                }}
                            >
                                About Us
                            </Typography>

                            <Box>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        backgroundColor: "rgb(248, 158, 35)",
                                        mt: 3,
                                        px: 3,
                                        borderRadius: "15px",
                                        fontSize: { xs: ".7rem", md: "1.3rem" },
                                    }}
                                >
                                    Join Us
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <div className="container my-3 mt-5">
                    <h2 style={{ fontSize: "24px", fontWeight: "600" }}>
                        THE XCHANGE MARKETPLACE Provides Equipment Buying & Selling  Opportunities for the Industrial Testing & Scientific Community
                    </h2>
                    <p style={{ fontSize: "16px" }}>Welcome to THE XCHANGE MARKETPLACE where we support the Industrial Testing &  Scientific community by providing a one-stop platform for the Purchase, Sale, and  Exchange of high-quality laboratory equipment.  </p>
                </div>
                <div className="container">
                    <h3 style={{ fontSize: "20px", fontWeight: "600" }}>
                        Our Story
                    </h3>
                    <p style={{ fontSize: "16px" }}>Founded by a team of dedicated scientists and engineers, our journey began when we  recognized the challenges that the Industrial community face in sourcing reliable and cost effective equipment. We saw an opportunity to bridge the gap between buyers and sellers,  creating a marketplace where transparency, quality, and convenience reign supreme.
                    </p>
                </div>
                <div className="container">
                    <h3 style={{ fontSize: "20px", fontWeight: "600" }}>
                        Our Commitment
                    </h3>
                    <p style={{ fontSize: "19px", fontWeight: "600" }}>
                        At THE XCHANGE Marketplace, we are committed to providing you with a seamless and  trustworthy experience. Here is what sets us apart:
                    </p>
                    <ul>
                        <li style={{ fontSize: "18px" }}> <strong> Quality Assurance: </strong>We understand the critical role that testing equipment plays, that is why we carefully vet each listing to ensure that only the highest-quality  products make it to our marketplace.
                        </li>
                        <li style={{ fontSize: "18px" }}> <strong> Diverse Selection: </strong>You will find a wide range of options from reputable sellers.</li>
                        <li style={{ fontSize: "18px" }}> <strong>User-Friendly Platform: </strong>Our intuitive website is designed with your convenience  in mind. Easily browse, compare, and purchase equipment with just a few clicks.</li>
                        <li style={{ fontSize: "18px" }}> <strong>Secure Transactions: </strong> THE XCHANGE MARKETPLACE fosters a sense of community  by providing resources, educational content, and a platform for professionals to  connect and collaborate.</li>
                    </ul>
                </div>
                <div className="container">
                    <h3 style={{ fontSize: "20px", fontWeight: "600" }}>Our Vision </h3>
                    <p style={{ fontSize: "16px" }}>Our vision is to be the go-to destination for Industrial Testing & Laboratory equipment, a  place where technical professionals can find equipment to advance their work. </p>
                </div>
                <div className="container">
                    <p style={{ fontSize: "16px" }}>Thank you for choosing THE XCHANGE MARKETPLACE as your trusted partner in the  world of Industrial Testing & Laboratory equipment. We look forward to serving you and  being a part of your success.</p>
                </div>
            </div>

        </Layout>
    );
}

export default AboutUs;
