import { green, red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
// import CabinSemiBold from '../public/fonts/Cabin/Cabin-SemiBold.ttf';
// import CabinMedium from '../public/fonts/Cabin/Cabin-Medium.ttf';
// import CabinBold from '../public/fonts/Cabin/Cabin-Bold.ttf';
// import CabinRegular from '../public/fonts/Cabin/Cabin-Regular.ttf';

// const Cabin = {
// 	fontFamily: 'Cabin',
// 	fontStyle: 'normal',
// 	fontWeight: 'normal',
// 	fontStretch: 'normal',
// 	src: `url(${CabinRegular}) format('truetype')`,
// };
// const Cabin_Bold = {
// 	fontFamily: 'Cabin',
// 	fontStyle: 'normal',
// 	fontWeight: 'bold',
// 	fontStretch: 'normal',
// 	src: `url(${CabinBold}) format('truetype')`,
// };

const theme = createTheme({
	palette: {
		primary: {
			main: '#fb9d1f', //4f5dba
		},
		secondary: {
			main: '#fb9d1f', //'#808080',
		},
		default: {
			main: '#f4f6f8',
		},
		success: {
			main: green[600],
		},
		error: {
			main: red.A400,
		},
		danger: {
			main: '#fee6ea',
		},
		warning: {
			main: '#fef6e7',
		},
		info: {
			main: '#edf8f6',
		},
		drawer: {
			default: '#f4f6f8',
		},
		content: {
			main: '#f4f6f8',
		},
		header: {
			main: '#fcfcfe',
		},
	},
	typography: {
		fontFamily: ['Montserrat', 'sans-serif'],
		button: {
			fontFamily: ['Montserrat', 'sans-serif'],
			fontSize: 14,
			fontStyle: 'Regular',
			textTransform: 'capitalize',
			borderRadius: '15px',
			// backgroundColor:'red'
		},
	},
	// overrides: {
	// 	MuiCssBaseline: {
	// 		'@global': {
	// 			'@font-face': [Cabin, Cabin_Bold],
	// 		},
	// 	},
	// },
});

export default theme;
