import React from "react";
import Layout from "../layout/Layout";
import video from "../assets/videos/Welcome to the Xchange Marketplace (1).mp4";
import logo from "../assets/images/logo-full.png"


function ComingSoon() {
    return (
        <Layout pageName="ComingSoon">
            {/* <div className="container text-center my-2 mb-4">
                <h1>ComingSoon with Us</h1>
            </div> */}
            <div className="container text-center">
                <div className="container d-flex justify-content-center align-items-center full-screen-container flex-column">
                    <h1 style={{}}>
                        COMING SOON
                    </h1>
                    <img src={logo} className="center-image img-fluid" alt="..." />
                    <h2 style={{ marginTop: "10px" }} >Summer 2024</h2>
                </div>
            </div>

        </Layout>
    );
}

export default ComingSoon;
