import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { logout } from "../utils/utils";
import SellerSidebar from "./Sidebar/SellerSidebar";
import SellerTitle from "./PageTitle/SellerTitle";
import { Typography } from "@material-ui/core";
import image from "../assets/images/img-02.jpg";
import api from "../api";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";

import Footer from "../layout/Footer";

function Overview() {
  const [isOpen, setIsOpen] = useState(false);
  const { push } = useHistory();
  const [selectedOption, setSelectedOption] = useState(null);
  const [order, setOrder] = useState([]);
  const [product, setProduct] = useState([]);
  const [dashboard, setDashboard] = useState();
  const toggling = () => {
    setIsOpen(!isOpen);
    setIsOn(false);
  };

  const [isOn, setIsOn] = useState(false);
  const toggling1 = () => {
    setIsOn(!isOn);
    setIsOpen(false);
  };

  const onOptionClicked = (value) => () => {
    console.log(selectedOption);
  };

  const onOptionzClicked = (value) => () => {
    console.log(selectedOption);
  };
  const fetchOrders = async (e) => {
    try {
      const { data } = await api.get(`sellerprofile/getTopProducts`);
      setOrder(data);
    } catch (error) {
      // handleApiError(error, openToast)
      console.log(error);
      // openToast("error", error.response?.data?.message || "Invalid Employee");
    }
  };
  const fetchDashboard = async (e) => {
    try {
      const { data } = await api.get(`/sellerprofile/getSellerDashboard`);
      setDashboard(data);
    } catch (error) {
      // handleApiError(error, openToast)
      console.log(error);
      // openToast("error", error.response?.data?.message || "Invalid Employee");
    }
  };

  const [option, setOption] = useState({
    page: 1,
    size: 5,
    sortBy: "productname",
    sortOrder: "asc",
    status: "",
  });

  const [totalElem, setTotalElem] = useState();
  const fetchProducts = async (e) => {
    try {
      const { data } = await api.post(`/order/getSellerOrders`, option);
      setProduct(data.body.content);
    } catch (error) {
      // handleApiError(error, openToast)
      console.log(error);
      // openToast("error", error.response?.data?.message || "Invalid Employee");
    }
  };
  useEffect(() => {
    fetchOrders();
    fetchProducts();
    fetchDashboard();
  }, []);

  return (
    <div>
      <div>
        {/* Required meta tags */}
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {/* Bootstrap CSS */}
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
        <link rel="stylesheet" href="assets/css/brands.css" />
        <link rel="stylesheet" href="assets/css/solid.css" />
        <link rel="stylesheet" href="assets/css/style.css" />
        <title>The Xchange Marketplace</title>
        <div id="dashboard" className="col-2-layout">
          <div className="left-sidebar equalColumn">
            <div className="box-content">
              <SellerSidebar pageName="overview" />
            </div>
          </div>
          {/* end left-sidebar */}
          <div className="right-content equalColumn">
            <div className="box-content title-box">
              <SellerTitle />
            </div>
            {/* end box-content */}
            <div className="box-content minColheight">
              <div className="row" id="dashboard-tabs">
                <div className="col-md-12">
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <a
                        className="nav-item active"
                        id="nav-activeOrders-tab"
                        data-toggle="tab"
                        href="#nav-activeOrders"
                        role="tab"
                        aria-controls="nav-activeOrders"
                        aria-selected="true"
                      >
                        Overview
                      </a>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-activeOrders"
                      role="tabpanel"
                      aria-labelledby="nav-activeOrders-tab"
                    >
                      <div className="row" id="dashboard-tabs">
                        <div className="col-md-8 ">
                          <h5 className="pt-3 mb-4">Sales Overview</h5>

                          <div className="row" id="dashboard-tabs">
                            <div className="col-md-3">
                              <Typography
                                style={{
                                  fontSize: "24px",
                                  color: "#fb9d1f",
                                  fontWeight: "600",
                                }}
                              >
                                ${dashboard?.todaySale}
                              </Typography>

                              <Typography
                                style={{
                                  fontSize: "18px",
                                  // color: '#4ca26d',
                                  fontWeight: "500",
                                }}
                              >
                                Today's Sales
                              </Typography>
                            </div>
                            <div className="col-md-3">
                              <Typography
                                style={{
                                  fontSize: "24px",
                                  color: "#fb9d1f",
                                  fontWeight: "600",
                                }}
                              >
                                ${dashboard?.monthlySale}
                              </Typography>

                              <Typography
                                style={{
                                  fontSize: "18px",
                                  // color: '#4ca26d',
                                  fontWeight: "500",
                                }}
                              >
                                This Month Sales
                              </Typography>
                            </div>
                            <div className="col-md-3">
                              <Typography
                                style={{
                                  fontSize: "24px",
                                  color: "#fb9d1f",
                                  fontWeight: "600",
                                }}
                              >
                                ${dashboard?.lastMonthSale}
                              </Typography>

                              <Typography
                                style={{
                                  fontSize: "18px",
                                  // color: '#4ca26d',
                                  fontWeight: "500",
                                }}
                              >
                                Last Month Sales
                              </Typography>
                            </div>
                            <div className="col-md-3">
                              <Typography
                                style={{
                                  fontSize: "24px",
                                  color: "#fb9d1f",
                                  fontWeight: "600",
                                }}
                              >
                                ${dashboard?.totalSale}
                              </Typography>

                              <Typography
                                style={{
                                  fontSize: "18px",
                                  // color: '#4ca26d',
                                  fontWeight: "500",
                                }}
                              >
                                Total Sales
                              </Typography>
                            </div>
                            <div
                              className="col-md-11"
                              style={{
                                margin: "12px",
                                boxShadow: "0px 0px 6px #e2e2e2",
                                borderRadius: "6px",
                                padding: "12px 18px",
                              }}
                            >
                              {dashboard?.annualSale && (
                                <LineChart
                                  xAxis={[
                                    {
                                      data: [
                                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                                      ],
                                      label: "Months",
                                    },
                                  ]}
                                  series={[
                                    {
                                      data: dashboard?.annualSale?.map(
                                        (item) =>
                                          Object.entries(item).map(
                                            ([k, v]) => v
                                          )[0]
                                      ),
                                    },
                                  ]}
                                  width={600}
                                  height={300}
                                />
                              )}
                            </div>
                          </div>
                          <h5 className="pt-3 my-4">Order Overview</h5>

                          <div className="row" id="dashboard-tabs">
                            <div className="col-md-3 mb-3">
                              <Typography
                                style={{
                                  fontSize: "24px",
                                  color: "#fb9d1f",
                                  fontWeight: "600",
                                }}
                              >
                                {dashboard?.dailyOrders}
                              </Typography>

                              <Typography
                                style={{
                                  fontSize: "18px",
                                  // color: '#4ca26d',
                                  fontWeight: "500",
                                }}
                              >
                                Today's Orders
                              </Typography>
                            </div>
                            <div className="col-md-3">
                              <Typography
                                style={{
                                  fontSize: "24px",
                                  color: "#fb9d1f",
                                  fontWeight: "600",
                                }}
                              >
                                {dashboard?.monthlyOrders}
                              </Typography>

                              <Typography
                                style={{
                                  fontSize: "18px",
                                  // color: '#4ca26d',
                                  fontWeight: "500",
                                }}
                              >
                                This Month Orders
                              </Typography>
                            </div>
                            <div className="col-md-3">
                              <Typography
                                style={{
                                  fontSize: "24px",
                                  color: "#fb9d1f",
                                  fontWeight: "600",
                                }}
                              >
                                {dashboard?.lastMonthlyOrders}
                              </Typography>

                              <Typography
                                style={{
                                  fontSize: "18px",
                                  // color: '#4ca26d',
                                  fontWeight: "500",
                                }}
                              >
                                Last Month Orders
                              </Typography>
                            </div>
                            <div className="col-md-3">
                              <Typography
                                style={{
                                  fontSize: "24px",
                                  color: "#fb9d1f",
                                  fontWeight: "600",
                                }}
                              >
                                {dashboard?.totalOrders}
                              </Typography>

                              <Typography
                                style={{
                                  fontSize: "18px",
                                  // color: '#4ca26d',
                                  fontWeight: "500",
                                }}
                              >
                                Total Orders
                              </Typography>
                            </div>
                            <div
                              className="col-md-11"
                              style={{
                                margin: "12px",
                                boxShadow: "0px 0px 6px #e2e2e2",
                                borderRadius: "6px",
                                padding: "12px 18px",
                              }}
                            >
                              {dashboard?.annualOrders && (
                                <BarChart
                                  xAxis={[
                                    {
                                      id: "barCategories",
                                      data: [
                                        "Jan",
                                        "Feb",
                                        "Mar",
                                        "Apr",
                                        "May",
                                        "Jun",
                                        "Jul",
                                        "Aug",
                                        "Sept",
                                        "Oct",
                                        "Nov",
                                        "Dec",
                                      ],
                                      scaleType: "band",
                                    },
                                  ]}
                                  series={[
                                    {
                                      data: dashboard?.annualOrders?.map(
                                        (item) =>
                                          Object.entries(item).map(
                                            ([k, v]) => v
                                          )[0]
                                      ),
                                    },
                                  ]}
                                  width={600}
                                  height={300}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <h5 className="pt-3">Top 5 Sales</h5>
                          {order.slice(-5).map((item) => {
                            let picImage;
                            if (item.productPic) {
                              picImage = item.productPic.split(",")[0];
                            }
                            return (
                              <div
                                style={{
                                  margin: "12px",
                                  boxShadow: "0px 0px 6px #e2e2e2",
                                  borderRadius: "6px",
                                  padding: "6px",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "18px",
                                    color: "#4ca26d",
                                    fontWeight: "600",
                                  }}
                                >
                                  No. of product sold: {item.orderCount}
                                </Typography>
                                <div
                                  className="row"
                                  style={{
                                    // display: "flex",
                                    margin: "4px",
                                  }}
                                >
                                  <div className="col-md-3">
                                    <img
                                      src={picImage}
                                      style={{
                                        borderRadius: "16px",
                                        padding: "6px",
                                        height: "70px",
                                        width: "70px",
                                        marginRight: "4px",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </div>
                                  <div className="col-md-9">
                                    <Typography
                                      style={{
                                        alignSelf: "center",
                                        fontSize: "16px",
                                        // color: 'green',
                                        fontWeight: 500,
                                      }}
                                    >
                                      {item.productName}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end right -sidebar */}
        </div>

        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
        <Footer />
      </div>
    </div>
  );
}

export default Overview;
