import React, { useState, useEffect } from 'react'
import { Typography, Grid, Button } from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import CustomTextField from '../components/CustomTextField'
import CustomCheckbox from '../components/CustomCheckbox'
import CustomButton from '../components/CustomButton'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Cookies from 'universal-cookie'
import * as yup from 'yup'
import clsx from 'clsx'
import api from '../api'
import { useSnackbar } from '../lib/context'
import CustomLoader from '../components/CustomLoader'
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import { makeStyles } from '@material-ui/styles'

const useRadioStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: '50%',
    width: 18,
    height: 18,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundColor: '#48a068',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    // '&:before': {
    // 	display: 'block',
    // 	width: 16,
    // 	height: 16,
    // 	backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    // 	content: '""',
    // },
    'input:hover ~ &': {
      backgroundColor: '#f89e23'
    }
  }
})

function StyledRadio (props) {
  const classes = useRadioStyles()

  return (
    <Radio
      className={classes.root}
      disableRipple
      color='default'
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      size='small'
      icon={<span className={classes.icon} />}
      {...props}
    />
  )
}
export const loginSchema = yup.object().shape({
  username: yup
    .string()
    .email('Invalid Email.')
    .required('Email is required.'),
  password: yup.string().required('Password is required.')
})
function LoginForm ({ setShowForgotPassword }) {
  const cookies = new Cookies()
  const { push } = useHistory()
  const { openSnackbar } = useSnackbar()

  const initialValues = {
    username: '',
    password: '',
    isRemeberMe: false,
    type: 'admin'
  }
  const [rememberMe, setRememberMe] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const fetchData = async token => {
    try {
      const { data } = await api.post(
        `/auth/remember-me`,
        {},
        {
          headers: {
            Authorization: token
          }
        }
      )
      localStorage.setItem('data', JSON.stringify(data.data))
      localStorage.setItem('token', 'Bearer ' + data.authToken)

      window.location.pathname == '/loginpage/buyer'
        ? push('/myacc')
        : push('/overview')
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach(e => openSnackbar('error', e))
      } else {
        openSnackbar('error', error.message)
      }
    }
  }
  const readCookie = () => {
    try {
      const cookies = new Cookies()
      const token = cookies.get('authToken')
      if (token && token != null) {
        fetchData(token)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const saveCookie = token => {
    try {
      const cookies = new Cookies()
      cookies.set('authToken', 'Bearer ' + token, { path: '/' })
      cookies.set('rememberMe', true, { path: '/' })
    } catch (e) {
      console.log(e)
    }
  }

  // useEffect(() => {
  // 	if (localStorage.getItem('authToken')) {
  // 		localStorage.removeItem('authToken');
  // 	}
  // 	if (cookies.get('rememberMe')) readCookie();
  // }, []);

  const handleSubmit = async values => {
    try {
      const { data } = await api.post('auth/admin', values)
      localStorage.setItem('data', JSON.stringify(data.data))
      localStorage.setItem('token', 'Bearer ' + data.authToken)
      localStorage.setItem('type', 'admin')
      if (values.rememberMe) {
        saveCookie(data.token)
      }
      push('/admin-profile')
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach(e => openSnackbar('error', e))
      } else {
        openSnackbar('error', error.message)
      }
    }
  }

  return (
    <div style={{ maxWidth: '420px' }}>
      <Typography
        style={{
          fontSize: '28px',
          color: '#4aa56d',
          textTransform: 'uppercase',
          fontWeight: 600,
          marginBottom: '8px'
        }}
      >
        Admin Login
      </Typography>
      <Typography
        style={{
          fontSize: '14px',
          color: '#fb9d1f',
          fontWeight: 600
        }}
      >
        Welcome Back!
      </Typography>
      <Typography
        style={{
          fontSize: '14px',
          color: '#737979',
          marginBottom: '16px'
        }}
      >
        Enter your email and password to login
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={loginSchema}
      >
        {({ values, setFieldValue, ...rest }) => {
          return (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Field
                    as={CustomTextField}
                    name='username'
                    label='Email'
                    placeholder='Email'
                    setFieldValue={setFieldValue}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={CustomTextField}
                    name='password'
                    label='Password'
                    placeholder='Password'
                    type='password'
                    setFieldValue={setFieldValue}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Field
                    as={CustomCheckbox}
                    label='Remember Me'
                    name='rememberMe'
                    setFieldValue={setFieldValue}
                    className='container'
                    style={{
                      height: '1rem',
                      width: '1rem',
                      marginRight: '10px'
                    }}
                  />
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'end' }}>
                  <Typography
                    style={{
                      fontSize: '14px',
                      color: '#898f8f',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setShowForgotPassword(true)
                    }}
                  >
                    Forgot Password
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '12px' }}>
                  <CustomButton
                    type='submit'
                    variant='contained'
                    style={{ width: '100%' }}
                    color='primary'
                    disabled={rest.isSubmitting}
                  >
                    LOGIN
                  </CustomButton>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default LoginForm
