import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Hidden,
  Container
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useHistory } from 'react-router-dom'
import logo from '../assets/images/logo.png'
import { items } from '../lib/header-data'

function Footer(props) {
  const isMobileorTablet = useMediaQuery('(max-width:768px)')
  const isMobile = useMediaQuery('(max-width:425px)')
  const { push } = useHistory()
  const [isComingSoon, setIsComingSoon] = useState(false);

  useEffect(() => {
    setIsComingSoon(window.location.pathname.toLowerCase().includes('/coming-soon'));
  }, []);
  const [isDefaultScreen, setIsDefaultScreen] = useState(true);

  // You can use the setIsDefaultScreen function to update the state based on some condition
  // For example, you might have a function that handles screen changes

  const handleScreenChange = (isDefault) => {
    setIsDefaultScreen(isDefault);
  };

  const useStyles = makeStyles(theme => ({
    appBar: {
      backgroundColor: 'rgba(255, 255, 255, 0.78)',
      // theme.palette.header.main,
      zIndex: theme.zIndex.drawer + 1,
      height: 'fit-content',
      justifyContent: 'center',
      // padding: '1em 0',
      height: isMobile ? 61 : 122
    },
    listItem: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '18px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      margin: '0 0 0 2rem',
      '&:hover': {
        cursor: isComingSoon ? 'not-allowed' : 'pointer', // Set cursor style based on isComingSoon
      },
      pointerEvents: isComingSoon ? 'none' : 'auto', // Disable pointer events when isComingSoon is true
      color: isComingSoon ? theme.palette.text.disabled : 'inherit',
    },
    imageDiv: {
      margin: 'auto',
      padding: 0,
      align: 'center',
      display: 'inline-block',
      position: 'relative',
      // overflow: 'hidden',
      maxWidth: '240px'
    },
    image: {
      width: '100%',
      height: 'auto'
    }
  }))

  const classes = useStyles()
  const [openMenu, setOpenMenu] = useState(false)
  const renderLink = (href, label) => (
    <li>
      <a
        href={window.location.pathname === '/' ? href : href}
        className={`${classes.listItem} ${(window.location.pathname === '/') ? classes.disabledLink : ''}`}
        style={{ cursor: (window.location.pathname === '/') ? 'pointer' : 'pointer', textAlign: "start" }}  // Set the cursor style to default
      >
        {label}
      </a>
    </li>
  );
  const renderLink2 = (label) => {
    const type = localStorage.getItem('type');

    // Set the default href based on the type
    let href = (type === 'buyer') ? '/myacc' : '/overview';

    return (
      <li>
        <a
          href={window.location.pathname === '/' ? href : href}
          className={`${classes.listItem} ${(window.location.pathname === '/') ? classes.disabledLink : ''}`}
          style={{ cursor: (window.location.pathname === '/') ? 'pointer' : 'pointer', textAlign: "start" }}
        >
          {label}
        </a>
      </li>
    );
  };


  return (
    <div className='footer' style={{ minWidth: "100%" }}>
      {window.location.pathname !== '/loginpage' && (
        <div className='container'>
          <footer className='pt-4 my-md-5 pt-md-5'>
            <div className='row'>
              <div className='col-6 col-md-4'>
                <h5 style={{ fontSize: "12px" }}>THE XCHANGE MARKETPLACE COMPANY</h5>
                <ul className='list-unstyled text-small'>
                  {renderLink('/home', 'Home')}
                  {renderLink('https://thexchangemarketplace.com/about-us-discover-our-unique-story-at-the-xchange-marketplace', 'About Us')}
                  {renderLink('https://thexchangemarketplace.com/xchange-marketplace-team-meet-the-marketing-experts-global-content-the-xchange-marketplace', 'Team')}
                  {renderLink('https://thexchangemarketplace.com/career-opportunities-the-xchange-marketplace', 'Careers')}
                  {renderLink('https://thexchangemarketplace.com/advertise-with-us-xchange-marketplace', 'Advertise with Us')}
                </ul>
              </div>
              {/* <div className='col-6 col-md-4'> */}
              {/* <h5 style={{ fontSize: "12px" }}>SHOP ON the XCHANGE MARKETPLACE</h5> */}
              {/* <ul className='list-unstyled text-small'>
                  <li>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='#'>Shop by Category</a>
                  </li>
                  <li>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='#'>Shop by Brand</a>
                  </li>
                  <li>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='#'>Shop by Seller</a>
                  </li>
                  <li>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='#'>Shop by Price</a>
                  </li>
                  <li>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='#'>Shop by Location</a>
                  </li>
                </ul> */}
              {/* <ul className='list-unstyled text-small'>
                  {renderLink('/xchangeShop', 'Shop by Category')}
                  {renderLink('/xchangeShop', 'Shop by Brand')}
                  {renderLink('/xchangeShop', 'Shop by Seller')}
                  {renderLink('/xchangeShop', 'Shop by Price')}
                  {renderLink('/xchangeShop', 'Shop by Location')}
                </ul> */}
              {window.location.pathname !== '/' ? (
                <div className='col-6 col-md-4'>
                  <h5 style={{ fontSize: '12px' }}>SHOP ON the XCHANGE MARKETPLACE</h5>
                  <ul className='list-unstyled text-small'>
                    {renderLink('/xchangeShop', 'Shop by Category')}
                    {renderLink('/xchangeShop', 'Shop by Brand')}
                    {renderLink('/xchangeShop', 'Shop by Seller')}
                    {renderLink('/xchangeShop', 'Shop by Price')}
                    {renderLink('/xchangeShop', 'Shop by Location')}
                  </ul>
                </div>
              ) : <div className='col-6 col-md-4'>
                <h5 style={{ fontSize: '12px' }}>SHOP ON the XCHANGE MARKETPLACE</h5>
                <ul className='list-unstyled text-small'>
                  {renderLink('#', 'Shop by Category')}
                  {renderLink('#', 'Shop by Brand')}
                  {renderLink('#', 'Shop by Seller')}
                  {renderLink('#', 'Shop by Price')}
                  {renderLink('#', 'Shop by Location')}
                </ul>
              </div>}
              {/* </div> */}
              <div className='col-6 col-md-4'>
                <h5 style={{ fontSize: "12px" }}>BUY/SELL ON the XCHANGE MARKETPLACE</h5>
                {/* <ul className='list-unstyled text-small'>
                  <li>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='/how-to-sell'>How to Sell</a>
                  </li>
                  <li>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='#'>Sell Your Equipment</a>
                  </li>
                  <li>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='#'>My Account </a>
                  </li>
                  <li>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='#'>Shipping</a>
                  </li>
                </ul> */}
                <ul className='list-unstyled text-small'>
                  {renderLink('https://thexchangemarketplace.com/how-to-sell-ultimate-guide-for-successful-sales-brandname', 'How to Sell')}
                  {renderLink('https://thexchangemarketplace.com/how-to-buy-on-the-xchange-marketplace', 'How to Buy')}
                  {renderLink('https://thexchangemarketplace.com/sell-your-page-xchange-marketplace', 'Sell Your Equipment')}
                  {renderLink2('My Account')}
                  {renderLink('https://thexchangemarketplace.com/shipping-equipment-xchange-marketplace-enhance-your-shipping-experience-with-top-quality-gear', 'Shipping')}
                </ul>
              </div>
              <div className='col-6 col-md-4'>
                <h5 style={{ fontSize: "12px" }}>LEARN ABOUT the XCHANGE MARKETPLACE</h5>
                {/* <ul className='list-unstyled text-small'>
                  <li>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='#'>Service Connect</a>
                  </li>
                  <li>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='/rent'>Rent</a>
                  </li>
                  <li>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='/lease'>Lease</a>
                  </li>
                  <li>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='/terms-and-condition'>Terms and Conditions</a>
                  </li>
                  <li>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='/privacy'>Privacy Policy</a>
                  </li>
                </ul> */}
                <ul className='list-unstyled text-small'>
                  {renderLink('https://thexchangemarketplace.com/industrial-testing-equipment-service-providers-xrf-oes-analysis-the-xrf-company', 'Service Connect')}
                  {renderLink('https://thexchangemarketplace.com/rent-section-explore-schlitz-bicycle-rights-lo-fi-style-everyday-carry-essentials-by-schlitz', 'Rent')}
                  {renderLink('https://thexchangemarketplace.com/lease-on-the-xchange-marketplace', 'Lease')}
                  {renderLink('https://thexchangemarketplace.com/terms-conditions-the-xchange-marketplace', 'Terms and Conditions')}
                  {renderLink('https://thexchangemarketplace.com/privacy-policy-the-xchange-marketplace', 'Privacy Policy')}
                </ul>
              </div>
              <div className='col-6 col-md-4'>
                <h5 style={{ fontSize: "12px" }}>THE XCHANGE MARKETPLACE SUPPORT </h5>
                {/* <ul className='list-unstyled text-small'>
                  <li>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='/contactus'>Support / Help</a>
                  </li>
                  <li>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='/faq'>FAQs</a>
                  </li>
                  <li>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='/contactus'>Contact</a>
                  </li>
                </ul> */}
                <ul className='list-unstyled text-small'>
                  {renderLink('https://thexchangemarketplace.com/support/help-find-answers-to-your-questions-brandname', 'Support / Help')}
                  {renderLink('https://thexchangemarketplace.com/faqs-get-answers-to-your-burning-questions-schlitz-brand', 'FAQs')}
                  {renderLink('https://thexchangemarketplace.com/contact-us-get-in-touch-with-schlitz-for-all-your-questions', 'Contact')}
                </ul>
              </div>
              {/* <div className='col-12 col-md' id='footer-last-col'>
                <div className='row mt-4'>
                  <div className='col-6'>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }}
                      className='btn btn-primary btn-sm btn-style1'
                      href='#'
                      role='button'
                    >
                      Join Us
                    </a>
                  </div>
                  <div className='col-6'>
                    <h5>Follow us</h5>
                    <ul>
                      <li>
                        <a style={{
                          cursor: isComingSoon ? 'not-allowed' : 'pointer',
                          pointerEvents: isComingSoon ? 'none' : 'auto',
                          color: isComingSoon
                            ? classes.listItem.color
                            : 'inherit',
                        }} href="https://www.facebook.com/profile.php?id=61553628560491">
                          <img src='assets/images/icon-fb.png' alt='' />
                        </a>
                      </li>
                      <li>
                        <a style={{
                          cursor: isComingSoon ? 'not-allowed' : 'pointer',
                          pointerEvents: isComingSoon ? 'none' : 'auto',
                          color: isComingSoon
                            ? classes.listItem.color
                            : 'inherit',
                        }} href="https://www.instagram.com/xchangemarketplacellc/?fbclid=IwAR1U6awojUb7WfxFff6QWrTAtMZrhOz-Ju9ZfpsXKkDAzy0J6XAjpvfnYgU">

                          <img src='assets/images/icon-insta.png' alt='' />
                        </a>
                      </li>
                      <li>
                        <a style={{
                          cursor: isComingSoon ? 'not-allowed' : 'pointer',
                          pointerEvents: isComingSoon ? 'none' : 'auto',
                          color: isComingSoon
                            ? classes.listItem.color
                            : 'inherit',
                        }} href="https://www.linkedin.com/company/98994935/admin/feed/posts/">
                          <img src='assets/images/icon-twiter.png' alt='' />
                        </a>
                      </li>
                    </ul>
                    <h5>Get the app</h5>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='#'>
                      <img
                        alt=''
                        src='assets/images/app-store.png'
                        className='app-icon'
                      />
                    </a>
                  </div>
                  <div className='col-6'>
                    <a style={{
                      cursor: isComingSoon ? 'not-allowed' : 'pointer',
                      pointerEvents: isComingSoon ? 'none' : 'auto',
                      color: isComingSoon
                        ? classes.listItem.color
                        : 'inherit',
                    }} href='#'>
                      <img
                        alt=''
                        src='assets/images/app-store-google.png'
                        className='app-icon'
                      />
                    </a>
                  </div>
                </div>
              </div> */}
              <div className='col-12 col-md-4' id='footer-last-col'>
                <div className='row mt-4'>
                  <div className='col-6'>
                    <a
                      style={{
                        cursor: isComingSoon ? 'not-allowed' : 'pointer',
                        pointerEvents: isComingSoon ? 'none' : 'auto',
                        color: isComingSoon ? classes.listItem.color : 'inherit',
                      }}
                      className={`btn btn-primary btn-sm btn-style1 ${isComingSoon ? classes.disabledLink : ''}`}
                      href='https://thexchangemarketplace.com/join-us-xchange-marketplace'
                      role='button'
                    >
                      Join Us
                    </a>
                  </div>
                  <div className='col-6'>
                    <h5>Follow us</h5>
                    {/* <ul>
                      <li>
                        <a
                          style={{
                            cursor: isComingSoon ? 'not-allowed' : 'pointer',
                            pointerEvents: isComingSoon ? 'none' : 'auto',
                            color: isComingSoon ? classes.listItem.color : 'inherit',
                          }}
                          href="https://www.facebook.com/profile.php?id=61553628560491"
                        >
                          <img src='assets/images/icon-fb.png' alt='' />
                        </a>
                      </li>
                      <li>
                        <a
                          style={{
                            cursor: isComingSoon ? 'not-allowed' : 'pointer',
                            pointerEvents: isComingSoon ? 'none' : 'auto',
                            color: isComingSoon ? classes.listItem.color : 'inherit',
                          }}
                          href="https://www.instagram.com/xchangemarketplacellc/?fbclid=IwAR1U6awojUb7WfxFff6QWrTAtMZrhOz-Ju9ZfpsXKkDAzy0J6XAjpvfnYgU"
                        >
                          <img src='assets/images/icon-insta.png' alt='' />
                        </a>
                      </li>
                      <li>
                        <a
                          style={{
                            cursor: isComingSoon ? 'not-allowed' : 'pointer',
                            pointerEvents: isComingSoon ? 'none' : 'auto',
                            color: isComingSoon ? classes.listItem.color : 'inherit',
                          }}
                          href="https://www.linkedin.com/company/98994935/admin/feed/posts/"
                        >
                          <img src='assets/images/icon-twiter.png' alt='' />
                        </a>
                      </li>
                    </ul> */}
                    <ul>
                      <li>
                        <a
                          style={{
                            // cursor: isDefaultScreen ? 'not-allowed' : 'pointer',
                            // pointerEvents: isDefaultScreen ? 'none' : 'auto',
                            // color: isDefaultScreen ? classes.listItem.color : 'inherit',
                          }}
                          href="https://www.facebook.com/profile.php?id=61553628560491"
                        >
                          <img src='assets/images/icon-fb.png' alt='' />
                        </a>
                      </li>
                      <li>
                        <a
                          style={{
                            // cursor: isDefaultScreen ? 'not-allowed' : 'pointer',
                            // pointerEvents: isDefaultScreen ? 'none' : 'auto',
                            // color: isDefaultScreen ? classes.listItem.color : 'inherit',
                          }}
                          href="https://www.instagram.com/xchangemarketplacellc/?fbclid=IwAR1U6awojUb7WfxFff6QWrTAtMZrhOz-Ju9ZfpsXKkDAzy0J6XAjpvfnYgU"
                        >
                          <img src='assets/images/icon-insta.png' alt='' />
                        </a>
                      </li>
                      <li>
                        <a
                          style={{
                            // cursor: isDefaultScreen ? 'not-allowed' : 'pointer',
                            // pointerEvents: isDefaultScreen ? 'none' : 'auto',
                            // color: isDefaultScreen ? classes.listItem.color : 'inherit',
                          }}
                          href="https://www.linkedin.com/company/98994935/admin/feed/posts/"
                        >
                          <img src='assets/images/icon-twiter.png' alt='' />
                        </a>
                      </li>
                    </ul>
                    {/* <h5>Get the app</h5> */}
                  </div>
                </div>
                {/* <div className='row'>
                  <div className='col-6'>
                    <a
                      style={{
                        cursor: isComingSoon ? 'not-allowed' : 'pointer',
                        pointerEvents: isComingSoon ? 'none' : 'auto',
                        color: isComingSoon ? classes.listItem.color : 'inherit',
                      }}
                      href='#'
                    >
                      <img
                        alt=''
                        src='assets/images/app-store.png'
                        className='app-icon'
                      />
                    </a>
                  </div>
                  <div className='col-6'>
                    <a
                      style={{
                        cursor: isComingSoon ? 'not-allowed' : 'pointer',
                        pointerEvents: isComingSoon ? 'none' : 'auto',
                        color: isComingSoon ? classes.listItem.color : 'inherit',
                      }}
                      href='#'
                    >
                      <img
                        alt=''
                        src='assets/images/app-store-google.png'
                        className='app-icon'
                      />
                    </a>
                  </div>
                </div> */}
              </div>

            </div>
          </footer>
        </div>
      )}
      <div className='copyright'>
        <div className='container'>
          Copyright © 2021 THE XCHANGE MARKETPLACE.&nbsp;  All Rights Reserved.&nbsp;  Website
          &amp;  App Powered By K Business Solutions Inc.
        </div>
      </div>
    </div>
  )
}

export default Footer
