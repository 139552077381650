import React from "react";
import Layout from "../layout/Layout";
import video from "../assets/videos/Welcome to the Xchange Marketplace (1).mp4";
import { Box, Button, Grid, Typography } from "@mui/material";


function Rent() {
    return (
        <Layout pageName="Rent">
            {/* <div className="container text-center my-2 mb-4">
                <h1>Rent with Us</h1>
            </div> */}
            <div className="main">
                {/* <div className="jumbotron">
                    <div className="container">
                        <h2>Rent with THE XCHANGE COMPANY
                        </h2>
                        <div>
                            <a
                                className="btn btn-primary btn-md"
                                href="/loginpage/seller"
                                role="button"
                            >
                                Join Us
                            </a>
                        </div>
                    </div>
                </div> */}
                <Grid container sx={{ my: 5 }}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                background:
                                    "url(/static/media/banner-02.553b564a.png) no-repeat 0 0;",
                                backgroundSize: { xs: "100% 100%", md: "cover" },
                                minHeight: "50vh",
                                py: 7,
                                px: { xs: 2, md: 6 },

                            }}
                        >
                            <Typography
                                variant="h2"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: { xs: "1.2rem", md: "3rem" },
                                    mt: { xs: 10, md: 12 },
                                }}
                            >
                                Rent with THE XCHANGE COMPANY
                            </Typography>

                            <Box>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        backgroundColor: "rgb(248, 158, 35)",
                                        mt: 3,
                                        px: 3,
                                        borderRadius: "15px",
                                        fontSize: { xs: ".7rem", md: "1.3rem" },
                                    }}
                                >
                                    Join Us
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <div className="container my-3 mt-5">
                    <h2 className="text-center" style={{ fontSize: "24px", fontWeight: "600" }}>
                        Rent with THE XCHANGE COMPANY</h2>
                    <p style={{ fontSize: "17px", marginTop: "20px" }}>
                        Rental is an agreement where a payment is made for the temporary use of equipment by a buyer to a seller with the specific purpose of using the equipment for a determined period of time in exchange for payment.  With equipment Rental, you will have no ownership rights to the equipment.                                         </p>
                </div>

                <div className="container my-2">
                    <h3 style={{ fontSize: "20px", fontWeight: "600" }}>
                        HOW RENTING WORKS ON THE XCHANGE
                    </h3>
                    <h4 style={{ fontSize: "18px", fontWeight: "600" }}>
                        Reasons why people and companies rent instead of buy...
                    </h4>
                    <ul style={{ fontSize: '18px' }}>
                        <li>
                            Saves money in many cases
                        </li>
                        <li>Caters to short-term equipment need</li>
                        <li>Provides specialty performance</li>
                        <li>Satisfies temporary production increases Fills in when regular</li>
                        <li>machines need maintenance or fail Helps meet deadline crunches Expands</li>
                        <li> machine inventory Increases overall capability when and where needed</li>
                        <li>Eliminates responsibility of testing, maintenance, service Makes the
                        </li>
                        <li>project schedule easier to manage with on-demand resources.</li>

                    </ul>

                </div>
                <div className="container">
                    <h4 style={{ fontSize: "18px", fontWeight: "600" }}>Benefits of Renting:</h4>
                    <ul style={{ fontSize: "18px" }}>
                        <li> <strong>Avoid the Initial Purchase Cost:</strong> Purchasing brand new equipment can be expensive and can make a huge impact on your company’s budget. Moreover, purchasing equipment is a long term investment that ties you down to particular equipment items. Renting or leasing equipment avoids the upfront costs associated with purchasing, and allows businesses to better allocate their financial resources.</li>
                        <li>
                            <strong>Less Maintenance and Repair Costs :</strong>  When you own equipment, you also have to consider the cost of maintenance and repair. Although maintenance and repair is still important for rental equipment, costs will be significantly lowered compared to purchased equipment.
                        </li>
                        <li> <strong>No Depreciation Costs</strong>When you own machinery, you incur considerable depreciation costs. Reselling equipment, and maintaining it as long as possible, requires quite a large investment on top of your upfront purchase. As value continues to depreciate, it makes it more difficult to recover the cost of your initial investment.</li>
                        <li> <strong>Project-Specific Rentals</strong>Typically, companies are balancing a number of projects at once. Rather than incurring logistical costs of transferring equipment and sharing them among multiple jobs, consider renting specific pieces of equipment for particular projects. This eliminates any logistical delays that you may experience, and provides each job site with the appropriate resources to get the project finished in an efficient manner.</li>
                        <li> <strong>Gain Access to the Latest Technology</strong>When you rent equipment, you gain access to the newest equipment with the latest built-in technology. Technology has significantly advanced over the last decade, filling a vital need that contractors had to be able to manage their projects more efficiently and improve profitability. </li>
                    </ul>
                </div>

            </div>

        </Layout>
    );
}

export default Rent;
