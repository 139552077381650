import React from "react";
import Layout from "../layout/Layout";
import { Box, Button, Grid, Typography } from "@mui/material";


function Lease() {
    return (
        <Layout pageName="Lease">
            {/* <div className="container text-center my-2 mb-4">
                <h1>Lease with Us</h1>
            </div> */}
            <div className="main">
                {/* <div className="jumbotron">
                    <div className="container">
                        <h2>Lease to Own with THE XCHANGE
                        </h2>
                        <div>
                            <a
                                className="btn btn-primary btn-md"
                                href="/loginpage/seller"
                                role="button"
                            >
                                Join Us
                            </a>
                        </div>
                    </div>
                </div> */}
                <Grid container sx={{ my: 5 }}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                background:
                                    "url(/static/media/banner-02.553b564a.png) no-repeat 0 0;",
                                backgroundSize: { xs: "100% 100%", md: "cover" },
                                minHeight: "50vh",
                                py: 7,
                                px: { xs: 2, md: 6 },

                            }}
                        >
                            <Typography
                                variant="h2"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: { xs: "1.1rem", md: "3rem" },
                                    mt: { xs: 10, md: 12 },
                                }}
                            >
                                Lease to Own with THE XCHANGE Company
                            </Typography>

                            <Box>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        backgroundColor: "rgb(248, 158, 35)",
                                        mt: 3,
                                        px: 3,
                                        borderRadius: "15px",
                                        fontSize: { xs: ".7rem", md: "1.3rem" },
                                    }}
                                >
                                    Join Us
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <div className="container my-3 mt-5">
                    <h2 className="text-center" style={{ fontSize: "24px", fontWeight: "600" }}>
                        Lease to Own with THE XCHANGE</h2>
                    <p style={{ fontSize: "17px", marginTop: "20px" }}>
                        Lease to Own refers to borrowing money from a lender with the specific purpose of buying equipment. The financing firm can loan the entire cost of the equipment.
                    </p>
                    <p style={{ fontSize: "17px", marginTop: "20px" }}>
                        In exchange, you need to pay the loan along with regular interest payments, depending on the terms of the loan.
                    </p>
                    <p style={{ fontSize: "17px", marginTop: "20px" }}>
                        With equipment Lease to Own, you will FULLY OWN the equipment at the end of the Lease, when you pay the loan according to the agreed-upon terms.
                    </p>

                </div>
                <div className="container d-flex my-5">
                    <div>
                        <button className="btn btn-warning btn-sm mx-3"
                        >
                            CALCULATE YOUR MONTHLY PAYMENTS
                        </button>
                    </div>
                    <div>
                        <button className="btn btn-primary btn-sm">
                            GET APPROVED!
                        </button>
                    </div>
                </div>

                <div className="container my-2">
                    <h3 style={{ fontSize: "20px", fontWeight: "600" }}>
                        Benefits to Leasing with THE XCHANGE COMPANY                    </h3>

                    <ul style={{ fontSize: '18px' }}>
                        <li> <strong> Improve Cash Flow</strong>Your company only has so much cash-on-hand to spend on overhead, new opportunities, unexpected expenses and other costs. Equipment financing can help you keep as much cash in-house as possible. There is no point in dedicating a significant portion of your money to equipment upgrades or purchases when financing is available. Obtain equipment financing and you’ll enjoy the benefits of having accessible cash flow and increased clarity in budgeting.</li>
                        <li><strong>Preserve Other Lines of Credit</strong>Equipment financing won’t exhaust your bank lines of credit. This style of financing empowers you to conserve your working capital while maintaining positive relationships with a myriad of lending institutions.</li>
                        <li><strong>Hedge Against Inflation</strong>Tapping into equipment financing really does help your business hedge against inflation risk. Consider the ramifications of placing a large down payment on equipment or paying for the total cost all at once. It ties up your free cash and also makes little financial sense in terms of inflation. Equipment financing will allow you to make a series of payments over time and the lender will be the one who absorbs the devaluation of these payments over the course of the financing term due to the inevitable inflation.</li>
                        <li><strong>100 Percent Financing</strong>Full financing is available for those in search of funding for equipment. Financing can even be used for software and installation to boot. In some instances, no down payment will be required. This is a stark contrast to traditional lending institutions that almost always require some sort of initial down payment.</li>
                        <li><strong>No Hidden Charges</strong>Traditional loans are often rife with hidden charges that are detailed in the fine print of the contract. Equipment financing does not contain such hidden charges. This style of financing is completely transparent in all facets.</li>
                        <li><strong>Excellent Rates</strong>Take a look at the equipment financing interest rates and you will find that they are quite favorable compared to other types of financing.</li>
                        <li><strong>Save on Taxes
                        </strong>Equipment financing is commonly structured in a manner that reduces the borrower’s tax burden. Equipment leases that are “tax-oriented” actually include the value of tax benefits.</li>

                    </ul>

                </div>


            </div>

        </Layout>
    );
}

export default Lease;
