import * as actions from "../actions";
import { combineReducers } from "redux";

const initialState = [];

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    // case actions.ADD_TO_CART:
    //   if (state.includes(action.payload)) return state;
    //   const product = { ...action.payload, quantity: 1 };
    //   return [...state, product];
    case actions.ADD_TO_CART:
      const prod = state.find((p) => p.id === action.payload.id);
      if (prod) {
        const newProd = { ...prod, quantity: prod.quantity + 1 };
        state.splice(state.indexOf(prod), 1);

        return [newProd];
      } else {
        const newProd = { ...action.payload, quantity: 1 };
        return [newProd];
      }
    // state.splice(state.indexOf(prod), 1);

    case actions.CHANGE_QUANTITY:
      // if (state.includes(action.payload)) return state;
      const oldProd = state.find((p) => p.id === action.payload.productId);
      const newProd = { ...oldProd, quantity: action.payload.qty };
      state.splice(state.indexOf(oldProd), 1);
      // oldProd.qty = action.payload.qty;
      return [...state, newProd];

    case actions.REMOVE_FROM_CART:
      return state.filter((item) => item.id != action.payload);
    case actions.CLEAR_CART:
      state = initialState;
      return state;
    default:
      return state;
  }
};

const rootReducer = combineReducers({ cartReducer });
export default rootReducer;
