import React, { useState } from "react";
import { Link } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { logout } from "../../utils/utils";

function AdminSidebar(props) {
  const { pageName } = props;
  console.log("Current pageName:", pageName);

  const { push } = useHistory();
  return (
    <div>
      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      {/* Bootstrap CSS */}
      <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
      <link rel="stylesheet" href="assets/css/brands.css" />
      <link rel="stylesheet" href="assets/css/solid.css" />
      <link rel="stylesheet" href="assets/css/style.css" />

      <title>The Xchange Marketplace</title>

      <div className="logo" onClick={() => push("/home")}>
        <img src="assets/images/logo-full.png" alt="" className="img-fluid" />
      </div>
      {/* end logo */}
      <nav className="sidebar card" style={{ overflowY: "hidden" }}>
        <ul className="nav flex-column" id="nav_accordion">
          <li
            className={
              pageName == "admin-profile" ? `nav-item nav-active` : `nav-item`
            }
          >
            <a className="nav-link" href="/admin-profile">
              {" "}
              <Link to="/admin-profile">Admin Profile</Link>
            </a>
          </li>
          <li
            className={
              pageName == "admin-seller-promotion"
                ? `nav-item nav-active`
                : `nav-item`
            }
          >
            <a href="/admin-seller-promotion" className="nav-link">
              <Link to="/admin-seller-promotion">Seller Promotion</Link>{" "}
            </a>
          </li>
          <li
            className={
              pageName == "admin-advertisement"
                ? `nav-item nav-active`
                : `nav-item`
            }
          >
            <a href="/admin-advertisement" className="nav-link">
              <Link to="/admin-advertisement">Advertisement </Link>{" "}
            </a>
          </li>
          <li
            className={
              pageName == "admin-BuyerProfile"
                ? `nav-item nav-active`
                : `nav-item`
            }
          >
            <a href="/admin-buyer" className="nav-link">
              <Link to="/admin-buyer">Buyers Profile</Link>{" "}
            </a>
          </li>
          <li
            className={
              pageName == "admin-SellerProfile"
                ? `nav-item nav-active`
                : `nav-item`
            }
          >
            <a className="nav-link" href="/admin-seller">
              {" "}
              <Link to="/admin-seller">Sellers Profile</Link>
            </a>
          </li>
          <li
            className={
              pageName == "admin-brands" ? `nav-item nav-active` : `nav-item`
            }
          >
            <a className="nav-link" href="/admin-brands">
              {" "}
              <Link to="/admin-brands">Brands</Link>
            </a>
          </li>
          <li
            className={
              pageName == "admin-category" ? `nav-item nav-active` : `nav-item`
            }
          >
            <a className="nav-link" href="/admin-category">
              {" "}
              <Link to="/admin-category">Category</Link>
            </a>
          </li>
        </ul>
      </nav>
      <div className="logout-btn" onClick={logout}>
        <a href="#">
          <img src="assets/images/icon-logout.png" alt="" />
          Logout
        </a>
      </div>
    </div>
  );
}

export default AdminSidebar;
