import React from 'react';
import './form.scss';
import { useField } from 'formik';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	label: {
		color: '#898f8f',
		fontSize: '14px',
		fontWeight: 500,
		fontFamily: 'Montserrat, sans-serif',
	},
});

export const CheckboxWithOptions = ({
	field,
	label,
	setFieldValue,
	options,
	value,
	className,
	style,
	...props
}) => {
	const [f, meta] = useField(props);
	const classes = useStyles();
	const errorText = meta.error && meta.touched ? meta.error : '';
	return (
		<div className={'checkbox'.concat(` ${className}`)}>
			<label className={classes.label}>{label}</label>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
				}}
			>
				{options.map((option) => (
					<div style={{ marginRight: '1rem' }}>
						<input
							type="checkbox"
							// checked={field.value}
							onChange={() => {
								setFieldValue(field.name, [
									...value,
									option.value,
								]);
							}}
							style={{
								...style,
							}}
						/>
						<label
							className={classes.label}
							style={{ fontWeight: 'normal' }}
						>
							{option.text}
						</label>
					</div>
				))}
			</div>
		</div>
	);
};

const CustomCheckbox = ({
	label,
	renderActions,
	disabled,
	value,
	onChange,
	setFieldValue,
	className,
	style,
	...props
}) => {
	const [field, meta] = useField(props);
	const errorText = meta.error && meta.touched ? meta.error : '';
	const classes = useStyles();
	return (
		<div
			className={'checkbox'.concat(` ${className}`)}
			style={{ display: 'flex', alignItems: 'center' }}
		>
			<input
				{...field}
				{...props}
				type="checkbox"
				checked={field.value}
				disabled={disabled}
				onChange={
					onChange
						? onChange
						: setFieldValue(field.name, !field.value)
				}
				style={{
					...style,
				}}
			/>
			<label
				style={{
					color: meta.error && meta.touched ? '#fb4868' : '#898f8f',
					fontFamily: 'Cabin',
					fontSize: '14px',
					marginBottom: '0px',
				}}
			>
				{label}
			</label>
			<span className="errorText">{errorText}</span>
			{renderActions && renderActions()}
		</div>
	);
};

export default CustomCheckbox;
