import React from "react";
import { Link, BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import "./App.css";
// ... (other imports)

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@material-ui/styles";
import theme from "../src/lib/theme";
import SellerhubPage from "./Pages/SellerhubPage";
import BuyerhubPage from "./Pages/BuyerhubPage";
import Home from "./Pages/Home";
// ... (other imports)

// Assume you have a Redux store for authentication
import store from "../src/redux/store";
import { Provider, useSelector } from "react-redux";
import AboutUs from "./Pages/AboutUs";
import Contact from "./Pages/Contact";
import Advertise from "./Pages/Advertise";
import Rent from "./Pages/Rent";
import Lease from "./Pages/Lease";
import Faq from "./Pages/Faq";
import TermsAndCondition from "./Pages/Terms&Condition";
import Career from "./Pages/Career";
import ContactForm from "./Pages/ContactForm";
import PrivacyPolicy from "./Pages/Privacy&Policy";
import HowToSell from "./Pages/HowToSell";
import Verification from "./Pages/Verification";
import SellerDashboard from "./Pages/SellerDashboard/MyAccount";
import Profile from "./Pages/SellerDashboard/Profile";
import Payments from "./Pages/Payments";

import Overview from "./Pages/Overview";
import Messages from "./Pages/Messages";
import OrderSummary from "./Pages/BuyerDashboard/OrderSummary";
import Orders from "./Pages/Orders/InProgress";

import CompletedOrders from "./Pages/Orders/CompletedOrders";
import Shipped from "./Pages/Orders/Shipped";
import Canceled from "./Pages/Orders/Canceled";
import Products from "./Pages/Products/Products";
import AllProducts from "./Pages/Products/AllProducts";
import AddProduct from "./Pages/Products/AddProduct";
import EditProduct from "./Pages/Products/EditProduct";
import MyAcc from "./Pages/BuyerDashboard/MyAcc";
import Cart from "./Pages/Cart";
import Watchlist from "./Pages/BuyerDashboard/Watchlist";
import BuyerOrders from "./Pages/BuyerDashboard/BuyerOrders";
import BuyerPayments from "./Pages/BuyerDashboard/BuyerPayments";
import Msg from "./Pages/BuyerDashboard/Msg";
import BuyerOrderHistory from "./Pages/BuyerDashboard/BuyerOrderHistory";
import ComingSoon from "./Pages/ComingSoon";
import AdminLogin from "./Pages/Admin/LoginPage";
import AdminProfile from "./Pages/Admin/Profile";
import AdminSellerPromotion from "./Pages/Admin/SellerPromotion";
import AdminUpcomingPromotion from "./Pages/Admin/UpcomingPromotion";
import AdminAdvertisement from "./Pages/Admin/Advertisement";
import Footer from "./layout/Footer";
import Loginpage from "./Pages/LoginPage";
import Shop from "./Pages/Shop";
import Order from "./Pages/Orders/InProgress";
import Brands from "./Pages/Admin/Brands";
import BuyerProfile from "./Pages/Admin/BuyerProfile";
import SellerProfile from "./Pages/Admin/SellerProfile";
import Category from "./Pages/Admin/Category";
import StripePage, { Stripes } from "./Pages/Stripe";

// store.subscribe(() => console.log("store111", store.getState()));

const PrivateRoute = ({ component: Component, userType, ...rest }) => {
  // Check if the user is authenticated
  const storedUserType = localStorage.getItem("type");
  const isAuthenticated = storedUserType === userType;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/loginpage" />
        )
      }
    />
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter basename="/">
          <Switch>
            {/* Public Routes */}
            {/* <Route path="/" exact component={Home} /> */}
            <Route path="/" exact render={()=> <Redirect to="/home" />} />
            <Route path="/home" component={Home} />
            <Route path="/aboutus" component={AboutUs} />
            <Route path="/support" component={Contact} />
            <Route path="/advertise" component={Advertise} />
            <Route path="/rent" component={Rent} />
            <Route path="/lease" component={Lease} />
            <Route path="/faq" component={Faq} />
            <Route path="/terms-and-condition" component={TermsAndCondition} />
            <Route path="/career" component={Career} />
            <Route path="/contact" component={ContactForm} />
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/sell" component={HowToSell} />
            <Route path="/verification" component={Verification} />
            <Route path="/loginpage" component={Loginpage} />
            <Route path="/stripe" component={StripePage} />

            <Route path="/xchangeShop" component={Shop} />
            <Route path="/admin-login" component={AdminLogin} exact />
            <Route path="/sellerhubpage" component={SellerhubPage} />
            <Route path="/sellerhub" component={SellerhubPage} />
            <Route path="/buyerhubpage" component={BuyerhubPage} />

            {/* ... (other public routes) */}

            {/* Private Routes */}

            <PrivateRoute
              path="/admin-buyer"
              component={BuyerProfile}
              exact
              userType="admin"
            />
            <PrivateRoute
              path="/admin-seller"
              component={SellerProfile}
              exact
              userType="admin"
            />
            <PrivateRoute
              path="/admin-profile"
              component={AdminProfile}
              exact
              userType="admin"
            />
            <PrivateRoute
              path="/admin-seller-promotion"
              component={AdminSellerPromotion}
              exact
              userType="admin"
            />
            <PrivateRoute
              path="/admin-upcoming-seller-promotion"
              component={AdminUpcomingPromotion}
              exact
              userType="admin"
            />
            <PrivateRoute
              path="/admin-advertisement"
              component={AdminAdvertisement}
              exact
              userType="admin"
            />
            <PrivateRoute
              path="/admin-brands"
              component={Brands}
              exact
              userType="admin"
            />
            <PrivateRoute
              path="/admin-category"
              component={Category}
              exact
              userType="admin"
            />

            <PrivateRoute path="/sellerhub" component={SellerhubPage} />

            <PrivateRoute
              path="/myaccount"
              component={SellerDashboard}
              userType="seller"
            />
            <PrivateRoute
              path="/profile"
              component={Profile}
              userType="seller"
            />
            <PrivateRoute
              path="/payments"
              component={Payments}
              userType="seller"
            />
            <PrivateRoute
              path="/overview"
              component={Overview}
              userType="seller"
            />
            <PrivateRoute
              path="/messages"
              component={Messages}
              userType="seller"
            />
            <PrivateRoute path="/order" component={Order} userType="seller" />
            <PrivateRoute
              path="/completedorder"
              component={CompletedOrders}
              userType="seller"
            />
            <PrivateRoute
              path="/shipped"
              component={Shipped}
              userType="seller"
            />
            <PrivateRoute
              path="/canceled"
              component={Canceled}
              userType="seller"
            />
            <PrivateRoute
              path="/products"
              component={Products}
              userType="seller"
            />
            <PrivateRoute
              path="/allproducts"
              component={AllProducts}
              userType="seller"
            />
            <PrivateRoute
              path="/addproduct"
              component={AddProduct}
              userType="seller"
            />
            <PrivateRoute
              path="/editproduct/:id"
              component={EditProduct}
              userType="seller"
            />
            <PrivateRoute path="/myacc" component={MyAcc} userType="buyer" />
            <PrivateRoute path="/msg" exact component={Msg} userType="buyer" />
            <PrivateRoute
              path="/buyerpayment"
              component={BuyerPayments}
              userType="buyer"
            />
            <PrivateRoute
              path="/buyerorder"
              component={BuyerOrders}
              userType="buyer"
            />
            <PrivateRoute
              path="/watchlist"
              component={Watchlist}
              userType="buyer"
            />
            <PrivateRoute path="/cart" component={Cart} userType="buyer" />
            <PrivateRoute
              path="/ordersummary"
              component={OrderSummary}
              userType="buyer"
            />
            <PrivateRoute
              path="/buyerorderhistory"
              component={BuyerOrderHistory}
              userType="buyer"
            />
            <PrivateRoute
              path="/admin-profile"
              component={AdminProfile}
              exact
              userType="admin"
            />
            <PrivateRoute
              path="/admin-seller-promotion"
              component={AdminSellerPromotion}
              exact
              userType="admin"
            />
            <PrivateRoute
              path="/admin-upcoming-seller-promotion"
              component={AdminUpcomingPromotion}
              exact
              userType="admin"
            />
            <PrivateRoute
              path="/admin-advertisement"
              component={AdminAdvertisement}
              exact
              userType="admin"
            />
            {/* ... (other private routes) */}
          </Switch>
          {/* <Footer /> */}
        </BrowserRouter>
      </Provider>
      <ToastContainer position="bottom-right" hideProgressBar icon={false} />
    </ThemeProvider>
  );
}

export default App;
