import React from "react";
import Layout from "../layout/Layout";
import video from "../assets/videos/Welcome to the Xchange Marketplace (1).mp4";
import { Box, Button, Grid, Typography } from "@mui/material";


function Faq() {
    return (
        <Layout pageName="Faq">
            {/* <div className="container text-center my-2 mb-4">
                <h1>Faq with Us</h1>
            </div> */}
            <div className="main">
                {/* <div className="jumbotron">
                    <div className="container">
                        <h2>Frequently Asked Questions (FAQs)
                        </h2>
                        <div>
                            <a
                                className="btn btn-primary btn-md"
                                href="/loginpage/seller"
                                role="button"
                            >
                                Join Us
                            </a>
                        </div>
                    </div>
                </div> */}
                <Grid container sx={{ my: 5 }}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                background:
                                    "url(/static/media/banner-02.553b564a.png) no-repeat 0 0;",
                                backgroundSize: { xs: "100% 100%", md: "cover" },
                                minHeight: "50vh",
                                py: 7,
                                px: { xs: 2, md: 6 },

                            }}
                        >
                            <Typography
                                variant="h2"
                                sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: { xs: "1.5rem", md: "3rem" },
                                    mt: { xs: 10, md: 12 },
                                }}
                            >
                                Frequently Asked Questions (FAQs)
                            </Typography>

                            <Box>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        backgroundColor: "rgb(248, 158, 35)",
                                        mt: 3,
                                        px: 3,
                                        borderRadius: "15px",
                                        fontSize: { xs: ".7rem", md: "1.3rem" },
                                    }}
                                >
                                    Join Us
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <div className="container my-3 mt-5">
                    <h2 className="text-center" style={{ fontSize: "24px", fontWeight: "600" }}>
                        Frequently Asked Questions (FAQs)                         </h2>

                </div>

                <div className="container my-2">
                    <h3 style={{ fontSize: "20px", fontWeight: "600" }}>
                        Account and Registration                     </h3>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        How do I create an account?
                    </h4>
                    <p style={{ fontSize: "17px" }}>To create an account, click on the "Sign Up" on the Homepage to create an  account.</p>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        I forgot my password. How can I reset it?
                    </h4>
                    <p style={{ fontSize: "17px" }}>
                        You can reset your password by clicking on the "Forgot Password" link on  the login page and following the instructions sent to your registered email.
                    </p>

                </div>
                <div className="container my-2">
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Buying
                    </h4>
                    <h5 style={{ fontSize: "17px", fontWeight: "600" }}>
                        To BUY items on THE XCHANGE MARKETPLACE
                    </h5>
                    <ul style={{ fontSize: "17px" }}>
                        <li>Step 1: Sign up for an Account </li>
                        <li>Step 2: Browse</li>
                        <li>Step 3: Select products and connect with Sellers</li>
                        <li>Step 4: Arrange Delivery </li>
                        <li>Step 5: Start Testing!</li>
                    </ul>
                </div>
                <div className="container my-2">
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Selling
                    </h4>
                    <h5 style={{ fontSize: "17px", fontWeight: "600" }}>
                        To SELL items on THE XCHANGE MARKETPLACE                    </h5>
                    <ul style={{ fontSize: "17px" }}>
                        <li>Step 1: Sign up for an Account </li>
                        <li>Step 2: List your Product</li>
                        <li>Step 3: Connect to Buyers</li>
                        <li>Step 4: Sell Your Products </li>
                        <li>Step 5: Arrange Shipping</li>
                        <li>Step 6: Get Paid!</li>
                    </ul>
                </div>

                <div className="container my-2">
                    <h3 style={{ fontSize: "20px", fontWeight: "600" }}>
                        Payments and Transactions
                    </h3>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Initial Contact and Purchase:                     </h4>
                    <p style={{ fontSize: "17px" }}>
                        "You can browse, negotiate, communicate, and initiate purchases on THE XCHANGE  MARKETPLACE; however, please be aware that the final transaction, including payment  and delivery arrangements, will take place outside of the Platform
                    </p>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Buyer-Seller Communication:                     </h4>
                    <p style={{ fontSize: "17px" }}>
                        "Buyers and sellers are encouraged to communicate through our messaging system for  convenience and record-keeping. However, any agreements made, such as payment  methods or delivery details, that take place outside the platform are solely the  responsibility of the parties involved."
                    </p>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Safety and Trust:                                    </h4>
                    <p style={{ fontSize: "17px" }}>
                        "While we strive to provide a safe and secure environment, we advise users to exercise  caution and use their judgment when conducting transactions outside of The XCHANGE  MARKETPLACE.
                    </p>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Dispute Resolution:                                    </h4>
                    <p style={{ fontSize: "17px" }}>
                        For transactions that occur within THE XCHANGE MARKETPLACE, our dispute  resolution services are available to help resolve any issues that may arise.
                    </p>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Reporting Concerns:                                              </h4>
                    <p style={{ fontSize: "17px" }}>
                        If you encounter any suspicious or fraudulent activity on our platform or believe that a  transaction is not following our guidelines, please report it to us immediately so that we  can investigate and take appropriate action.
                    </p>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Marketplace Fees:                                                      </h4>
                    <p style={{ fontSize: "17px" }}>
                        Our platform charges fees to support the services we provide, including dispute  resolution, customer support, and maintaining a safe environment. Engaging in  transactions outside the platform may not exempt users from these fees.
                    </p>

                </div>
                <div className="container my-2">
                    <h3 style={{ fontSize: "20px", fontWeight: "600" }}>
                        Security and Privacy
                    </h3>
                    <h4 style={{ fontSize: "19px", fontWeight: "600" }}>
                        Is my personal information secure?                     </h4>
                    <p style={{ fontSize: "17px" }}>
                        Yes, we take your privacy seriously. We use industry-standard security measures to  protect your data. For more details, review our Private Policy.
                    </p>
                </div>
            </div>

        </Layout>
    );
}

export default Faq;
