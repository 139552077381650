import React, { useState, useEffect } from "react";
import "font-awesome/css/font-awesome.min.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IconButton, Badge } from "@material-ui/core";

function BuyerTitle() {
  const [username, setUsername] = useState("");
  const { push } = useHistory();
  const myState = useSelector((state) => state.cartReducer);
  useEffect(() => {
    if (localStorage.getItem("data") && localStorage.getItem("data") != null) {
      var firstname = JSON.parse(localStorage.getItem("data")).firstname;
      var lastname = JSON.parse(localStorage.getItem("data")).lastname;
      setUsername(firstname + " " + lastname);
    }
  }, []);
  return (
    <div>
      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      {/* Bootstrap CSS */}
      <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
      <link rel="stylesheet" href="assets/css/brands.css" />
      <link rel="stylesheet" href="assets/css/solid.css" />
      <link rel="stylesheet" href="assets/css/style.css" />

      <title>The Xchange Marketplace</title>

      {/* end left-sidebar */}
      <div className="page-title">Buyer</div>
      <div className="right-top-link">
        <a className="link-alert">
          <IconButton
            aria-label="show new notifications"
            color="inherit"
            onClick={() => {
              // window.location.pathname = '/cart';
              push("/ordersummary");
            }}
          >
            <Badge
              badgeContent={myState ? myState.length : 0}
              color="secondary"
              // classes={{
              // 	badge: classes.customBadge,
              // }}
              style={{ position: "absolute", top: 0 }}
            >
              <i
                className="fas fa-shopping-cart"
                style={{ fontSize: "0.8em" }}
              />
            </Badge>
          </IconButton>
        </a>
        {/* <a href="#" className="link-alert">
          <i className="fas fa-bell" />
        </a> */}
        <h3>
          <a href="/myacc" style={{ cursor: "pointer" }}>
            {/* <i className="fas fa-chevron-down" /> */}
            Hello, {username ?? ""}
            &nbsp;
          </a>
        </h3>
      </div>
    </div>
  );
}

export default BuyerTitle;
