import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import 'font-awesome/css/font-awesome.min.css'
import styled from 'styled-components'
import { Bar, Doughnut } from 'react-chartjs-2'
import { Typography, Divider, Grid, Button } from '@material-ui/core'
import DonutChart from 'react-donut-chart'
import { PieChart } from 'react-minimal-pie-chart'
import MasterCardImage from '../../assets/images2/mastercard.png'
import { useHistory } from 'react-router-dom'
import SellerSidebar from '../Sidebar/SellerSidebar'
import { logout } from '../../utils/utils'
import SellerTitle from '../PageTitle/SellerTitle'
import api from '../../api'
import { loadStripe } from '@stripe/stripe-js'

import { useSnackbar } from '../../lib/context'
import { toast } from 'react-toastify'
import Footer from '../../layout/Footer'

function Profile () {
  const { push } = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [profile, setProfile] = useState([
    {
      tid: '#123',
      date: '02-14-2022',
      description: 'Fund Added',
      status: 'Payment Successful'
    }
  ])
  const [sessionId, setSessionId] = useState('')
  const toggling = () => {
    setIsOpen(!isOpen)
    setIsOn(false)
  }

  const arbitraryStackKey = 'stack1'
  const [isOn, setIsOn] = useState(false)
  const toggling1 = () => {
    setIsOn(!isOn)
    setIsOpen(false)
  }

  const onOptionClicked = value => () => {
    console.log(selectedOption)
  }

  const onOptionzClicked = value => () => {
    console.log(selectedOption)
  }
  const getBarData = () => {
    return {
      labels: ['Funds/Deals'],
      datasets: [
        // These two will be in the same stack.
        {
          stack: arbitraryStackKey,
          label: 'Matched',
          // display:false,
          data: [30],
          backgroundColor: '#07BEF7'
        },
        {
          stack: arbitraryStackKey,
          label: 'Unmatched',
          data: [100 - 30],
          backgroundColor: '#c1c1c1'
        }
      ]
    }
  }
  const redirectToStripe = async () => {
    // const { data } = await api.get(`subscriptions/stripe-public-key`)
    // 'pk_test_TCjofXqhpGiaDzNLItyiccx000ougmhFnz';
    // const stripePromise = loadStripe(data.value)
    const stripePromise = loadStripe(
      'pk_test_51K6IJQB5OnvlhtIimji1uXUxV8S6AHtaxogATTN4oXsV6yEPToxCmxfq25eP28CUNIKw1FhqVwFAD0H78Ajcb3Fj00gYZf5paT'
    )
    const stripe = await stripePromise
    const result = await stripe.redirectToCheckout({
      sessionId: sessionId
    })
  }

  useEffect(() => {
    if (sessionId) {
      redirectToStripe()
    }
  }, [sessionId])
  const handleStripeSubscription = async investorId => {
    try {
      // setDisableStripe(true)
      // const { data } = await api.post(
      // 	`subscriptions/stripe?investorId=${investorId}`
      // );
      const { data } = await api.post(
        `signup/stripe?reference=${JSON.parse(localStorage.getItem('data')).id}`
      )
      setSessionId(data)
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        // setDisableStripe(false)
        error.response.data.errors.forEach(e => toast.error(e))
      } else {
        // setDisableStripe(false)
        toast.error(error.message)
      }
    }
  }

  return (
    <div>
      {/* Required meta tags */}
      <meta charSet='utf-8' />
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1, shrink-to-fit=no'
      />
      {/* Bootstrap CSS */}
      <link rel='stylesheet' href='assets/css/bootstrap.min.css' />
      <link rel='stylesheet' href='assets/css/fontawesome.min.css' />
      <link rel='stylesheet' href='assets/css/brands.css' />
      <link rel='stylesheet' href='assets/css/solid.css' />
      <link rel='stylesheet' href='assets/css/style.css' />
      <title>The Xchange Marketplace</title>
      <div id='dashboard' className='col-2-layout'>
        <div className='left-sidebar equalColumn'>
          <div className='box-content'>
            <SellerSidebar />
          </div>
        </div>
        {/* end left-sidebar */}
        <div className='right-content equalColumn'>
          <div className='box-content title-box'>
            <SellerTitle />
          </div>
          {/* end box-content */}

          <div className='box-content minColheight'>
            <div className='row' id='dashboard-tabs'>
              <div className='col-md-12'>
                <nav>
                  <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                    <a
                      className='nav-item '
                      id='nav-account-tab'
                      data-toggle='tab'
                      href='#nav-account'
                      role='tab'
                      aria-controls='nav-account'
                      aria-selected='false'
                    >
                      <Link to='/sellerhub'>MY ACCOUNT</Link>
                    </a>
                    <a
                      className='nav-item active '
                      id='nav-profile-tab'
                      data-toggle='tab'
                      href='#nav-profile'
                      role='tab'
                      aria-controls='nav-profile'
                      aria-selected='true'
                    >
                      <Link to='/profile'>SUBSCRIPTION</Link>
                    </a>
                  </div>
                </nav>
                <div className='tab-content' id='nav-tabContent'>
                  <div
                    className='tab-pane fade  show active'
                    id='nav-account'
                    role='tabpanel'
                    aria-labelledby='nav-account-tab'
                  >
                    <div className='form-sec full-block mb-4'>
                      <h3>Member Since January 15, 2021</h3>
                    </div>

                    {/* end full-block */}
                    <div className='form-sec full-block'>
                      {/* <h3>Contact info</h3> */}
                      <div className='row'>
                        <Grid container className='mx-4' spacing={5}>
                          <Grid item sm={12} md={6}>
                            {[
                              {
                                label: ' Type of Subscription',
                                value: 'Annual'
                              },
                              { label: 'Payment Renewal', value: 'Recurring' },
                              {
                                label: 'Next Payment',
                                value: 'February 16,2022'
                              }
                            ].map(item => (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontWeight: 600,
                                      color: '#5c5c5c'
                                    }}
                                  >
                                    {item.label}
                                  </Typography>
                                  <Typography
                                    style={{
                                      color: '#c2c0c0',
                                      fontWeight: 600
                                    }}
                                  >
                                    {item.value}
                                  </Typography>
                                </div>
                                <Divider
                                  style={{
                                    margin: '8px'
                                  }}
                                />
                              </>
                            ))}
                          </Grid>

                          <Grid item sm={12} md={6}>
                            {' '}
                            {[
                              {
                                label: 'Total Product Upload Credit',
                                value: '5'
                              },
                              {
                                label: 'Used Product Upload Credit',
                                value: '2'
                              },
                              {
                                label: 'Available Product Upload Credit',
                                value: '3'
                              }
                            ].map(item => (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontWeight: 600,
                                      color: '#5c5c5c'
                                    }}
                                  >
                                    {item.label}
                                  </Typography>
                                  <Typography
                                    style={{
                                      color: '#c2c0c0',
                                      fontWeight: 600
                                    }}
                                    className='mr-3'
                                  >
                                    {item.value}
                                  </Typography>
                                </div>
                                <Divider
                                  style={{
                                    margin: '8px'
                                  }}
                                />
                              </>
                            ))}
                          </Grid>
                        </Grid>
                        {/* <div
													className="col-md-7"
													style={{
														maxHeight: '200px',
														width: 'auto',
													}}
												> */}
                        {/* <Doughnut
													data={getBarData()}
													// options={options2}
													width={100}
													height={200}
													padding={5}
													// type="pie"
												/> */}
                        {/* <DonutChart
													data={[
														{
															label: 'Give you up',
															value: 25,
														},
														{
															label: '',
															value: 75,
															isEmpty: true,
														},
													]}
												/> */}
                        {/* <div
                          className="col-md-5"
                          style={{
                            marginBottom: "40px",
                          }}
                        >
                          <PieChart
                            lineWidth={15}
                            radius={PieChart.defaultProps.radius - 2}
                            // viewBoxSize={[100,100]}
                            style={{
                              height: "150px",
                              padding: "0px",
                              margin: 0,
                            }}
                            label={({ dataEntry }) =>
                              dataEntry.value + " days remaining"
                            }
                            labelStyle={{
                              fontSize: "7px",
                              fontFamily: "sans-serif",
                              fill: "#E38627",
                            }}
                            labelPosition={0}
                            totalValue={365}
                            startAngle={270}
                            background="#dadada"
                            data={[
                              // {
                              // 	title: 'One',
                              // 	value: 15,
                              // 	color: '#dadada',
                              // },
                              {
                                title: "Two",
                                value: 150,
                                color: "#4e9f66",
                              },
                            ]}
                          />
                        </div> */}
                        {/* <div className="col-md-7"></div> */}
                        {/* </div> */}
                        {/* <div className="col-md-5">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: 600,
                                color: "#5c5c5c",
                              }}
                            >
                              Type of Subscription
                            </Typography>
                            <Typography
                              style={{
                                color: "#c2c0c0",
                                fontWeight: 600,
                              }}
                            >
                              Annual
                            </Typography>
                          </div>
                          <Divider
                            style={{
                              margin: "8px",
                            }}
                          />
                        </div>
                        <div className="col-md-7"></div>
                        <div className="col-md-5">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: 600,
                                color: "#5c5c5c",
                              }}
                            >
                              Amount
                            </Typography>
                            <Typography
                              style={{
                                color: "#c2c0c0",
                                fontWeight: 600,
                              }}
                            >
                              $995
                            </Typography>
                          </div>
                          <Divider
                            style={{
                              margin: "8px",
                            }}
                          />
                        </div>
                        <div className="col-md-7"></div>
                        <div className="col-md-5">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: 600,
                                color: "#5c5c5c",
                              }}
                            >
                              Payment Reniewal
                            </Typography>
                            <Typography
                              style={{
                                color: "#c2c0c0",
                                fontWeight: 600,
                              }}
                            >
                              Recuring
                            </Typography>
                          </div>
                          <Divider
                            style={{
                              margin: "8px",
                            }}
                          />
                        </div>
                        <div className="col-md-7"></div>
                        <div className="col-md-5">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: 600,
                                color: "#5c5c5c",
                              }}
                            >
                              Next Payment
                            </Typography>
                            <Typography
                              style={{
                                color: "#c2c0c0",
                                fontWeight: 600,
                              }}
                            >
                              Jaunary 22, 2021
                            </Typography>
                          </div>
                          <Divider
                            style={{
                              margin: "8px",
                            }}
                          />
                        </div>
                        <div className="col-md-7"></div>
                        <div className="col-md-5">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: 600,
                                color: "#5c5c5c",
                              }}
                            >
                              Payment Details{" "}
                            </Typography>

                            <div
                              style={{
                                display: "inline-flex",
                              }}
                            >
                              <img
                                src={MasterCardImage}
                                style={{
                                  height: "24px",
                                  marginRight: "6px",
                                }}
                              />
                              <Typography
                                style={{
                                  color: "#c2c0c0",
                                  fontWeight: 600,
                                }}
                              >
                                Mastercard ending in 1234
                              </Typography>
                            </div>
                          </div>
                          <Divider
                            style={{
                              margin: "8px",
                            }}
                          />
                        </div>
                        <div className="col-md-7">
                          <Typography
                            style={{
                              textDecoration: "underline",
                              fontWeight: 600,
                            }}
                          >
                            Edit Payment
                          </Typography>
                        </div>*/}
                      </div>
                      {/* end row */}
                    </div>
                    {/* end full-block */}
                    <div
                      className='tab-content'
                      id='nav-tabContent'
                      style={{
                        width: '100%',
                        padding: '2em 2em 1em 2em '
                      }}
                    >
                      <div
                        className='tab-pane fade show active'
                        id='nav-activeOrders'
                        role='tabpanel'
                        aria-labelledby='nav-activeOrders-tab'
                      >
                        <div className='payments-content '>
                          {profile.length == 0 ? (
                            <label>No Record Found</label>
                          ) : (
                            <div className='paymentsCon-thead mt-5'>
                              {/* <div id="prod" className="th-col tg-pro">
                          Product
                        </div> */}
                              <div className='th-col tg-email'>
                                Transaction#
                              </div>{' '}
                              <div className='th-col tg-email ml-5'>Date#</div>
                              <div className='th-col tg-email ml-5'>
                                Description
                              </div>
                              <div className='th-col tg-email ml-5'>Status</div>
                              {/* <div className="th-col tg-action"></div> */}
                              <div className='th-col tg-action'></div>
                            </div>
                          )}
                          {profile.map(item => (
                            <div
                              // onClick={handleDialogOpen}
                              className='paymentsCon-item-row'
                            >
                              <div className='td-col tg-email'>{item.tid}</div>
                              <div className='td-col tg-email ml-5'>
                                {item.date}
                              </div>
                              <div className='td-col tg-email ml-5'>
                                {item.description}
                              </div>
                              <div className='td-col tg-email ml-5'>
                                {item.status}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='full-block mt-3'>
                      <Button
                        className='btn btn-style1 btn-green'
                        size='small'
                        onClick={() => {
                          handleStripeSubscription()
                        }}
                      >
                        Add Balance with Stripe
                      </Button>
                    </div>
                    <div className='emptyspace50'> &nbsp;</div>
                    <div
                      style={{
                        display: 'inline-flex',
                        marginTop: '60px',
                        marginBottom: '12px'
                      }}
                    >
                      <Typography
                        style={{
                          color: '#c2c0c0',
                          fontWeight: 600,
                          fontSize: '13px'
                        }}
                      >
                        Payment Powered By{' '}
                      </Typography>
                      <Typography
                        style={{
                          color: '#7366fd',
                          fontSize: '13px',
                          fontWeight: 600
                        }}
                      >
                        {' '}
                        Stripe
                      </Typography>
                    </div>

                    {/* end full-block */}
                  </div>
                </div>
              </div>
            </div>
            {/* end row */}
          </div>
        </div>
      </div>
      {/* end right -sidebar */}
      <Footer />
    </div>
  )
}

export default Profile
