import React, { useState, useEffect, useRef } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  Typography,
  Grid,
  Button,
  ButtonBase
} from '@material-ui/core'

import { Formik, Form, Field } from 'formik'
import CustomTextField from '../components/CustomTextField'
import CustomCheckbox from '../components/CustomCheckbox'
import CustomButton from '../components/CustomButton'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from '../lib/context'
import { toast } from 'react-toastify'
import api from '../api'
import { loadStripe } from '@stripe/stripe-js'
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup'
import CustomTextarea from '../components/CustomTextarea'
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup'

import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import clsx from 'clsx'
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { Country, State, City } from "country-state-city";
import { Box, TextField } from '@mui/material'
import { Autocomplete as MUIAutocomplete } from '@material-ui/lab';

import FormikSelect from '../components/FormikSelect'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { Map, GoogleApiWrapper } from "google-maps-react";
import { GoogleMap, LoadScript, Autocomplete } from "@react-google-maps/api";




const useRadioStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: '50%',
    width: 18,
    height: 18,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundColor: '#48a068',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    // '&:before': {
    // 	display: 'block',
    // 	width: 16,
    // 	height: 16,
    // 	backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    // 	content: '""',
    // },
    'input:hover ~ &': {
      backgroundColor: '#f89e23'
    }
  }
})

function StyledRadio(props) {
  const classes = useRadioStyles()

  return (
    <Radio
      className={classes.root}
      disableRipple
      color='default'
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      size='small'
      icon={<span className={classes.icon} />}
      {...props}
    />
  )
}
export const signupSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required.'),
  lastName: yup.string().required('Last Name is required.'),
  company: yup.string().required('Company Name is required.'),
  // address: yup.string().required('Address is required.'),
  // country: yup.string().required('Address is required.'),
  // continent: yup.string().required('Address is required.'),
  phone: yup
    .string()
    .matches(/(\D*\d){8}/, 'Phone number is not valid')
    .required('Phone is required.'),
  username: yup
    .string()
    .email('Invalid Email.')
    .required('Email is required.'),
  password: yup
    .string()
    .required('Password is required.')
    .min(8, 'Password must be at least 8 characters long.')
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
      'Password must contain at least one number and one special character.'
    ),
})



function SignupPageFunction(props) {

  const countryData = Country.getAllCountries().map((country) => ({
    value: country.isoCode,
    displayValue: country.name,
  }));

  const useStyles = makeStyles(theme => ({
    button: props => ({
      ...theme.typography.button,

      padding: '4px 0px',
      height: 'fit-content',
      // minHeight: '30px',
      borderRadius: '3px',
      boxShadow:
        '0 1px 0 0 rgba(22, 29, 37, 0.1), inset 0 1px 0 1px rgba(255, 255, 255, 0.06)',
      fontWeight: 'bold!important',
      width: 'fit-content',
      transition: '0.2s all',
      outline: 'none',
      '&:active': {
        transform: 'scale(0.98)',
        boxShadow: '3px 2px 22px 1px rgba(0, 0, 0, 0.24)'
      }
    })
  }))
  const [address, setAddress] = useState('')
  const [continent, setContinent] = useState('')
  const [country, setCountry] = useState('')

  const classes = useStyles()
  const { openSnackbar } = useSnackbar()
  const { push } = useHistory()
  const [disableStripe, setDisableStripe] = useState(false)
  const [sellerId, setSellerId] = useState('')
  const [sessionId, setSessionId] = useState('')
  const [showStripe, setShowStripe] = useState(false)
  const initialValues = {
    firstName: '',
    lastName: '',
    company: '',
    phone: '',
    address: '',
    username: '',
    password: '',
    type: 'buyer',
    tnc: '',
    latitude: 0,
    longitude: 0,
    continent: "",
    country: ""
  }

  const [details, setDetails] = useState(initialValues);
  const [place, setPlace] = useState(null);
  const autocompleteRef = useRef(null);

  const handlePlaceSelect = () => {
    const selectedPlace = autocompleteRef.current.getPlace();
    if (selectedPlace.geometry && selectedPlace.geometry.location) {
      const lat = selectedPlace.geometry.location.lat();
      const lng = selectedPlace.geometry.location.lng();
      setDetails({
        ...details,
        address: ` ${selectedPlace.formatted_address}`,
        latitude: lat,
        longitude: lng,
      });
      setAddress(` ${selectedPlace.formatted_address}`);

    }

    setPlace(selectedPlace);
  };
  console.log(address, "addd")

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const results = await geocodeByAddress(details.address);
      const location = await getLatLng(results[0]);
      const { lat, lng } = location;
      const newData = {
        firstname: values.firstName,
        lastname: values.lastName,
        company: values.company,
        phone: values.phone,
        address: address,
        username: values.username,
        password: values.password,
        latitude: lat,
        longitude: lng,
        continent: continent,
        country: country
      };
      console.log(values, "vall")
      const { data } = await api.post(
        values.type === 'buyer' ? 'signup/buyer' : 'signup/seller',
        newData
      );

      setSellerId(data.data.id);
      localStorage.setItem('data', JSON.stringify(data.data));
      localStorage.setItem('token', 'Bearer ' + data.authToken);
      localStorage.setItem('type', values.type === 'buyer' ? 'buyer' : 'seller');
      values.type === 'buyer'
        ? push('/verification', { username: values.username, sellerId: data.data.id })
        : push('/verification', { username: values.username, sellerId: data.data.id });
      resetForm();
      openSnackbar('success', 'Signup successfully.');
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach(e => openSnackbar('error', e));
      } else {
        openSnackbar('error', error.message);
      }
    }
  };
  console.log(address, "add")
  const redirectToStripe = async () => {
    // const { data } = await api.get(`subscriptions/stripe-public-key`)
    // 'pk_test_TCjofXqhpGiaDzNLItyiccx000ougmhFnz';
    // const stripePromise = loadStripe(data.value)
    const stripePromise = loadStripe(
      'pk_test_51K6IJQB5OnvlhtIimji1uXUxV8S6AHtaxogATTN4oXsV6yEPToxCmxfq25eP28CUNIKw1FhqVwFAD0H78Ajcb3Fj00gYZf5paT'
    )
    const stripe = await stripePromise
    const result = await stripe.redirectToCheckout({
      sessionId: sessionId
    })
  }

  useEffect(() => {
    if (sessionId) {
      redirectToStripe()
    }
  }, [sessionId])

  const handleStripeSubscription = async investorId => {
    try {
      setDisableStripe(true)
      // const { data } = await api.post(
      // 	`subscriptions/stripe?investorId=${investorId}`
      // );
      const { data } = await api.post(`signup/stripe?reference=${sellerId}`)
      setSessionId(data)
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        setDisableStripe(false)
        error.response.data.errors.forEach(e => toast.error(e))
      } else {
        setDisableStripe(false)
        toast.error(error.message)
      }
    }
  }

  const navigateTo = () => {
    push("/terms-and-condition")
  }
  const continents = [
    { value: 'Africa', label: 'Africa' },
    { value: 'Antarctica', label: 'Antarctica' },
    { value: 'Asia', label: 'Asia' },
    { value: 'Europe', label: 'Europe' },
    { value: 'North America', label: 'North America' },
    { value: 'Oceania', label: 'Oceania' },
    { value: 'South America', label: 'South America' },
  ];
  return (
    <>
      <div style={{ maxWidth: '420px' }}>
        <>
          <Typography
            style={{
              fontSize: '28px',
              color: '#4aa56d',
              textTransform: 'uppercase',
              fontWeight: 600,
              marginBottom: '8px'
            }}
          >
            Sign up
          </Typography>
          <Typography
            style={{
              fontSize: '14px',
              color: '#fb9d1f',
              fontWeight: 600
            }}
          >
            Create Account
          </Typography>
          <Typography
            style={{
              fontSize: '14px',
              color: '#737979',
              marginBottom: '16px'
            }}
          >
            Enter your details to create an account with us
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={signupSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, control, errors, ...rest }) => {
              console.log('values', rest);
              return (
                <Form>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <RadioGroup
                        onChange={rest.handleChange}
                        value={values.type}
                        name='type'
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'start'
                          }}
                        >
                          <FormControlLabel
                            value='buyer'
                            control={<StyledRadio color='secondary' />}
                            label='Buyer'
                          />
                          <FormControlLabel
                            value='seller'
                            control={<StyledRadio color='secondary' />}
                            label='Seller'
                          />
                        </div>
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        as={CustomTextField}
                        name='firstName'
                        label='First Name'
                        placeholder='First Name'
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        as={CustomTextField}
                        name='lastName'
                        label='Last Name'
                        placeholder='Last Name'
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        as={CustomTextField}
                        name='company'
                        label='Company Name'
                        placeholder='Company Name'
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={6}>

                      <Typography style={{ color: "rgb(137, 143, 143)", fontSize: "14px", fontWeight: "500" }}>
                        Phone
                      </Typography>
                      <PhoneInput
                        // containerStyle={{ marginTop: "6.2%" }}
                        country="us"

                        error={!!errors?.phone} // Convert errors.phone to boolean
                        inputStyle={{
                          border: errors?.phone
                            ? "1px solid red"
                            : "1px solid #ced4da",
                          width: "100%",
                          height: "38px",
                          borderRadius: "5px",
                          marginTop: "1px"

                        }}
                        onChange={(e) => { setFieldValue('phone', e) }}
                      />
                      {errors.phone && (
                        <Typography
                          style={{
                            color: "#fb4868",
                            fontWeight: "100",
                            fontSize: "14px"
                          }}
                        >
                          {errors.phone}
                        </Typography>
                      )}
                    </Grid>


                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                      <Autocomplete
                        onLoad={(autocomplete) => {
                          autocompleteRef.current = autocomplete;
                        }}
                        onPlaceChanged={() => handlePlaceSelect()}
                      >
                        <TextField
                          variant="outlined"
                          required
                          placeholder="Address"
                          inputProps={{ style: { textTransform: "capitalize" } }}
                          onChange={(e) => setAddress(e.target.value)}
                          // {...register("address")}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color: "black",
                            },
                          }}
                        />
                      </Autocomplete>

                      {errors.address && (
                        <p
                          style={{
                            color: "red",
                            justifyContent: "start",
                            display: "flex",
                          }}
                        >
                          {errors.address.message}
                        </p>
                      )}

                    </Grid>

                    <Grid item xs={12} style={{ marginTop: "20px", marginBottom: "20px" }}>
                      <Field name="continent">
                        {({ field, form }) => (
                          <MUIAutocomplete
                            options={continents.map((continent) => continent.label)}
                            getOptionLabel={(option) => option || ''}
                            onChange={(_, value) => {
                              form.setFieldValue(field.name, value);
                              console.log(value);
                              setContinent(value); // Custom function to handle the selected value
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Continent"
                                variant="outlined"
                                size="small"
                                required
                                error={form.errors.continent && form.touched.continent}
                                helperText={
                                  form.touched.continent && form.errors.continent ? (
                                    <p
                                      style={{
                                        fontSize: '0.9rem',
                                        color: 'red',
                                      }}
                                    >
                                      {form.errors.continent}
                                    </p>
                                  ) : null
                                }
                              />
                            )}
                          />
                        )}
                      </Field>

                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field name="country">
                        {({ field, form }) => (
                          <MUIAutocomplete
                            {...field}
                            options={countryData.map((country) => country.displayValue)}
                            getOptionLabel={(option) => option || ''}
                            onChange={(_, value) => {
                              form.setFieldValue(field.name, value);
                              console.log(value)
                              setCountry(value)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Country"
                                variant="outlined"
                                required
                                error={form.errors.country && form.touched.country}
                                helperText={
                                  form.touched.country && form.errors.country ? (
                                    <p
                                      style={{
                                        fontSize: "0.9rem",
                                        color: "red",
                                      }}
                                    >
                                      {form.errors.country}
                                    </p>
                                  ) : null
                                }
                              />
                            )}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                      <Field
                        as={CustomTextField}
                        name='username'
                        label='Email'
                        placeholder='Email'
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={CustomTextField}
                        name='password'
                        label='Password'
                        placeholder='Password'
                        type='password'
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}

                    >
                      <Field
                        as={CustomCheckbox}
                        label={<a style={{ cursor: "pointer", color: "black" }} href="/terms-and-condition">I agree to the terms and conditions</a>}
                        name='tnc'
                        setFieldValue={setFieldValue}
                        className='container'
                        style={{
                          height: '1rem',
                          width: '1rem',
                          marginRight: '10px'
                        }}
                      />

                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '12px' }}>
                      <CustomButton
                        type='submit'
                        variant='contained'
                        style={{ width: '100%' }}
                        color='primary'
                        disabled={rest.isSubmitting || !values.tnc}
                      >
                        SIGNUP
                      </CustomButton>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </>
      </div>

    </>
  )
}
function SignupForm() {
  const WrappedSignUpPage = GoogleApiWrapper({
    apiKey:"AIzaSyD03xPe6O4SbhdpF_CYFdGgetqfKpLAv0o",
    //apiKey: "AIzaSyBm2j93IqHV6pH7OO-WuTOLkyJ3VlbvD-4",
    //apiKey: "AIzaSyDsLFAtLpCN0KFbR-syWTV3cgFdtylgLbA",
  })(SignupPageFunction);

  return (
    <Box >
      <WrappedSignUpPage />
    </Box>
  );
}
export default SignupForm
