import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import styled from 'styled-components'
import productImage from '../../assets/images/pro-img.jpg'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import * as Yup from 'yup';

import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grow,
    Typography,
    Checkbox,
    IconButton,
    Switch,
    Grid,
    TextField
} from '@material-ui/core'
import api from '../../api'
import { useSnackbar } from '../../lib/context'
import { toast } from 'react-toastify'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { useHistory } from 'react-router-dom'
import Image from '../../assets/images/img-02.jpg'
import CustomLoader from '../../components/CustomLoader'
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported'
import { logout } from '../../utils/utils'
import AdminSidebar from '../Sidebar/AdminSidebar'
import AdminTitle from '../PageTitle/AdminTitle'
import editImg from '../../assets/images/icon-edit.png'
import deleteImg from '../../assets/images/icon-delete2.png'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import CustomTextField2 from '../../components/CustomTextField2'
import CustomTextarea from '../../components/CustomTextarea'
import FormikSelect from '../../components/FormikSelect'
// import FormikDatePicker from '../../components/FormikDatePicker'
import { formatDate3 } from '../../utils/utils'
import Footer from '../../layout/Footer'

const DropDownListContainer = styled('div')

const DropDownList = styled('ul')`
  padding: 0;
  margin: 0;
  padding-left: 0.5em;
  background: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 800;
  font-size: 0.8rem;
  &:first-child {
    padding-top: 0.5em;
  }
`

const ListItem = styled('li')`
  list-style: none;
  margin-bottom: 0.4em;
  padding-top: 0rem
  font-weight: 800;
  font-size: 1rem;
`

const options = [
    <li className='nav-item'>
        <a href='/order' className='nav-link'>
            <Link to='/order'> In Progress</Link>{' '}
        </a>
    </li>,
    <li className='nav-item'>
        <a href='/completedorder' className='nav-link'>
            <Link to='/completedorder'>Completed</Link>{' '}
        </a>
    </li>,
    <li className='nav-item'>
        <a href='/shipped' className='nav-link'>
            <Link to='/shipped'>Shipped</Link>{' '}
        </a>
    </li>,
    <li className='nav-item'>
        <a href='/canceled' className='nav-link'>
            <Link to='/canceled'>Canceled</Link>{' '}
        </a>
    </li>
]

const optionz = [
    <li id='active' className='nav-item'>
        <a href='/products' className='nav-link'>
            <Link to='/products'>Recent Products</Link>{' '}
        </a>
    </li>,
    <li className='nav-item'>
        <a href='/allproducts' className='nav-link'>
            <Link to='/allproducts'>All Products</Link>{' '}
        </a>
    </li>,
    <li className='nav-item'>
        <a href='addproduct' className='nav-link'>
            <Link to='addproduct'>Add A Product</Link>{' '}
        </a>
    </li>
]


function Brands() {
    const { push } = useHistory()
    const [deleteId, setDeleteId] = useState('')
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const { openSnackbar } = useSnackbar()
    const [brands, setbrands] = useState([])
    // const brands = [
    //     {
    //         id: 1,
    //         sellername: 'Nike',
    //         brands: "Hitachi"
    //     },
    //     {
    //         id: 2,
    //         sellername: 'Mike',
    //         brands: "Mitsibushi"
    //     }
    // ]

    const [isDialogOpen, setIsDialogOpen] = useState({ open: false })
    const [tabVal, setTabVal] = useState(1)
    const handleDialogClose = () => {
        setIsDialogOpen({ open: false })
    }
    const handleDialogOpen = () => {
        setIsDialogOpen({ open: true })
    }
    const handleDeleteDialogOpen = id => {
        setDeleteId(id)
        setIsDeleteDialogOpen(true)
    }
    const handleDeleteDialogClose = () => {
        setDeleteId('')
        setIsDeleteDialogOpen(false)
    }
    const [isOpen, setIsOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null)

    const toggling = () => {
        setIsOpen(!isOpen)
        setIsOn(false)
    }

    const [isOn, setIsOn] = useState(true)
    const toggling1 = () => {
        setIsOn(!isOn)
        setIsOpen(false)
    }

    const onOptionClicked = value => () => {
        console.log(selectedOption)
    }

    const onOptionzClicked = value => () => {
        console.log(selectedOption)
    }
    // const fetchInfo = async () => {
    //     try {
    //         const { data } = await api.get('/home/lookups')
    //         setProducts(data)
    //     } catch (error) {
    //         if (
    //             error.response !== null ||
    //             error.response.data !== null ||
    //             error.response.data.errors.length !== 0
    //         ) {
    //             error.response.data.errors.forEach(e => openSnackbar('error', e))
    //         } else {
    //             openSnackbar('error', error.message)
    //         }
    //     }
    // }
    const [option, setOption] = useState({
        page: 1,
        size: 5,
        sortBy: "documenttype",
        sortOrder: "asc",
        search: ""
    })
    const [totalElem, setTotalElem] = useState();
    const [search, setSearch] = useState();

    const fetchData = async () => {
        try {
            const { data } = await api.post('/brands/getBrands', option)
            setbrands(data.content)
            setTotalElem(data.totalElements);

        } catch (error) {
            if (
                error.response !== null ||
                error.response.data !== null ||
                error.response.data.errors.length !== 0
            ) {
                // error.response.data.errors.forEach(e => openSnackbar('error', e))
            } else {
                openSnackbar('error', error.message)
            }
        }
    }

    const handleDelete = async productId => {
        try {
            const { data } = await api.put(`brands/deleteBrand?id=${deleteId}`)
            openSnackbar('success', 'Brands is deleted.')
            handleDeleteDialogClose()
            fetchData()
        } catch (error) {
            if (
                error.response !== null ||
                error.response.data !== null ||
                error.response.data.errors.length !== 0
            ) {
                error.response.data.errors.forEach(e => openSnackbar('error', e))
            } else {
                openSnackbar('error', error.message)
            }
        }
    }

    const handleSubmit = async values => {
        try {
            console.log('values', values)
            if (values.type === 'Add') {
                const { data } = await api.post(`brands`, {
                    ...values,
                })
                fetchData()
                openSnackbar('success', 'New Promotion Added Successfully')
                handleDialogClose()
            }
            else {
                const { data } = await api.put(`brands/updateBrand`, {
                    ...values,
                })
                fetchData()
                openSnackbar('success', 'Edit Promotion Added Successfully')
                handleDialogClose()
            }

        } catch (error) {
            if (
                error.response !== null ||
                error.response.data !== null ||
                error.response.data.errors.length !== 0
            ) {
                error.response.data.errors.forEach(e => openSnackbar('error', e))
            } else {
                openSnackbar('error', error.message)
            }
        }
    }

    useEffect(() => {
        // fetchInfo()
        fetchData()
    }, [option])

    // if (!products) {
    //     return <CustomLoader />
    // }
    const handleSort = (column) => {
        console.log(column);
        setOption({
            ...option,
            sortBy: column,
            sortOrder:
                option.sortBy === column && option.sortOrder === "asc" ? "desc" : "asc",
        });
    };
    return (
        <div>
            <Dialog
                open={isDeleteDialogOpen}
                onClose={handleDeleteDialogClose}
                TransitionComponent={Grow}
            >
                <DialogContent>
                    {/* <div className="modal" onClick={handleDialogClose}> */}
                    {/* <div
							className="modal-content"
							onClick={(e) => e.stopPropagation()}
						> */}
                    {/* <div className="modal-body"> */}
                    <HighlightOffIcon
                        style={{ color: '#48a068', float: 'right' }}
                        onClick={handleDeleteDialogClose}
                    />
                    <div>
                        <Typography>
                            Are you sure you want to delete this product?
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button onClick={() => handleDeleteDialogClose()}>Cancel</Button>
                            <Button onClick={() => handleDelete()}>Confirm</Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={isDialogOpen.open}
                onClose={handleDialogClose}
                TransitionComponent={Grow}
            >
                <DialogTitle>{isDialogOpen.type == "Add" ? "Add" : "Edit"} Brands</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={{
                            id: "",
                            name: "",
                            documenttype: "",
                            ...isDialogOpen
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().required('Name is required'),
                            // documenttype: Yup.string().required('Document Type is required'),
                        })}
                    >
                        {({ values, setFieldValue }) => {
                            console.log(values)
                            return (
                                <Form>
                                    {console.log('Promo ', values)}
                                    <Grid container spacing={2}>
                                        {/* <Grid item xs={12}>
                      <Field
                        name='messagetext'
                        label='Type Message'
                        // className="form-control style2"
                        placeholder='Type Message'
                        // rows={3}
                        as={CustomTextField2}
                      />
                    </Grid> */}
                                        {/* <Field
                                                component={FormikSelect}
                                                label={'Seller'}
                                                variant='standard'
                                                // name="callCenterIds"
                                                size='small'
                                                options={products.seller.map(item => {
                                                    return {
                                                        value: item.id,
                                                        label: item.firstname + ' ' + item.lastname
                                                    }
                                                })}
                                                textField='label'
                                                valueField='value'
                                                onChange={e => {
                                                    setFieldValue('sellerId', e.target.value)
                                                    // setFieldValue('sellername', e.target.label)
                                                }}
                                            // inputProps={{ readOnly: view }}
                                            /> */}
                                        {/* <Grid item xs={12}>
                                            <TextField
                                                label='Document Type'
                                                type='text'
                                                fullWidth
                                                value={values.documenttype}
                                              
                                                onChange={e => {
                                                    console.log('E ', e.target.value)
                                                    setFieldValue(
                                                        'documenttype',
                                                        (e.target.value)
                                                    )
                                                }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <ErrorMessage
                                            name="documenttype"
                                            component="div"
                                            style={{ color: "red" }}
                                        /> */}
                                        <Grid item xs={12}>
                                            <TextField
                                                // id='date'
                                                label='Brands'
                                                type='text'
                                                fullWidth
                                                value={values.name}
                                                // defaultValue={values.startdate}
                                                // '2017-05-24'
                                                // className={classes.textField}
                                                onChange={e => {
                                                    console.log('E ', e.target.value)
                                                    setFieldValue(
                                                        'name',
                                                        (e.target.value)
                                                    )
                                                }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <ErrorMessage
                                            name="name"
                                            component="div"
                                            style={{ color: "red" }}
                                        />

                                        <Grid item xs={12}>
                                            <Button
                                                style={{
                                                    float: 'right',
                                                    backgroundColor: '#51a470',
                                                    marginBottom: 10,
                                                    color: 'white'
                                                }}
                                                variant='contained'
                                                color='primary'
                                                type='submit'
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>


            {/* Required meta tags */}
            <meta charSet='utf-8' />
            <meta
                name='viewport'
                content='width=device-width, initial-scale=1, shrink-to-fit=no'
            />
            {/* Bootstrap CSS */}
            <link rel='stylesheet' href='assets/css/bootstrap.min.css' />
            <link rel='stylesheet' href='assets/css/fontawesome.min.css' />
            <link rel='stylesheet' href='assets/css/brands.css' />
            <link rel='stylesheet' href='assets/css/solid.css' />
            <link rel='stylesheet' href='assets/css/style.css' />
            <title>The Xchange Marketplace</title>
            <div id='dashboard' className='col-2-layout'>
                <div className='left-sidebar equalColumn'>
                    <div className='box-content'>
                        <AdminSidebar pageName='admin-brands' />
                    </div>
                </div>
                {/* end left-sidebar */}
                <div className='right-content equalColumn'>
                    <div className='box-content title-box'>
                        <AdminTitle />
                    </div>
                    {/* end box-content */}
                    <div className='box-content minColheight'>
                        <div className='row' id='dashboard-tabs'>
                            <div className='col-md-12'>
                                <nav>
                                    <div
                                        className='nav nav-tabs'
                                        id='nav-tab'
                                        role='tablist'
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >


                                        <div style={{ alignSelf: 'center' }}>
                                            <a
                                                className={`nav-item ${tabVal == 1 ? 'active' : ''}`}
                                                id='nav-account-tab'
                                                data-toggle='tab'
                                                href='#nav-account'
                                                role='tab'
                                                aria-controls='nav-account'
                                                aria-selected='true'
                                                onClick={() => {
                                                    setTabVal(1)
                                                }}
                                            >
                                                {/* <Link to='/admin-seller-promotion'> */}
                                                Brands
                                                {/* </Link> */}
                                            </a>

                                            {/* <a
                        className="nav-item"
                        id="nav-account-tab"
                        data-toggle="tab"
                        href="#nav-account"
                        role="tab"
                        aria-controls="nav-account"
                        aria-selected="true"
                      >
                        <Link to="/allproducts">ALL PRODUCTS</Link>
                      </a> */}

                                        </div>
                                        <div style={{ flexGrow: 1 }}></div>
                                        <div className="search-blk" style={{ width: "400px", marginRight: "10px" }} >
                                            <input
                                                type="text"
                                                placeholder="Search all brand"
                                                className="form-control"
                                                value={search}
                                                onChange={(e) => {
                                                    // setSearch(e.target.value);
                                                    setOption({ ...option, search: e.target.value });
                                                }}
                                            />
                                            <button
                                                type="submit"
                                                defaultValue="Search"
                                                className="btn-search"
                                                onClick={() => setOption({ ...option, search })}
                                            >
                                                Search
                                            </button>
                                        </div>
                                        {/* <a
											id="addprod"
											href="/addproduct"
											className="nav-item"
										> */}

                                        <Button
                                            style={{
                                                float: 'right',
                                                marginBottom: 10,
                                                color: 'white',
                                                backgroundColor: '#51a470'
                                            }}
                                            variant='contained'
                                            color='primary'
                                            // href="/addproduct"
                                            onClick={() => setIsDialogOpen({ open: true, type: "Add" })}
                                        >
                                            Add Brands
                                        </Button>

                                        {/* </a> */}
                                    </div>
                                </nav>
                            </div>
                            {/* <div className="col-md-3 text-right">
										<label>&nbsp;</label>
										<button className="btn btn-style1 btn-green btn-save">
											Add A Product
										</button>
									</div> */}
                            <div
                                className='tab-content'
                                id='nav-tabContent'
                                style={{
                                    width: '100%',
                                    paddingRight: '12px',
                                    paddingLeft: '12px'
                                }}
                            >
                                <div
                                    className='tab-pane fade show active'
                                    id='nav-activeOrders'
                                    role='tabpanel'
                                    aria-labelledby='nav-activeOrders-tab'
                                >




                                    <div className="payments-content">
                                        {brands.length == 0 ? (
                                            <label>No Record Found</label>
                                        ) : (
                                            <div className="paymentsCon-thead">
                                                {/* <div className="th-col tg-email ml-3" onClick={() =>
                                                    handleSort("documenttype")
                                                } style={{ width: "30%" }}>Document Type <span>
                                                        {option.sortBy ===
                                                            "documenttype" &&
                                                            option.sortOrder === "asc"
                                                            ? "↑"
                                                            : "↓"}
                                                    </span></div>{" "} */}
                                                <div className="th-col tg-email ml-3" onClick={() =>
                                                    handleSort("name")
                                                } >Brands <span>
                                                        {option.sortBy ===
                                                            "name" &&
                                                            option.sortOrder === "asc"
                                                            ? "↑"
                                                            : "↓"}
                                                    </span></div>{" "}
                                                {/* <div className="th-col tg-email " >Brands</div> */}
                                                <div className="th-col tg-action"></div>
                                            </div>
                                        )}
                                        {brands.map((item) => (
                                            <div
                                                // onClick={handleDialogOpen}
                                                className="paymentsCon-item-row"
                                            >

                                                {/* <div className="td-col tg-email ml-3  " style={{ width: "30%" }}>
                                                    {item.documenttype}                                                    
                                                    </div> */}
                                                <div className="td-col tg-email ml-3" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                                                    {item.name}
                                                </div>


                                                <td style={{ textAlign: "end" }}> <IconButton
                                                    className="mr-4"
                                                    onClick={() => {
                                                        // push(`editproduct/${product.id}`);
                                                        setIsDialogOpen({
                                                            ...item,
                                                            open: true,
                                                            type: "Edit",
                                                        });
                                                    }}
                                                >
                                                    <img src={editImg} alt="" width="25"></img>
                                                </IconButton> </td>
                                                <td style={{ textAlign: "end" }}> <IconButton
                                                    // className="mr-3"
                                                    onClick={
                                                        () => {
                                                            // handleDelete(product.id)
                                                            handleDeleteDialogOpen(item.id)
                                                        }
                                                    }
                                                >
                                                    <img src={deleteImg} alt="" width="25"></img>
                                                </IconButton></td>




                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-row-reverse">
                            <div>
                                <select
                                    className="form-select mt-2 mx-2"
                                    value={option.size}
                                    onChange={(e) =>
                                        setOption({ ...option, size: e.target.value })
                                    }
                                >
                                    {/* <option selected>{option.size}</option> */}
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="100">100</option>
                                </select>
                            </div>

                            <nav aria-label="...">
                                <ul className="pagination">
                                    <li
                                        className={`page-item ${option.page === 1 ? "disabled" : ""
                                            }`}
                                    >
                                        <span
                                            className="page-link"
                                            onClick={() =>
                                                setOption({ ...option, page: option.page - 1 })
                                            }
                                            style={{ color: "black" }}
                                        >
                                            Previous
                                        </span>
                                    </li>
                                    {Array.from(
                                        { length: Math.ceil(totalElem / option.size) },
                                        (_, index) => (
                                            <li
                                                key={index}
                                                className={`page-item ${option.page === index + 1 ? "active" : ""
                                                    }`}
                                            >
                                                <span
                                                    className="page-link"
                                                    onClick={() =>
                                                        setOption({
                                                            ...option,
                                                            page: index + 1,
                                                        })
                                                    }
                                                    style={
                                                        {
                                                            // backgroundColor: "#4ba26b",
                                                            // color: "black",
                                                        }
                                                    }
                                                >
                                                    {index + 1}
                                                </span>
                                            </li>
                                        )
                                    )}
                                    <li
                                        className={`page-item ${option.page === Math.ceil(totalElem / option.size)
                                            ? "disabled"
                                            : ""
                                            }`}
                                    >
                                        <span
                                            className="page-link"
                                            onClick={() =>
                                                setOption({
                                                    ...option,
                                                    page: option.page + 1,
                                                })
                                            }
                                            style={{ color: "black" }}
                                        >
                                            Next
                                        </span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    {/* Optional JavaScript */}
                    {/* jQuery first, then Popper.js, then Bootstrap JS */}
                </div>
                <Footer />
            </div>
        </div >
    )
}

export default Brands
