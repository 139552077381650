import React, { useState } from 'react';
import './form.scss';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// import { pure } from 'recompose';

const CustomTextarea2 = ({
	label,
	type,
	value,
	renderActions,
	className,
	style,
	setFieldValue,
	className1,
	className2,
	placeholder,
	...props
}) => {
	const [field, meta] = useField(props);
	const [visibility, setVisibility] = useState(false);
	const errorText = meta.error && meta.touched ? meta.error : '';
	const styles = makeStyles((theme) => ({
		input: {
			'&::placeholder': {
				color: meta.error && meta.touched ? '#fb4868' : '#898f8f',
			},
		},
	}));
	const classes = styles();

	const toggleVisibility = () => {
		setVisibility(!visibility);
	};
	console.log(field.name);
	return (
		<div
			// className={'text-field'.concat(` ${className}`)}
			style={{ display: 'grid' }}
		>
			<div className="form-sec full-block">
				<div className={className1}>
					<div className={className2}>
						{' '}
						<label
							style={{
								// color:
								// 	meta.error && meta.touched ? '#83827c' : '#898f8f',
								fontSize: '14px',
								fontWeight: 500,
								fontFamily: 'Montserrat, sans-serif',
								marginBottom: '0px!important',
								color: 'black',
								fontWeight: 'bold',
								marginBottom: '4px',
								marginLeft: '8px',
							}}
							htmlFor={field.name}
						>
							{label ?? ''}
						</label>
						<textarea
							maxLength={500}
							// {...field}
							id={field.name}
							type={visibility ? 'text' : type}
							// maxLength="50"
							onBlur={(e) => {
								const val = (e.target.value || '').replace(
									/\s+/gi,
									' '
								);
								setFieldValue(field.name, val.trim());
							}}
							name={field.name}
							placeholder={placeholder}
							className="form-control"
							value={value}
							// style={{
							// 	...(meta.error && meta.touched
							// 		? {
							// 				border: '1px solid #fb4868',
							// 				color: '#fb4868',
							// 		  }
							// 		: {
							// 				border: '1px solid #9fabc6',
							// 				color: '#898f8f',
							// 		  }),
							// 	...style,
							// }}
							{...props}
						/>
					</div>
				</div>
				{/* end row */}
			</div>
			{/* <span className="errorText">{errorText}</span> */}
			{/* {renderActions && renderActions()} */}
		</div>
	);
};

CustomTextarea2.propTypes = {
	label: PropTypes.string,
};

CustomTextarea2.defaultProps = {
	label: '',
};

export default CustomTextarea2;
