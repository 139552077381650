import React, { useState } from 'react'
import Layout from '../layout/Layout'
import backgroundImage from '../assets/images2/main-bg2.jpg'

import { Formik, Form, Field } from 'formik';
import api from '../api';
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom';
import CustomTextField from '../components/CustomTextField';
import CustomButton from '../components/CustomButton';
import * as yup from 'yup';
import { Box, Typography, Grid, Button } from '@mui/material';
import OtpInput from 'react-otp-input';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from '../lib/context';



export const loginSchema = yup.object().shape({
    otp: yup.string().length(4, 'Enter a valid 4-digit code').required('Code is required'),

});
const Verification = () => {
    // const [otp, setOtp] = useState('');

    const cookies = new Cookies();
    const { push } = useHistory();
    const initialValues = {
        otp: '',
        isRemeberMe: false,
        type: 'buyer',
    };
    const location = useLocation();
    const { username, sellerId } = location.state || {};
    console.log(sellerId, "idd")
    const { openSnackbar } = useSnackbar()

    const storedUserType = localStorage.getItem("type");
    console.log(storedUserType, "typeeee")
    const handleSubmit = async (values) => {
        console.log(values)
        try {
            const { data } = await api.post(
                storedUserType == 'buyer' ? `/signup/buyerVerify?email=${username}&otp=${values.otp}` : `/signup/sellerVerify?email=${username}&otp=${values.otp}`
            );
            localStorage.setItem('data', JSON.stringify(data.data));
            localStorage.setItem('token', 'Bearer ' + data.authToken);
            localStorage.setItem(
                'type',
                storedUserType == 'buyer' ? 'buyer' : 'seller'
            );

            if (storedUserType === 'buyer') {
                push('/myacc'); // Navigate to home if type is buyer
            }
            else if (storedUserType === 'seller') {
                push('/stripe', { sellerId: sellerId }); // Navigate to home if type is buyer

            }
            else {
                push('/ordersummary'); // Navigate to home if type is buyer

            }

            // openSnackbar('success', );

        } catch (error) {
            if (
                error.response !== null ||
                error.response.data !== null ||
                error.response.data.errors.length !== 0
            ) {
                error.response.data.errors.forEach((e) =>
                    openSnackbar('error', e)
                );
            } else {
                openSnackbar('error', error);
            }
        }

    };
    return (
        // <Layout pageName=''>
        <div
            style={{
                minHeight: '80vh',
                height: 'fit-content',
                width: '100%',
                backgroundImage: `url(${backgroundImage})`,
                backgroundRepeat: 'round'
            }}
        >
            {/* <img
					src={backgroundImage}
					style={{ width: '100%', height: '100%' }}
				/> */}
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={loginSchema}
            >
                {({ values, setFieldValue, ...rest }) => {
                    return (
                        <Box sx={{ py: 5, pt: 9 }}>

                            <Form>
                                <Box sx={{ textAlign: "center", mb: 4 }}>

                                    <Typography sx={{ fontSize: "3rem", fontWeight: "bold" }} variant='h6'>
                                        Enter Verification Code
                                    </Typography>
                                </Box>
                                <Grid container spacing={3} justifyContent='center' alignItems='center'>

                                    <Grid item xs={12} md>

                                        <OtpInput
                                            inputType='tel'
                                            numInputs={4}
                                            renderSeparator={<span> - </span>}
                                            renderInput={(props) => <input {...props} />}
                                            inputStyle={{ width: "75px", borderRadius: "10px", height: "75px" }}
                                            containerStyle={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                            onChange={(otp) => setFieldValue('otp', otp)}
                                            value={values.otp}
                                        />
                                    </Grid>



                                    <Grid
                                        item
                                        xs={12}
                                        md={12}

                                    >
                                        <Box sx={{ textAlign: "center" }}>

                                            <Button
                                                type="submit"
                                                variant="contained"
                                                sx={{ backgroundColor: "rgb(248, 158, 35)", px: 10, fontSize: "1.5rem", cursor: "pointer" }}
                                                disabled={rest.isSubmitting}

                                            >
                                                Verify Code
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        </Box>
                    );
                }}
            </Formik>
        </div>
        // </Layout>
    )
}

export default Verification
