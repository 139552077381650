import React, { useState, useEffect, useRef } from 'react'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grow,
    Typography,
    Grid,
    Button,
    ButtonBase
} from '@material-ui/core'

import { Formik, Form, Field } from 'formik'
import CustomTextField from '../components/CustomTextField'
import CustomCheckbox from '../components/CustomCheckbox'
import CustomButton from '../components/CustomButton'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from '../lib/context'
import { toast } from 'react-toastify'
import api from '../api'
import { loadStripe } from '@stripe/stripe-js'
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup'
import CustomTextarea from '../components/CustomTextarea'
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup'

import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import clsx from 'clsx'
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { Country, State, City } from "country-state-city";
import { Box, TextField } from '@mui/material'
import { Autocomplete as MUIAutocomplete } from '@material-ui/lab';

import FormikSelect from '../components/FormikSelect'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-google-places-autocomplete";
import { Map, GoogleApiWrapper } from "google-maps-react";
import { GoogleMap, LoadScript, Autocomplete } from "@react-google-maps/api";

import { useLocation } from 'react-router-dom';



const useRadioStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    icon: {
        borderRadius: '50%',
        width: 18,
        height: 18,
        boxShadow:
            'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage:
            'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5'
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)'
        }
    },
    checkedIcon: {
        backgroundColor: '#48a068',
        backgroundImage:
            'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        // '&:before': {
        // 	display: 'block',
        // 	width: 16,
        // 	height: 16,
        // 	backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        // 	content: '""',
        // },
        'input:hover ~ &': {
            backgroundColor: '#f89e23'
        }
    }
})

function StyledRadio(props) {
    const classes = useRadioStyles()

    return (
        <Radio
            className={classes.root}
            disableRipple
            color='default'
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            size='small'
            icon={<span className={classes.icon} />}
            {...props}
        />
    )
}
export const Stripes = yup.object().shape({
    // firstName: yup.string().required('First Name is required.'),
    // lastName: yup.string().required('Last Name is required.'),
    // company: yup.string().required('Company Name is required.'),
    // // address: yup.string().required('Address is required.'),
    // // country: yup.string().required('Address is required.'),
    // // continent: yup.string().required('Address is required.'),
    // phone: yup
    //     .string()
    //     .matches(/(\D*\d){8}/, 'Phone number is not valid')
    //     .required('Phone is required.'),
    // username: yup
    //     .string()
    //     .email('Invalid Email.')
    //     .required('Email is required.'),
    // password: yup
    //     .string()
    //     .required('Password is required.')
    //     .min(8, 'Password must be at least 8 characters long.')
    //     .matches(
    //         /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
    //         'Password must contain at least one number and one special character.'
    //     ),
})



function Stripe(props) {

    const countryData = Country.getAllCountries().map((country) => ({
        value: country.isoCode,
        displayValue: country.name,
    }));

    const useStyles = makeStyles(theme => ({
        button: props => ({
            ...theme.typography.button,

            padding: '4px 0px',
            height: 'fit-content',
            // minHeight: '30px',
            borderRadius: '3px',
            boxShadow:
                '0 1px 0 0 rgba(22, 29, 37, 0.1), inset 0 1px 0 1px rgba(255, 255, 255, 0.06)',
            fontWeight: 'bold!important',
            width: 'fit-content',
            transition: '0.2s all',
            outline: 'none',
            '&:active': {
                transform: 'scale(0.98)',
                boxShadow: '3px 2px 22px 1px rgba(0, 0, 0, 0.24)'
            }
        })
    }))

    const classes = useStyles()
    const { openSnackbar } = useSnackbar()
    const { push } = useHistory()
    const [disableStripe, setDisableStripe] = useState(false)
    // const [sellerId, setSellerId] = useState('')
    const [sessionId, setSessionId] = useState('')
    const [showStripe, setShowStripe] = useState(false)
    const location = useLocation()
    const { sellerId } = location.state || {};

    const initialValues = {
        firstName: '',
        lastName: '',
        company: '',
        phone: '',
        address: '',
        username: '',
        password: '',
        type: 'buyer',
        tnc: '',
        latitude: 0,
        longitude: 0,
        continent: "",
        country: ""
    }

    const [details, setDetails] = useState(initialValues);
    const [place, setPlace] = useState(null);
    const autocompleteRef = useRef(null);




    const redirectToStripe = async () => {
        // const { data } = await api.get(`subscriptions/stripe-public-key`)
        // 'pk_test_TCjofXqhpGiaDzNLItyiccx000ougmhFnz';
        // const stripePromise = loadStripe(data.value)
        const stripePromise = loadStripe(
            'pk_test_51K6IJQB5OnvlhtIimji1uXUxV8S6AHtaxogATTN4oXsV6yEPToxCmxfq25eP28CUNIKw1FhqVwFAD0H78Ajcb3Fj00gYZf5paT'
        )
        const stripe = await stripePromise
        const result = await stripe.redirectToCheckout({
            sessionId: sessionId
        })
    }

    useEffect(() => {
        if (sessionId) {
            redirectToStripe();
        }
    }, [sessionId]);

    const handleStripeSubscription = async () => {
        try {
            setDisableStripe(true);
            // API call to create Stripe session, assuming it returns a session ID
            const { data } = await api.post(`signup/stripe?reference=${sellerId}`);
            setSessionId(data)

            // else {
            //     throw new Error("Session creation failed.");
            // }
        } catch (error) {
            setDisableStripe(false);
            const errorMessage = error?.response?.data?.errors?.length
                ? error.response.data.errors.join(', ')
                : error.message;
            toast.error(errorMessage);
        }
    };

    return (
        <>
            <>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "24px",
                        border: "1px solid #e0e0e0",
                        borderRadius: "8px",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                        maxWidth: "400px",
                        margin: "auto"
                    }}
                >
                    <Typography
                        style={{
                            fontSize: '32px',
                            color: '#4aa56d',
                            textTransform: 'uppercase',
                            fontWeight: 600,
                            marginBottom: '24px',
                            textAlign: 'center'
                        }}
                    >
                        Annual Subscription
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={7}>
                            <Typography
                                style={{
                                    fontSize: '20px',
                                    color: '#3a3963',
                                    fontWeight: 500
                                }}
                            >
                                Pay With Stripe
                            </Typography>
                        </Grid>

                        <Grid item xs={5}>
                            <Typography
                                style={{
                                    fontSize: '20px',
                                    color: '#3a3963',
                                    fontWeight: 500,
                                    textAlign: 'right'
                                }}
                            >
                                Total
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                style={{
                                    fontSize: '24px',
                                    color: '#4aa56d',
                                    fontWeight: 600,
                                    textAlign: 'right'
                                }}
                            >
                                $999
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: '24px', textAlign: 'center' }}>
                            <ButtonBase
                                onClick={() => {
                                    if (!disableStripe) {
                                        handleStripeSubscription();
                                    }
                                }}
                                disabled={disableStripe}
                                className={classes.button}
                                style={{
                                    padding: '12px 24px',
                                    backgroundColor: disableStripe ? '#ccc' : '#4aa56d',
                                    color: '#fff',
                                    borderRadius: '4px',
                                    fontWeight: 600
                                }}
                            >
                                <span>Pay With Stripe</span>
                            </ButtonBase>
                        </Grid>
                    </Grid>
                </Box>

            </>

        </>
    )
}
function StripePage() {
    const WrappedSignUpPage = GoogleApiWrapper({
        // apiKey: "AIzaSyBm2j93IqHV6pH7OO-WuTOLkyJ3VlbvD-4",
        //apiKey: "AIzaSyDsLFAtLpCN0KFbR-syWTV3cgFdtylgLbA",
    })(Stripe);

    return (
        <Box >
            <WrappedSignUpPage />
            {/* <Stripe /> */}
        </Box>
    );
}
export default StripePage
