import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import "../../assets/css/style.css";
import AddIcon from "@material-ui/icons/Add";
import deleteImg from "../../assets/images/icon-delete2.png";

import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  Switch,
  Grow,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import uploadImage from "../../assets/images2/upload5.png";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import FormikAutocomplete from "../../components/FormikAutocomplete";
import CustomTextField2 from "../../components/CustomTextField2";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "../../lib/context";
import api, { toFormData } from "../../api";
import videoplaceholder from "../../assets/images2/video-placeholder1.jpg";
import videoplaceholder1 from "../../assets/images2/video-placeholder.jpg";
import ModalImage from "react-modal-image";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomLoader from "../../components/CustomLoader";
import ReactPlayer from "react-player";
import { logout } from "../../utils/utils";
import AdminSidebar from "../Sidebar/AdminSidebar";
import AdminTitle from "../PageTitle/AdminTitle";
import CustomTextarea2 from "../../components/CustomTextarea2";

import editImg from "../../assets/images/icon-edit.png";
import Footer from "../../layout/Footer";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    display: "none",
  }),
};

const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 0.5em;
  background: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 800;
  font-size: 0.8rem;
  &:first-child {
    padding-top: 0.5em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.4em;
  padding-top: 0rem;
  font-weight: 800;
  font-size: 1rem;
`;

const options = [
  <li className="nav-item">
    <a href="/order" className="nav-link">
      <Link to="/order"> In Progress</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a href="/completedorder" className="nav-link">
      <Link to="/completedorder">Completed</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a href="/shipped" className="nav-link">
      <Link to="/shipped">Shipped</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a href="/canceled" className="nav-link">
      <Link to="/canceled">Canceled</Link>{" "}
    </a>
  </li>,
];

const optionz = [
  <li className="nav-item">
    <a href="/products" className="nav-link">
      <Link to="/products">Recent Products</Link>{" "}
    </a>
  </li>,
  <li className="nav-item">
    <a href="/allproducts" className="nav-link">
      <Link to="/allproducts">All Products</Link>{" "}
    </a>
  </li>,
  <li id="active" className="nav-item">
    <a href="/addproduct" className="nav-link">
      <Link to="/addproduct">Add A Product</Link>{" "}
    </a>
  </li>,
];

function Advertisements() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [primaryImagePreview, setPrimaryImagePreview] = useState();
  const { openSnackbar } = useSnackbar();
  const [adverts, setAdverts] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState({ open: false });
  const [tabVal, setTabVal] = useState(1);
  const [deleteId, setDeleteId] = useState("");

  const handleDialogClose = () => {
    setIsDialogOpen({ open: false });
  };
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };
  const [product, setProduct] = useState({
    address: "",
    description: "",
    discountprice: "",
    manufacturer: "",
    price: "",
    pfile: {},
    // primaryfile: {
    // 	fileName: 'string',
    // 	fileType: 'string',
    // 	originalFileName: 'string',
    // 	url: 'string',
    // },
    sfile: [
      // { url: '' },
      // { url: '' },
      // { url: '' },
      // { url: '' },
      // { url: '' },
      // { url: '' },
    ],
    // productfiles: [
    // {
    // 	fileName: 'string',
    // 	fileType: 'string',
    // 	originalFileName: 'string',
    // 	url: 'string',
    // },
    // ],
    productname: "",
    sellerid: "",
    sellinginstrument: "",
    tags: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const toggling = () => {
    setIsOpen(!isOpen);
    setIsOn(false);
  };
  const [secondaryFilesPreview, setSecondaryFilesPreview] = useState([]);
  const { push } = useHistory();
  const [videoModal, setVideoModal] = useState({ state: false, url: "" });

  const [isOn, setIsOn] = useState(true);
  const toggling1 = () => {
    setIsOn(!isOn);
    setIsOpen(false);
  };
  const useStyles = makeStyles({
    input: {
      border: "0px",
      height: "26px",
      width: "100%",
      outline: "none",
      marginRight: "4px",
      color: "#898f8f",
      "&:focus": {
        border: "0px",
        outline: "none",
      },
      "&:hover": {
        border: "0px",
        outline: "none",
      },
      "&:active": {
        border: "0px",
        outline: "none",
      },
      // '&::placeholder': {
      // 	color: '#898f8f',
      // 	fontSize: '14px',
      // 	fontFamily: 'Cabin',
      // },
    },
    icon: {
      fontSize: "19px",
      margin: "3px 5px 0px 8px",
      color: "#898f8f",
    },
    container: {
      border:
        // meta.error && meta.touched
        // 	? '1px solid #fb4868'
        // :
        "1px solid #ffffff",
      height: "46px",
      borderRadius: "6px",
      display: "flex",
      alignItems: "center",
      marginTop: "2px",
      boxShadow: "1px 0px 5px #cfcfcf",
    },
    iconTextField: {
      //   color: "#898f8f",
      color: "white",
      lineHeight: 1.43,
      fontSize: "18px",
      fontWeight: "bold",
      borderRadius: "6px 0 0 6px",
      letterSpacing: "normal",
      padding: "12px 16px",
      borderRight: "1px solid #9fabc6",
      backgroundColor: "#fb9d1e",
      height: "100%",
      marginRight: "6px",
    },
  });
  const classes = useStyles();
  const onOptionClicked = (value) => () => {
    console.log(selectedOption);
  };

  const onOptionzClicked = (value) => () => {
    console.log(selectedOption);
  };
  const [search, setSearch] = useState();

  const [option, setOption] = useState({
    page: 1,
    size: 5,
    sortBy: "name",
    sortOrder: "asc",
    search: "",
  });
  const [totalElem, setTotalElem] = useState();
  const fetchData = async () => {
    try {
      const response = await api.post("/advert", option);
      if (response && response.data) {
        const { data } = response;
        setAdverts(data.content);
        setTotalElem(data.totalElements);
      } else {
        // Handle unexpected response format
        openSnackbar("error", "Unexpected response format");
      }
    } catch (error) {
      // Handle error
    }
  };

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      // const tags = [];
      // selectedTags.map((item) => tags.push(item.title));

      const sfiles = [];
      console.log(
        selectedTags.map((item) => item.title),
        "selectedTags"
      );
      // values.sfile.map((item) =>
      // 	sfiles.push(formData.append('sfile', item))
      // );
      // values.sfile.forEach((i) => formData.append("sfile", i));
      // formData.append("productname", values.productname);
      // formData.append("price", values.price);
      // formData.append("discountprice", values.discountprice);
      // formData.append("pfile", values.pfile);
      // formData.append("tags", tags);
      // console.log(values.tags, "aaaaaaaa");

      // formData.append("manufacturer", values.manufacturer);
      // formData.append("sellinginstrument", values.sellinginstrument);
      // formData.append("address", values.address);
      // formData.append("description", values.description);
      // const { data } = await api.post('product/save', {
      // 	...values,
      // 	tags: tags,
      // });
      if (values.type == "Add") {
        const { data } = await api.post(
          `advert`,
          toFormData({
            name: values.name,
            url: values.url,
            tags: values.tags,
            document: values.pfile,
          })
        );
        console.log(values.tags);

        openSnackbar("success", "Advertisement added successfully");
        handleDialogClose();
        fetchData();
      } else {
        const { data } = await api.put(
          `advert`,
          toFormData({
            id: values.id,
            name: values.name,
            url: values.url,
            tags: values.tags,
            document: values.pfile,
          })
        );
        openSnackbar("success", "Advertisement updated successfully");

        handleDialogClose();
        fetchData();
      }

      // localStorage.setItem('data', JSON.stringify(data.data));
      // localStorage.setItem('token', 'Bearer ' + data.authToken);
      // window.location.pathname == '/loginpage/buyer'
      // 	? push('/myacc')
      // 	: push('/overview');
      // push('/allproducts')
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        // error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const handleDeleteDialogOpen = (id) => {
    setDeleteId(id);
    setIsDeleteDialogOpen(true);
  };
  const handleDeleteDialogClose = () => {
    setDeleteId("");
    setIsDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    try {
      const { data } = await api.put(`advert/deleteAdvert?id=${deleteId}`);
      openSnackbar("success", "Advertisement is deleted.");
      handleDeleteDialogClose();
      fetchData();
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };

  useEffect(() => {
    console.log("test console log");
  }, []);

  useEffect(() => {
    fetchData();
  }, [option]);

  if (!adverts) {
    return <CustomLoader />;
  }
  const handleSort = (column) => {
    console.log(column);
    setOption({
      ...option,
      sortBy: column,
      sortOrder:
        option.sortBy === column && option.sortOrder === "asc" ? "desc" : "asc",
    });
  };
  return (
    <>
      <Dialog
        open={isDialogOpen.open}
        onClose={handleDialogClose}
        TransitionComponent={Grow}
      >
        <DialogTitle>
          {isDialogOpen.type == "Add" ? "Add" : "Edit"} Advertisement
        </DialogTitle>
        <DialogContent className="p-3">
          <Formik
            initialValues={{
              url: "",
              tags: "",
              pfile: {},
              ...isDialogOpen,
              // primaryfile: {
              // 	fileName: 'string',
              // 	fileType: 'string',
              // 	originalFileName: 'string',
              // 	url: 'string',
              // },
              // sfile: [],
              // productfiles: [
              // {
              // 	fileName: 'string',
              // 	fileType: 'string',
              // 	originalFileName: 'string',
              // 	url: 'string',
              // },
              // ],
            }}
            validationSchema={yup.object().shape({
              name: yup.string().required("Name is required."),
              // address: yup.string().required("Address is required."),
              // price: yup.string().required("Price is required."),
              // phone: yup.string().required("Phone is required."),
              // address: yup.string().required("Address is required."),
            })}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, ...rest }) => {
              return (
                <Form>
                  {/* <div className='tab-content' id='nav-tabContent'>
                              <div
                                className='tab-pane fade show active'
                                id='nav-activeOrders'
                                role='tabpanel'
                                aria-labelledby='nav-activeOrders-tab'
                              >
                                <div className='box-content minColheight'>
                                  <div
                                    className='row'
                                    id='dashboard-tabs'
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-around'
                                    }}
                                  > */}
                  <div className="col-md-12">
                    <div className="tab-content" id="nav-tabContent">
                      <div
                        className="tab-pane fade  show active"
                        id="nav-account"
                        role="tabpanel"
                        aria-labelledby="nav-account-tab"
                      >
                        <div className="form-sec full-block ">
                          {/* <h2>
																						Upload
																						a
																						Primary
																						Photo/Video{' '}
																					</h2> */}
                          <div className="row">
                            <div
                              className="col-md-12"
                              style={{
                                // backgroundColor:
                                // 	'green',
                                borderColor: "#e9ecef",
                                border: "2px solid #e9ecef",
                                boxShadow: "0 0 4px 4px #e9ecef",
                                margin: "20px 0",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                paddingTop: "48px",
                                paddingBottom: "48px",
                                borderRadius: "12px",
                                alignItems: "center",
                              }}
                            >
                              {values.pfile && primaryImagePreview ? (
                                // values
                                // 	.primaryfile
                                // 	.fileType ==
                                // 		'image'
                                true ? (
                                  <div
                                  // style={{
                                  // 	position:
                                  // 		'relative',
                                  // }}
                                  >
                                    <ModalImage
                                      small={primaryImagePreview}
                                      large={primaryImagePreview}
                                      alt=""
                                      style={{
                                        objectFit: "cover",
                                        minHeight: "100%",
                                        minWidth: "100%",
                                      }}
                                      hideDownload={true}
                                      hideZoom={true}
                                    />
                                    <CancelIcon
                                      style={{
                                        position: "absolute",
                                        color: "red",
                                        right: "-10px",
                                        top: "-10px",
                                      }}
                                      onClick={() => {
                                        setFieldValue("pfile", {});
                                        setPrimaryImagePreview("");
                                        setProduct({
                                          ...product,
                                          pfile: {},
                                        });
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <img
                                    src={videoplaceholder1}
                                    style={{
                                      objectFit: "cover",
                                      height: "150px",
                                      width: "200px",
                                      // minHeight:
                                      // 	'100%',
                                      // minWidth:
                                      // 	'100%',
                                    }}
                                    onClick={() =>
                                      setVideoModal({
                                        state: true,
                                        url: product.pfile.url,
                                      })
                                    }
                                  />
                                )
                              ) : (
                                <img
                                  src={
                                    product.pfile && primaryImagePreview
                                      ? primaryImagePreview
                                      : uploadImage
                                  }
                                  alt=""
                                  width={150}
                                />
                              )}

                              {/* <CloudUploadIcon
																				style={{
																					color: '#ababab',
																					fontSize:
																						'80px',
																				}}
																			/> */}
                              <input
                                accept="image/*,video/*"
                                style={{
                                  display: "none",
                                  outline: "none",
                                }}
                                id="outlined-button-file"
                                // multiple
                                type="file"
                                onChange={async (e) => {
                                  e.preventDefault();
                                  let file = e.target.files[0];
                                  e.target.value = "";
                                  setIsLoading(true);
                                  setFieldValue("pfile", file);
                                  setProduct({
                                    ...product,
                                    pfile: file,
                                  });
                                  setIsLoading(false);
                                  let reader = new FileReader();
                                  reader.onloadend = () => {
                                    setPrimaryImagePreview(reader.result);
                                  };
                                  reader.readAsDataURL(file);
                                }}
                              />
                              <label htmlFor="outlined-button-file">
                                <Button
                                  component="span"
                                  style={{
                                    float: "right",
                                    backgroundColor: "#51a470",
                                    marginBottom: "2px",
                                    marginTop: "20px",
                                    color: "white",
                                  }}
                                  variant="contained"
                                  color="primary"
                                >
                                  Upload primary photo/video
                                </Button>
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* end full-block */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-sec full-block">
                      <Field
                        as={CustomTextField2}
                        type="text"
                        name="name"
                        placeholder="Advertisement Name"
                        label={
                          <span
                            style={{
                              fontSize: "20px",
                              fontWeight: "500",
                            }}
                          >
                            Advertisement Name
                          </span>
                        }
                        className="form-control"
                        className1="row mt-2"
                        className2="col-md-8"
                        setFieldValue={setFieldValue}
                      />
                      {/* end row */}
                    </div>
                    <div className="form-sec full-block mb-3">
                      <Field
                        as={CustomTextarea2}
                        type="text"
                        name="url"
                        placeholder="Redirect URL"
                        label={
                          <span
                            style={{
                              fontSize: "20px",
                              fontWeight: "500",
                            }}
                          >
                            Redirect URL
                          </span>
                        }
                        className="form-control"
                        className1="row mt-2"
                        className2="col-md-8"
                        setFieldValue={setFieldValue}
                        multiline={true}
                        // row={3}
                      />
                      {/* end row */}
                    </div>
                    {/* end full-block */}
                    {/* <div className="form-sec full-block mb-3">
                                      <h3
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Types of Instruments we will be selling
                                      </h3>
                                      <div className="row">
                                        <div className="col-md-8">
                                          <select
                                            className="form-control"
                                            style={{
                                              height: "43px",
                                            }}
                                            onChange={(event) =>
                                              setFieldValue(
                                                "sellinginstrument",
                                                event.target.value
                                              )
                                            }
                                          >
                                            <option val>Instruments</option>
                                            <option val="new">New</option>
                                            <option val="usedPartsOnly">
                                              Used Parts Only
                                            </option>
                                            <option val="refurbished">
                                              Refurbished
                                            </option>
                                            <option val="certifyPreOwned">
                                              Certify Pre Owned
                                            </option>
                                          </select>
                                        </div>
                                      </div> 
                                    </div>*/}
                    {/* <div className="form-sec full-block mb-2">
                                      <h3
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Select Manufacturer
                                      </h3>
                                      <div className="row">
                                        <div className="col-md-8">
                                          <select
                                            style={{
                                              height: "43px",
                                            }}
                                            onChange={(event) =>
                                              setFieldValue(
                                                "manufacturer",
                                                event.target.value
                                              )
                                            }
                                            className="form-control"
                                          >
                                            <option val>
                                              Select Manufacturer
                                            </option>
                                            <option val="thermo">
                                              Thermo Scientific Rigaku
                                            </option>
                                            <option val="pmi">PMI</option>
                                            <option val="hitachi">
                                              Hitachi
                                            </option>
                                            <option val="verder">Verder</option>
                                          </select>
                                        </div>
                                      </div> 
                                    </div>*/}
                    <div className="form-sec full-block">
                      <h3
                        style={{
                          fontSize: "20px",
                          fontWeight: "500",
                        }}
                      >
                        Tags
                      </h3>
                      <div className="row mt-2">
                        <div className="col-md-8">
                          {/* <FormikAutocomplete
                            value={values.tags.map((tag) => ({
                              title: tag,
                              value: tag,
                            }))}
                            options={[
                              { title: "Hitachi", value: "Hitachi" },
                              { title: "PMI", value: "PMI" },
                              { title: "Buyer", value: "Buyer" },
                              { title: "Seller", value: "Seller" },
                            ]}
                            onChange={(event, newValue) => {
                              if (typeof newValue === "string") {
                                setFieldValue("tags", [
                                  ...values.tags,
                                  newValue,
                                ]);
                              } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setFieldValue("tags", [
                                  ...values.tags,
                                  newValue.inputValue,
                                ]);
                              } else {
                                setFieldValue(
                                  "tags",
                                  newValue.map((tag) => tag.value)
                                );
                              }
                            }}
                          /> */}
                          {/* <CreatableSelect
                            isMulti
                            value={values.tags.map((tag) => ({
                              label: tag,
                              value: tag,
                            }))}
                            onChange={(newValue, actionMeta) => {
                              console.log(newValue, "react select");
                              setFieldValue(
                                "tags",
                                newValue.map((tag) => tag.value)
                              );
                              
                            }}
                            formatCreateLabel={(inputValue) =>
                              `Create tag: ${inputValue}`
                            }
                            onInputChange={(inputValue) =>
                              console.log(inputValue)
                            }
                          /> */}
                          <CreatableSelect
                            isMulti
                            styles={customStyles}
                            value={values.tags.map((tag) => ({
                              label: tag,
                              value: tag,
                            }))}
                            placeholder="Type something and press enter..."
                            onChange={(newValue, actionMeta) => {
                              console.log(newValue, "react select");
                              setFieldValue(
                                "tags",
                                newValue.map((tag) => tag.value)
                              );
                            }}
                            formatCreateLabel={(inputValue) =>
                              `Create tag: ${inputValue}`
                            }
                            onInputChange={(inputValue) =>
                              console.log(inputValue)
                            }
                            components={{
                              DropdownIndicator: null,
                              IndicatorSeparator: null,
                              Menu: () => null,
                            }}
                          />
                          {/* <FormikAutocomplete
                            value={selectedTags}
                            options={[
                              {
                                title: "Hitachi",
                                value: "Hitachi",
                              },
                              {
                                title: "PMI",
                                value: "PMI",
                              },

                              {
                                title: "Buyer",
                                value: "Buyer",
                              },

                              {
                                title: "Seller",
                                value: "Seller",
                              },
                            ]}
                            onChange={(event, newValue) => {
                              console.log(newValue, "3232", selectedTags);
                              if (typeof newValue === "string") {
                                console.log("type of newValue", newValue);
                                // setValue({
                                //   title: newValue
                                // });
                                // setFieldValue(
                                // 	name,
                                // 	{
                                // 		title: newValue,
                                // 	}
                                // );
                                setSelectedTags([
                                  ...selectedTags,
                                  {
                                    title: newValue,
                                    value: newValue,
                                  },
                                ]);
                              } else if (newValue && newValue.inputValue) {
                                console.log("else if", newValue.inputValue);
                                // Create a new value from the user input
                                // setValue({
                                //   title: newValue.inputValue
                                // });
                                // setFieldValue(
                                // 	name,
                                // 	{
                                // 		title: newValue.inputValue,
                                // 	}
                                // );
                                setSelectedTags([
                                  ...selectedTags,
                                  {
                                    title: newValue.inputValue,
                                    value: newValue.inputValue,
                                  },
                                ]);
                              } else {
                                // setValue(newValue);
                                // setFieldValue(
                                // 	name,
                                // 	newValue
                                // );
                                console.log("else", newValue);
                                setSelectedTags(newValue);
                              }
                            }}
                          /> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="emptyspace50"> &nbsp;</div>
                        <div className="full-block">
                          <button
                            className="btn btn-style1 btn-green"
                            type="submit"
                            disabled={rest.isSubmitting}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn ml-3"
                            onClick={handleDialogClose}
                          >
                            Cancel
                          </button>
                        </div>
                        {/* end full-block */}
                        {/* <div className='col-md-8'>
                                            <div className='emptyspace50'>
                                              {' '}
                                              &nbsp;
                                            </div>
                                            <div className="full-block mt-3">
																						<button
																							className="btn btn-style1 btn-green"
																							type="submit"
																						>
																							Save
																						</button>
																					</div>
                                          </div>
                                        </div> */}
                        {/* end row */}
                      </div>
                    </div>
                  </div>
                  {/* end row */}
                  {/* </div>
                              </div>
                            </div> */}
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleDeleteDialogClose}
        TransitionComponent={Grow}
      >
        <DialogContent>
          {/* <div className="modal" onClick={handleDialogClose}> */}
          {/* <div
							className="modal-content"
							onClick={(e) => e.stopPropagation()}
						> */}
          {/* <div className="modal-body"> */}
          <HighlightOffIcon
            style={{ color: "#48a068", float: "right" }}
            onClick={handleDeleteDialogClose}
          />
          <div>
            <Typography>
              Are you sure you want to delete this advertise?
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button onClick={() => handleDeleteDialogClose()}>Cancel</Button>
              <Button onClick={() => handleDelete()}>Confirm</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <div>
        <div>
          {/* Required meta tags */}
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          {/* Bootstrap CSS */}
          <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
          <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
          <link rel="stylesheet" href="assets/css/brands.css" />
          <link rel="stylesheet" href="assets/css/solid.css" />
          <link rel="stylesheet" href="assets/css/style.css" />
          <title>The Xchange Marketplace</title>
          <div id="dashboard" className="col-2-layout">
            <div className="left-sidebar equalColumn">
              <div className="box-content">
                <AdminSidebar pageName="admin-advertisement" />
              </div>
            </div>
            {/* end left-sidebar */}
            <div className="right-content equalColumn">
              <div className="box-content title-box">
                <AdminTitle />
              </div>
              {/* end box-content */}
              <div className="">
                <Dialog
                  open={videoModal.state}
                  onClose={() => setVideoModal({ state: false, url: "" })}
                  TransitionComponent={Grow}
                  // style={{ backgroundColor: 'transparent' }}
                  PaperProps={{
                    style: {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    },
                  }}
                  fullWidth={true}
                  maxWidth={"md"}
                >
                  <DialogContent>
                    <ReactPlayer
                      url={videoModal.url}
                      // width="100%"
                      // height="calc(100vh - 300px)"
                      playing
                      controls
                    />
                  </DialogContent>
                </Dialog>
                <div className="box-content minColheight">
                  <div className="row" id="dashboard-tabs">
                    <div className="col-md-12">
                      <nav>
                        <div
                          className="nav nav-tabs"
                          id="nav-tab"
                          role="tablist"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ alignSelf: "center" }}>
                            <a
                              className={`nav-item active `}
                              id="nav-account-tab"
                              data-toggle="tab"
                              href="#nav-account"
                              role="tab"
                              aria-controls="nav-account"
                              aria-selected="true"
                              // onClick={() => {
                              //   setTabVal(1)
                              // }}
                            >
                              {/* <Link to='/admin-seller-promotion'> */}
                              Advertisements
                              {/* </Link> */}
                            </a>
                          </div>
                          <div style={{ flexGrow: 1 }}></div>

                          <div
                            className="search-blk"
                            style={{ width: "400px", marginRight: "10px" }}
                          >
                            <input
                              type="text"
                              placeholder="Search all Advertisement"
                              className="form-control"
                              value={search}
                              onChange={(e) => {
                                // setSearch(e.target.value);
                                setOption({
                                  ...option,
                                  search: e.target.value,
                                });
                              }}
                            />
                            <button
                              type="submit"
                              defaultValue="Search"
                              className="btn-search"
                              onClick={() => setOption({ ...option, search })}
                            >
                              Search
                            </button>
                          </div>
                          <Button
                            style={{
                              float: "right",
                              marginBottom: 10,
                              color: "white",
                              backgroundColor: "#51a470",
                            }}
                            variant="contained"
                            color="primary"
                            // href="/addproduct"
                            onClick={() =>
                              setIsDialogOpen({
                                open: true,
                                type: "Add",
                                id: "",
                                url: "",
                                name: "",
                                tags: [],
                              })
                            }
                          >
                            Add Advertisement
                          </Button>
                          {/* </a> */}
                        </div>
                      </nav>
                    </div>
                    {/* <div className="col-md-3 text-right">
										<label>&nbsp;</label>
										<button className="btn btn-style1 btn-green btn-save">
											Add A Product
										</button>
									</div> */}
                    <div
                      className="tab-content"
                      id="nav-tabContent"
                      style={{
                        width: "100%",
                        paddingRight: "12px",
                        paddingLeft: "12px",
                      }}
                    >
                      <div
                        className="tab-pane fade show active"
                        id="nav-activeOrders"
                        role="tabpanel"
                        aria-labelledby="nav-activeOrders-tab"
                      >
                        <div className="payments-content">
                          {adverts?.length == 0 ? (
                            <label>No Record Found</label>
                          ) : (
                            <div className="paymentsCon-thead">
                              {/* <div id="prod" className="th-col tg-pro">
                          Product
                        </div> */}
                              <div className="th-col tg-email">
                                Advertisement #
                              </div>
                              {/* <div className="th-col tg-email">Name</div> */}
                              <div
                                className="th-col tg-email"
                                onClick={() => handleSort("name")}
                              >
                                Name{" "}
                                <span>
                                  {option.sortBy === "name" &&
                                  option.sortOrder === "asc"
                                    ? "↑"
                                    : "↓"}
                                </span>
                              </div>{" "}
                              <div className="th-col tg-email ml-5">Url</div>
                              <div className="th-col tg-email ml-5">Tags</div>
                              {/* <div className="th-col tg-action"></div> */}
                              <div className="th-col tg-action"></div>
                            </div>
                          )}
                          {adverts?.map((item, index) => (
                            <div
                              // onClick={handleDialogOpen}
                              className="paymentsCon-item-row"
                            >
                              {/* <div id="prod" className="td-col tg-pro">
                          <div className="tg-pro-img">
                            {item.primaryfile && item.primaryfile.url ? (
                              <img
                                src={item.primaryfile.url}
                                style={{
                                  width: "60px",
                                }}
                                alt=""
                                className="img-responsive"
                              />
                            ) : (
                              <ImageNotSupportedIcon
                                style={{
                                  fontSize: "50px",
                                  color: "#6e6e6e",
                                }}
                              />
                            )}
                          </div>
                          {item.itemname + " " + item.description}
                        </div> */}
                              <div className="td-col tg-email pl-5">
                                {index + 1}
                              </div>
                              <div className="td-col tg-email">{item.name}</div>
                              <div className="td-col tg-email ml-5">
                                {item.url}
                              </div>

                              <div className="td-col tg-email ml-5 d-flex flex-wrap">
                                {item.tags?.map((item) => (
                                  <h5>
                                    <span className="mx-2 px-3 badge rounded-pill bg-primary text-light">
                                      {item}
                                    </span>
                                  </h5>
                                ))}
                              </div>
                              {/* <div
                          id="edit"
                          className="td-col tg-action"
                          onClick={() => {
                            push(`editproduct/${product.id}`);
                          }}
                        >
                          <EditIcon
                            style={{
                              fontSize: "36px",
                              color: "black",
                            }}
                          />
                        </div> */}
                              <div id="delete" className="td-col tg-action">
                                <IconButton
                                  className="mr-4"
                                  onClick={() => {
                                    setIsDialogOpen({
                                      open: true,
                                      type: "Edit",
                                      ...item,
                                    });
                                  }}
                                >
                                  <img src={editImg} alt="" width="25"></img>
                                </IconButton>
                                <IconButton
                                  // className="mr-3"
                                  onClick={() => {
                                    // handleDelete(product.id)
                                    handleDeleteDialogOpen(item.id);
                                  }}
                                >
                                  <img src={deleteImg} alt="" width="25"></img>
                                </IconButton>
                                {/* <Switch size='medium' color='primary' /> */}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse">
                    <div>
                      <select
                        className="form-select mt-2 mx-2"
                        value={option.size}
                        onChange={(e) =>
                          setOption({ ...option, size: e.target.value })
                        }
                      >
                        {/* <option selected>{option.size}</option> */}
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="100">100</option>
                      </select>
                    </div>

                    <nav aria-label="...">
                      <ul className="pagination">
                        <li
                          className={`page-item ${
                            option.page === 1 ? "disabled" : ""
                          }`}
                        >
                          <span
                            className="page-link"
                            onClick={() =>
                              setOption({
                                ...option,
                                page: option.page - 1,
                              })
                            }
                            style={{ color: "black" }}
                          >
                            Previous
                          </span>
                        </li>
                        {Array.from(
                          { length: Math.ceil(totalElem / option.size) },
                          (_, index) => (
                            <li
                              key={index}
                              className={`page-item ${
                                option.page === index + 1 ? "active" : ""
                              }`}
                            >
                              <span
                                className="page-link"
                                onClick={() =>
                                  setOption({
                                    ...option,
                                    page: index + 1,
                                  })
                                }
                                style={
                                  {
                                    // backgroundColor: "#4ba26b",
                                    // color: "black",
                                  }
                                }
                              >
                                {index + 1}
                              </span>
                            </li>
                          )
                        )}
                        <li
                          className={`page-item ${
                            option.page === Math.ceil(totalElem / option.size)
                              ? "disabled"
                              : ""
                          }`}
                        >
                          <span
                            className="page-link"
                            onClick={() =>
                              setOption({
                                ...option,
                                page: option.page + 1,
                              })
                            }
                            style={{ color: "black" }}
                          >
                            Next
                          </span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                {/* end right -sidebar */}
              </div>
            </div>
          </div>

          {/* Optional JavaScript */}
          {/* jQuery first, then Popper.js, then Bootstrap JS */}
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Advertisements;
