import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { formatDate, logout } from "../../utils/utils";
import productImage from "../../assets/images/pro-img.jpg";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  Button,
  Typography,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Image from "../../assets/images/img-02.jpg";
import BuyerTitle from "../PageTitle/BuyerTitle";
import BuyerSidebar from "../Sidebar/BuyerSidebar";
import api from "../../api";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { useSnackbar } from "../../lib/context";
import MessageIcon from "@mui/icons-material/Message";
import CustomLoader from "../../components/CustomLoader";
import Footer from "../../layout/Footer";

function BuyerOrderHistory() {
  const { push } = useHistory();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [orders, setOrders] = useState();
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await api.get("order");
      setOrders(data.filter((d) => d.orderstatus != "New Order"));
    } catch (error) {
      if (
        error.response !== null ||
        error.response.data !== null ||
        error.response.data.errors.length !== 0
      ) {
        error.response.data.errors.forEach((e) => openSnackbar("error", e));
      } else {
        openSnackbar("error", error.message);
      }
    }
  };
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };
  if (!orders) {
    return <CustomLoader />;
  }
  return (
    <div>
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        TransitionComponent={Grow}
      >
        <DialogContent>
          {/* <div className="modal" onClick={handleDialogClose}> */}
          {/* <div
							className="modal-content"
							onClick={(e) => e.stopPropagation()}
						> */}
          {/* <div className="modal-body"> */}
          <HighlightOffIcon
            style={{ color: "#48a068", float: "right" }}
            onClick={handleDialogClose}
          />
          {/* <button
							onClick={handleDialogClose}
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>

							<span aria-hidden="true">×</span>
						</button> */}
          <div className="order-details brdr-none">
            <h2>ORDER DETAILS</h2>
            <div className="od-list">
              <label>Order Date:</label> July 19, 2021
            </div>
            <div className="od-list">
              <label>Order Number:</label> #5896254
            </div>
            <div className="od-list">
              <label>Order Total:</label> $5,900
            </div>
          </div>
          {/* end  order-details */}
          <div className="order-details brdr-none">
            <h2>PRODUCT DETAILS</h2>
            <div className="od-list">
              <strong>Product</strong> <br />
            </div>
            <div className="od-list mt-2 mb-2">
              <img src={Image} alt="" />
            </div>
            <div className="od-list mb-2">
              Beckman Coulter Life Science UV/Vis Spectrophotometer DU 730
            </div>
            <div className="od-list">
              <label>Catagory:</label> Test &amp; Measurement
            </div>
            <div className="od-list">
              <label>Brand:</label> Rigaku
            </div>
            <div className="od-list">
              <label>Seller:</label> SeqGen
            </div>
          </div>
          {/* end  order-details */}
          <div className="row">
            <div className="col-md-6">
              <div className="order-details brdr-none">
                <h2>SHIPPING DETAILS</h2>
                <div className="od-list">Chris Wattson</div>
                <div className="od-list mt-2">
                  <strong>Shipping Address</strong> <br />
                  12345 Jones Rd. Suite #246 <br />
                  Houston, TX 77075
                </div>
              </div>
              {/* end  order-details */}
            </div>
            <div className="col-md-6">
              <div className="order-details brdr-none">
                <h2>PREFERRED SHIPPING METHOD</h2>
                <div className="od-list">
                  Ground Shipping
                  <br />
                  Seller Preferred Shipping
                </div>
              </div>
              {/* end  order-details */}
            </div>
          </div>
          <div className="order-details brdr-none">
            <h2>PREFERRED PAYMENT METHOD</h2>
            <div className="od-list">Credit Card</div>
          </div>
          {/* end  order-details */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
        </DialogContent>
      </Dialog>
      <div>
        {/* Required meta tags */}
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {/* Bootstrap CSS */}
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
        <link rel="stylesheet" href="assets/css/brands.css" />
        <link rel="stylesheet" href="assets/css/solid.css" />
        <link rel="stylesheet" href="assets/css/style.css" />
        <title>The Xchange Marketplace</title>
        <div id="dashboard" className="col-2-layout">
          <div className="left-sidebar equalColumn">
            <div className="box-content">
              <BuyerSidebar pageName="orders" />
            </div>
          </div>
          {/* end left-sidebar */}
          <div className="right-content equalColumn">
            <div className="box-content title-box">
              <div className="page-title">seller Hub</div>
              <BuyerTitle />
            </div>
            {/* end box-content */}
            <div className="box-content minColheight">
              <div className="row" id="dashboard-tabs">
                <div className="col-md-12">
                  <nav>
                    <div className="nav nav-tabs">
                      <a
                        className="nav-item"
                        id="nav-profile-tab"
                        data-toggle="tab"
                        href="/buyerorder"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        Current Orders
                      </a>
                      <a
                        className="nav-item active"
                        id="nav-profile-tab"
                        data-toggle="tab"
                        href="/buyerorderhistory"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        Order History
                      </a>

                      <div className="order-right-filter">
                        <div className="search-blk">
                          <input
                            type="text"
                            placeholder="Search all orders"
                            className="form-control"
                          />
                          <input
                            type="submit"
                            defaultValue="Search"
                            className="btn-search"
                          />
                        </div>
                      </div>
                    </div>
                  </nav>
                  <div className="payments-content">
                    {orders.length > 0 && (
                      <div className="paymentsCon-thead">
                        <div className="th-col tg-pro">Product</div>
                        <div className="th-col tg-name">Name</div>
                        <div className="th-col tg-email">Email</div>
                        <div className="th-col tg-date">Order Date</div>
                        <div className="th-col tg-orderNo">Order No.</div>
                        <div className="th-col tg-amount">Amount</div>
                        <div className="th-col tg-pay-status">Status</div>
                      </div>
                    )}
                    {orders.length == 0 ? (
                      <div>No orders found</div>
                    ) : (
                      orders.map((order) => (
                        <div className="paymentsCon-item-row">
                          <div className="td-col tg-pro">
                            <div className="tg-pro-img">
                              {order.product.length > 0 &&
                              order.product[0].primaryfile &&
                              order.product[0].primaryfile.url ? (
                                <img
                                  src={order.product[0].primaryfile.url}
                                  style={{
                                    width: "60px",
                                  }}
                                  alt=""
                                  className="img-responsive"
                                />
                              ) : (
                                <ImageNotSupportedIcon
                                  style={{
                                    fontSize: "50px",
                                    color: "#6e6e6e",
                                  }}
                                />
                              )}
                            </div>
                            {order.product.length > 0 &&
                            order.product[0].productname
                              ? order.product[0].productname
                              : "NA"}
                            <button
                              type="button"
                              className="view-more pt-3"
                              data-toggle="modal"
                              data-target="#viewOrderDetail"
                              onClick={handleDialogOpen}
                            >
                              View Details
                            </button>
                          </div>
                          <div className="td-col tg-name">
                            {order.buyerAccountData
                              ? order.buyerAccountData.firstname
                              : "NA"}
                          </div>
                          <div
                            className="td-col tg-email"
                            style={{
                              overflowWrap: "break-word",
                            }}
                          >
                            {order.buyerAccountData
                              ? order.buyerAccountData.username
                              : "NA"}
                          </div>
                          <div className="td-col tg-date">
                            {formatDate(order.orderdate)}
                          </div>
                          <div className="td-col tg-orderNo">
                            #{order.ordernumber ?? "NA"}
                          </div>
                          <div className="td-col tg-amount">
                            ${order.total ?? ""}
                          </div>
                          <div className="td-col tg-pay-status">
                            {order.orderstatus ?? "NA"}
                          </div>
                          <div
                            className="td-col tg-pay-status"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <MessageIcon />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end right -sidebar */}
        </div>

        {/* Optional JavaScript */}
        {/* jQuery first, then Popper.js, then Bootstrap JS */}
        <Footer />
      </div>
    </div>
  );
}

export default BuyerOrderHistory;
